import React from "react";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {getPlayersParticipants} from "modules/selectors";
import {Exist} from "components/Exist";
import {GameCard} from "components/GameCard";

const Wrapper = styled.div`
	padding: 15px;
	column-gap: 20px;
	row-gap: 20px;
	text-align: center;

	.playerCard {
		display: inline-block;
		margin-left: 8px;
		min-width: 295px;
	}

	@media (min-width: ${({theme}) => theme.media.desktop}) {
		text-align: left;
	}
`;

const NotHaveMatches = styled.div`
	text-align: center;
	padding: 20px;
`;

export const LiveMatches: React.FC = () => {
	const playingParticipants = useSelector(getPlayersParticipants);

	return (
		<Wrapper>
			<Exist when={playingParticipants.length !== 0}>
				{playingParticipants.map((participant) => (
					<GameCard participant={participant} key={participant.id} />
				))}
			</Exist>
			<Exist when={playingParticipants.length === 0}>
				<NotHaveMatches>We don't have any matches here yet</NotHaveMatches>
			</Exist>
		</Wrapper>
	);
};
