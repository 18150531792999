import React from "react";
import styled from "styled-components";
import {Players, PlayerRow} from "components/GameCard/Players";
import {CardFooter} from "components/GameCard/CardFooter";
import {IParticipant} from "modules/reducers";
import {Exist} from "components";
import {useIsMobile} from "modules/utils";
import {useSelector} from "react-redux";
import {getActiveTournamentSettings} from "modules/selectors";
import {get} from "lodash";

const Wrapper = styled.div<{isActive: boolean; colorFlash?: string}>`
	position: relative;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
	border-radius: 6px;
	margin: 15px 0;

	${PlayerRow} {
		&:first-child {
			border-radius: 6px 6px 0 0;
		}
	}

	@media (min-width: ${({theme}) => theme.media.desktop}) {
		width: 270px;
		${({isActive}) =>
			isActive ? "animation: borderBlink 1s alternate;animation-iteration-count: 3;" : ""};

		@media screen and (max-width: 900px) {
			width: 100%;
		}

		@keyframes borderBlink {
			from,
			to {
				box-shadow: 0 1px 4px ${({colorFlash}) => colorFlash || "#fcfa6d"};
			}
			10% {
				box-shadow: 0 1px 8px ${({colorFlash}) => colorFlash || "#fcfa6d"};
			}
			50% {
				box-shadow: 0 1px 12px ${({colorFlash}) => colorFlash || "#fcfa6d"};
			}
			70% {
				box-shadow: 0 1px 14px ${({colorFlash}) => colorFlash || "#fcfa6d"};
			}
			100% {
				box-shadow: 0 1px 16px ${({colorFlash}) => colorFlash || "#fcfa6d"};
			}
		}
	}
`;

const SeparatorRight = styled.div`
	position: absolute;
	right: -29px;
	background: #e3e3e3;
	width: 29px;
	height: 1px;
	top: 77px;
`;

const SeparatorLeft = styled.div`
	position: absolute;
	left: -29px;
	background: #e3e3e3;
	width: 29px;
	height: 1px;
	top: 77px;
`;

interface IProps {
	participant: IParticipant;
	countParticipants: number;
	isActiveFlash: boolean;
	participantBlockNumber: number;
}

export const DraftCard: React.FC<IProps> = ({
	participant,
	countParticipants,
	isActiveFlash,
	participantBlockNumber,
}) => {
	const {isMobile} = useIsMobile();
	const settings = useSelector(getActiveTournamentSettings);

	return (
		<Wrapper
			isActive={isActiveFlash}
			colorFlash={get(settings, "scoreCentre.completedMatchesTitleColour")}>
			<Exist when={countParticipants === 2 && !isMobile && participantBlockNumber === 2}>
				<SeparatorRight />
			</Exist>
			<Exist
				when={
					(countParticipants === 4 ||
						countParticipants === 2 ||
						countParticipants === 1) &&
					!isMobile
				}>
				<SeparatorLeft />
			</Exist>
			<Players participant={participant} showHoverPlayers={true} withoutHeader={true} />
			<CardFooter participant={participant} />
		</Wrapper>
	);
};
