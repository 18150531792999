import React from "react";
import {useSelector} from "react-redux";
import {getActivePlayer} from "modules/selectors";
import {moneyFormat} from "modules/utils";
import {StatsList} from "./StatsList";
import {IPlayer} from "modules/reducers";
import {GenderType} from "modules/enums";
import {isNumber} from "lodash";

const getPlayhand = (playhand: string) =>
	playhand === "right-handed" ? "Right" : playhand === "left-handed" ? "Left" : "N/A";

const getBackhand = (player: IPlayer) =>
	player.backhand === "right-handed"
		? "Right"
		: player.playhand === "left-handed"
		? "Left"
		: "N/A";

const getDataOrNA = (data: number | null) => (isNumber(data) ? data : "N/A");

export const CareerStats: React.FC = () => {
	const player = useSelector(getActivePlayer)!;

	const commonData = [
		{
			title: player.age,
			value: "Age",
		},
		{
			title: player.height ? `${player.height}cm` : "N/A",
			value: "Height",
		},
		{
			title: getPlayhand(player.playhand),
			value: "Plays",
		},
	];

	const careerPrizeMoney = {
		title: player.careerPrizeMoney ? moneyFormat(player.careerPrizeMoney) : "N/A",
		value: "Prize money",
	};
	const titlesSingles = {
		title: getDataOrNA(player.titlesSingles),
		value: "Titles",
	};
	const data = {
		[GenderType.Woman]: [
			...commonData,
			careerPrizeMoney,
			titlesSingles,
			{
				title: getDataOrNA(player.currentWorldRankSingles),
				value: "Current world ranking",
			},
			{
				title: getDataOrNA(player.highestWorldRankSingles),
				value: "Highest singles ranking",
			},
			{
				title: getDataOrNA(player.currentWorldRankDoubles),
				value: "Doubles world ranking",
			},
			{
				title: getDataOrNA(player.highestWorldRankDoubles),
				value: "Highest doubles ranking",
			},
			{
				title: `${player.winsSingles || ""}/${player.lostsSingles || ""}`,
				value: "W/L record",
			},
		],
		[GenderType.Man]: [
			...commonData,
			{
				title: getBackhand(player),
				value: "Backhand",
			},
			{
				title: getDataOrNA(player.yearTurnedPro),
				value: "Year turned pro",
			},
			careerPrizeMoney,
			titlesSingles,
			{
				title: getDataOrNA(player.currentWorldRankSingles),
				value: "Current world ranking",
			},
			{
				title: getDataOrNA(player.winsSingles),
				value: "Career wins",
			},
			{
				title: getDataOrNA(player.lostsSingles),
				value: "Career losses",
			},
		],
	}[player.gender];

	return <StatsList data={data} />;
};
