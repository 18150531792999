import React, {ChangeEvent, useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import {SearchInput} from "components/Form";
import {forEach, partial} from "lodash";
import {SPECIAL_SYMBOLS} from "modules/constants";
import {IPlayer} from "modules/reducers";
import {useSelector, useDispatch} from "react-redux";
import {
	checkIsCustomEvent,
	getActiveCompleteCourt,
	getActiveCourt,
	getActiveDay,
	getActiveEvent,
	getActiveTournamentId,
	getEventsByParticipants,
	getPlayerProfileState,
	getPlayersByTournamentId,
	getPlayingCourt,
} from "modules/selectors";
import {Box} from "@mui/material";
import {showPlayerProfile} from "modules/actions";
import {useLocation} from "react-router-dom";

const Wrapper = styled.div`
	position: relative;
	width: 100%;
	margin-top: 20px;
	max-width: 100%;
	text-transform: capitalize;
	scrollbar-width: none;
	-ms-overflow-style: none;

	&::-webkit-scrollbar {
		display: none; /* for Chrome, Safari, and Opera */
	}

	&.full-width {
		max-width: 100%;
	}

	@media (min-width: ${({theme}) => theme.media.desktop}) {
		display: flex;
		align-items: center;
		margin-top: 0;
		margin-left: 10px;
		max-width: 130px;
	}
`;

const SearchResultWrapper = styled(Box)`
	position: absolute;
	width: 100%;
	max-height: 120px;
	overflow: scroll;
	z-index: 9;
	border-radius: 0 0 6px 6px;
	max-width: 332px;
	left: 7px;
	scrollbar-width: none;
	-ms-overflow-style: none;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);

	&::-webkit-scrollbar {
		display: none; /* for Chrome, Safari, and Opera */
	}

	@media (min-width: ${({theme}) => theme.media.desktop}) {
		left: 10px;
		top: 58px;
		max-width: 400px;
	}
`;

const SearchItem = styled.button`
	display: flex;
	width: 100%;
	padding: 8px 16px;
	color: #333333;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
	border: none;
	background: #ffffff;

	&:hover {
		background: #ecf5f9;
	}
`;

export const SearchPlayers: React.FC = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const [filteredPlayers, setFilteredPlayers] = useState<IPlayer[]>([]);
	const [searchText, setSearchText] = useState<string>("");
	const tournamentId = useSelector(getActiveTournamentId);
	const players = useSelector(getPlayersByTournamentId)(tournamentId);
	const isCustomTournament = useSelector(checkIsCustomEvent);

	const changeSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		const searchValue = e.target.value;
		setSearchText(searchValue);
	}, []);

	const pathname = location.pathname;
	const activeDay = useSelector(getActiveDay);
	const draftEvents = useSelector(getEventsByParticipants);
	const activeEventForOrderOfPlay = useSelector(getActiveEvent);
	const playingCourt = useSelector(getPlayingCourt);
	const activeCourt = useSelector(getActiveCourt);
	const activeCompleteCourt = useSelector(getActiveCompleteCourt);
	const {isShow} = useSelector(getPlayerProfileState);

	useEffect(() => {
		setSearchText("");
	}, [
		pathname,
		activeDay,
		draftEvents,
		activeEventForOrderOfPlay,
		playingCourt,
		activeCourt,
		activeCompleteCourt,
		isShow,
	]);

	useEffect(() => {
		if (searchText === "") {
			setFilteredPlayers([]);
			return;
		}

		const filteredPlayers = players.filter((player) => {
			let fullName = `${player.firstName} ${player.lastName}`.toLowerCase();
			let firstName = player.firstName.toLowerCase();
			let lastName = player.lastName.toLowerCase();

			let enteredName = searchText.toLowerCase();
			forEach(SPECIAL_SYMBOLS, (item, key) => {
				fullName = fullName.replace(key, item);
				firstName = firstName.replace(key, item);
				lastName = lastName.replace(key, item);
				enteredName = enteredName.replace(key, item);
			});

			return (
				fullName.startsWith(enteredName) ||
				firstName.startsWith(enteredName) ||
				lastName.startsWith(enteredName)
			);
		});
		setFilteredPlayers(filteredPlayers);
	}, [searchText, players]);

	const openPlayerProfile = useCallback(
		(player: IPlayer | undefined) => {
			if (!player) {
				return;
			}

			setSearchText("");
			dispatch(showPlayerProfile({playerId: player.id, participantId: tournamentId}));
			window.scrollTo(0, 0);
		},
		[dispatch, tournamentId]
	);

	return !isCustomTournament ? (
		<Wrapper className={"full-width"}>
			<SearchInput
				name={"search"}
				label={"Search by player name"}
				variant="filled"
				autoComplete="off"
				onChange={changeSearch}
				style={{height: "46px"}}
				value={searchText}
			/>
			<SearchResultWrapper sx={{bgcolor: "background.paper"}}>
				{filteredPlayers.map((player) => (
					<SearchItem key={player.id} onClick={partial(openPlayerProfile, player)}>
						{player.firstName} {player.lastName}
					</SearchItem>
				))}
			</SearchResultWrapper>
		</Wrapper>
	) : null;
};
