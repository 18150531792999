import React, {useCallback} from "react";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";
import {IconButton} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {hidePlayerProfile} from "modules/actions";
import {get} from "lodash";
import {getActiveTournamentSettings, getMatchStatsState} from "modules/selectors";
import {IMAGES_URL} from "modules/constants";
import {Exist} from "components";
import {changeIframeHeight} from "modules/utils";

const Wrapper = styled.div<{bg?: string | null}>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: row;
	width: 100%;
	position: relative;
	background: ${({bg}) => bg || "linear-gradient(180deg, #31176B 0%, #42177B 100%)"};
	min-height: 72px;
	padding: 20px;

	img {
		height: 40px;
	}

	@media (min-width: ${({theme}) => theme.media.desktop}) {
		height: 80px;
		padding: 0 20px;
		z-index: 999999;
	}
`;

const CloseButton = styled(IconButton)`
	&.MuiButtonBase-root {
		padding: 1px;
		color: #ffffff;

		@media (min-width: ${({theme}) => theme.media.desktop}) {
			// top: auto;
			// right: 14px;
		}
	}
`;

export const PlayerCardHead: React.FC = () => {
	const dispatch = useDispatch();
	const settings = useSelector(getActiveTournamentSettings);
	const logo = get(settings, "scoreCentre.headerLogo");
	const {isShow} = useSelector(getMatchStatsState);

	const clickHandler = useCallback(() => {
		dispatch(hidePlayerProfile());
		if (isShow) {
			changeIframeHeight(document.querySelector<HTMLElement>("#playerStats")?.offsetHeight);
		}
	}, [dispatch, isShow]);

	return (
		<Wrapper bg={get(settings, "scoreCentre.cardHeaderColour")}>
			<Exist when={!!logo}>
				<img src={IMAGES_URL + logo} alt="Tournament's logo" />
			</Exist>
			<CloseButton onClick={clickHandler}>
				<CloseIcon />
			</CloseButton>
		</Wrapper>
	);
};
