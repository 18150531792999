import {IStore} from "modules/types/store";
import {createSelector} from "reselect";
import {get, uniqBy, values} from "lodash";
import {IParticipant, IPlayer} from "modules/reducers";
import {getPlayers, getTournaments} from "modules/selectors";

export const getPlayerProfileState = (state: IStore) => state.playerProfile;

export const getActivePlayer = createSelector(getPlayers, getPlayerProfileState, (players: IPlayer[], state) => players.find(player => player.id === state.id));

export const getPlayerTournaments = createSelector(getPlayers, getTournaments, (players, tournaments) => (playerId: number | null) => {
	if (playerId === null) {
		return [];
	}

	return uniqBy(
		tournaments.filter(tournament => values(get(tournament, 'rounds', []))
			.flat()
			.find((match: IParticipant) => values(match.teams)
				.flat()
				.find(item => item.playerId)
			)
		)
		, 'id')
})