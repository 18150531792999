export const sendDataLayer = (event: string) => {
	if (window.dataLayer) {
		const parentUrl = sessionStorage.getItem("parentDomain") || document.location.href;

		console.log(event);
		console.log(parentUrl);

		window.dataLayer.push({
			event: event,
			parentURL: parentUrl,
		});
	}
};
