import React from "react";
import styled from "styled-components";
import {CardHeader} from "./CardHeader";
import {Players} from "./Players";
import {CardFooter} from "./CardFooter";
import {IParticipant} from "modules/reducers";
import {ParticipantStatusType} from "modules/enums";
// import {Exist} from "components";
// import {get} from "lodash";
// import {useSelector} from "react-redux";
// import {getActiveTournamentSettings} from "modules/selectors";

const Wrapper = styled.div`
	position: relative;
	width: 100%;
	max-width: 310px;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
	border-radius: 6px;
	margin: 30px 0;

	@media screen and (min-width: ${({theme}) => theme.media.desktop}) {
		margin: 20px 10px;
		max-width: 295px;
	}

	@media screen and (max-width: 1000px) {
		margin: 20px 3px;
	}
`;

// const PageTitleStyled = styled.h3<{color: string | null}>`
// 	position: absolute;
// 	top: -20px;
// 	left: 0;
// 	font-family: ${({theme}) => theme.fonts.secondaryFontFamily};
// 	font-style: normal;
// 	font-weight: 700;
// 	font-size: 20px;
// 	line-height: 18px;
// 	text-align: center;
// 	color: ${({color}) => color || "#311B77"};
// 	margin-top: 0;
//
// 	@media screen and (min-width: ${({theme}) => theme.media.desktop}) {
// 		top: -22px;
// 		text-align: left;
// 	}
// `;

// const titles = {
// 	complete: "Completed matches",
// 	active: "Live matches",
// };

interface IProps {
	participant: IParticipant;
	showNotBefore?: boolean;
	participantSubTitle?: ParticipantStatusType | null | undefined;
	blockKey?: number;
}

export const GameCard: React.FC<IProps> = ({
	participant,
	showNotBefore = false,
	// participantSubTitle = null,
}) => {
	// const settings = useSelector(getActiveTournamentSettings);
	// const titleWrapperColor =
	// 	participantSubTitle === ParticipantStatusType.Active
	// 		? get(settings, "scoreCentre.liveMatchesTitleColour", null)
	// 		: get(settings, "scoreCentre.completedMatchesTitleColour", null);

	return (
		<Wrapper className={`participant-${participant.id} playerCard`}>
			{/*<Exist when={Boolean(participantSubTitle)}>*/}
			{/*	<PageTitleStyled color={titleWrapperColor}>*/}
			{/*		{get(titles, `${participantSubTitle}`, null)}*/}
			{/*	</PageTitleStyled>*/}
			{/*</Exist>*/}
			<CardHeader participant={participant} />
			<Players participant={participant} />
			<CardFooter participant={participant} showNotBefore={showNotBefore} />
		</Wrapper>
	);
};
