import styled from "styled-components";

export const PageTitle = styled.h3<{color?: string | null}>`
	font-family: ${({theme}) => theme.fonts.secondaryFontFamily};
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 18px;
	text-align: center;
	color: ${({color}) => color || "#311B77"};
	margin-top: 20px;

	@media (min-width: ${({theme}) => theme.media.desktop}) {
		text-align: left;
		margin-left: 20px;
	}

	@media screen and (max-width: 1000px) {
		margin-left: 30px;
	}
`;
