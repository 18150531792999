import {createAction} from "redux-act";
import {IParticipant, IPlayer, ISet, ITournament, ITournamentsSetting} from "modules/reducers";
import {IDictionary} from "modules/types";

export const requestTournaments = createAction();
export const requestTournamentsSuccess = createAction<ITournament[]>();
export const requestTournamentsFailed = createAction();

export const fetchChecksumsJSON = createAction();
export const fetchChecksumsJSONSuccess = createAction<IDictionary<string>>();
export const fetchChecksumsJSONFailed = createAction();

export const fetchPlayers = createAction();
export const fetchPlayersSuccess = createAction<IPlayer[]>();
export const fetchPlayersFailed = createAction<Error>();

export const fetchTournamentsSettings = createAction();
export const fetchTournamentsSettingsSuccess = createAction<ITournamentsSetting[]>();
export const fetchTournamentsSettingsFailed = createAction();

export const fetchParticipantSets = createAction<IParticipant>();

export interface IParticipantSets {
	[id: number]: {
		status: string;
		set: ISet[];
	};
}

export const fetchParticipantSetsSuccess = createAction<IParticipantSets>();
export const fetchParticipantSetsFailed = createAction();

export const setActiveTournament = createAction<number | "">();
export const setActiveRoundName = createAction<string>();
export const setActiveDay = createAction<string>();
export const setSelectDayForOrderOfPlay = createAction<string>();
export const setActiveEvent = createAction<string>();
export const setActiveCourt = createAction<string>();

export const setActiveCompleteCourt = createAction<string>();

export const setPlayingCourt = createAction<string>();

export const setHoverPlayer = createAction<number>();
export const unsetHoverPlayer = createAction();

export const setFilterTournamentBy = createAction<string>();
