import React from "react";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {getPlayersById} from "modules/selectors";
import {IParticipant, TTeam} from "modules/reducers";
import {useIsMobile} from "modules/utils";
import {PlayersCard} from "./PlayersCard";
import {ScoreCard} from "./ScoreCard";
import {MatchStats} from "./MatchStats";
import {get} from "lodash";

const Wrapper = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 16px 16px 20px;

	@media (min-width: ${({theme}) => theme.media.desktop}) {
		padding: 20px;
	}
`;

const CourtName = styled.div`
	width: 100%;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 17px;
	border-bottom: 1px solid #e3e3e3;
	padding-bottom: 10px;
	margin-bottom: 10px;
	text-align: center;
`;

interface IStatsPlayers {
	participant: IParticipant | undefined;
}

export const StatsPlayers: React.FC<IStatsPlayers> = ({participant}) => {
	const {isMobile} = useIsMobile("(max-width: 900px)");
	const opponentsA = useSelector(getPlayersById)(
		get(participant, "teams.teamA", []).map((x: TTeam) => x.playerId)
	);
	const opponentsB = useSelector(getPlayersById)(
		get(participant, "teams.teamB", []).map((x: TTeam) => x.playerId)
	);

	return participant ? (
		<Wrapper>
			<CourtName>{participant.courtName}</CourtName>
			<PlayersCard
				participant={participant}
				opponentsA={opponentsA}
				opponentsB={opponentsB}
			/>
			{isMobile && <ScoreCard participant={participant} />}
			<MatchStats participant={participant} />
		</Wrapper>
	) : null;
};
