import {createReducer} from "redux-act";
import {fetchPlayersSuccess} from "modules/actions";
import {GenderType} from "modules/enums";

export interface IPlayer {
	id: number;
	image: string;
	firstName: string;
	lastName: string;
	age: number;
	nationality: string;
	country: string;
	height: number;
	playhand: "right-handed" | "left-handed" | "n/a";
	backhand: string;
	titlesSingles: number;
	titlesDoubles: number;
	currentWorldRankSingles: number;
	currentWorldRankDoubles: number;
	highestWorldRankSingles: number;
	highestWorldRankDoubles: number;
	winsSingles: number | null;
	winsDoubles: number | null;
	lostsSingles: number | null;
	lostsDoubles: number | null;
	yearTurnedPro: number | null;
	careerPrizeMoney: number | null;
	informationLink: string | null;
	isWheelchair: boolean;
	tournamentStats: {
		[key: number]: boolean;
	};
	gender: GenderType;
}

export type IPlayersReducer = IPlayer[];

export const players = createReducer<IPlayersReducer>({}, []).on(
	fetchPlayersSuccess,
	(_state, playersList) => playersList
);
