const isPlay = (pathname: string, countParticipants: number) => {
	return pathname === "/play" && countParticipants > 6;
};
const isDraw = (pathname: string, isMobile: boolean, countParticipants: number) => {
	return pathname === "/draw" && isMobile && countParticipants > 0;
};

const getBodyHeight = () => {
	return (
		document?.getElementById("root")?.clientHeight ||
		document.body.clientHeight ||
		document.body.offsetHeight
	);
};

export const getIframeHeight = (pathname: string, countParticipants: number, isMobile: boolean) => {
	if (pathname === "/" && countParticipants > 6) {
		return isMobile ? 1280 : 709;
	}

	if (isPlay(pathname, countParticipants)) {
		return isMobile ? 1217 : 720;
	}

	if (isDraw(pathname, isMobile, countParticipants)) {
		return 1000;
	}

	return getBodyHeight();
};

export const changeIframeHeight = (height?: number) => {
	// const height = getIframeHeight(pathname, countParticipants, isMobile);
	// console.log(height);
	setTimeout(() => {
		window.parent.postMessage(
			JSON.stringify({
				type: "voting_height",
				// payload: height,
				payload:
					height ||
					document.querySelector<HTMLElement>("#mainWrapper")?.offsetHeight ||
					0,
			}),
			"*"
		);
	}, 0);
};
