import React from "react";
import {getActiveTournamentSettings, IRoundItem} from "modules/selectors";
import {DisplayParticipantsList} from "./DisplayParticipantsList";
import {sliceParticipantsIntoChunks, useIsMobile} from "modules/utils";
import styled from "styled-components";
import ReactPageScroller from "react-page-scroller";
import {IParticipant} from "modules/reducers";
import {useSelector} from "react-redux";
import {get} from "lodash";
import {Exist} from "components";

const WrapperParticipantsList = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
	scrollbar-width: none;
	-ms-overflow-style: none;

	&::-webkit-scrollbar {
		display: none; /* for Chrome, Safari, and Opera */
	}

	@media (min-width: ${({theme}) => theme.media.desktop}) {
		flex-direction: initial;
		height: 100%;
		padding: 0 10px;
	}
`;

export const ProgressionIcons = styled.div<{color?: string | null}>`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 18px;
	height: 42px;
	margin: 0 auto 10px auto;

	&:before {
		content: "";
		position: absolute;
		top: 0;
		width: 18px;
		height: 18px;
		border: 3px solid ${({color}) => color || "#57e8fc"};
		border-radius: 50%;
	}

	&:after {
		content: "";
		position: absolute;
		bottom: 0;
		width: 18px;
		height: 18px;
		border: 3px solid ${({color}) => color || "#57e8fc"};
		border-radius: 50%;
	}

	@media (min-width: ${({theme}) => theme.media.desktop}) {
		display: none;
	}
`;

const getCartsBlockDataByParticipantCounts = (participantCounts: number) => {
	if (participantCounts === 32) {
		return {
			chunkedRound1: 8,
			chunkedRound2: 4,
			chunkedRound3: 2,
			scrollerHeight: "1950px",
			scrollerHeightMobile: "3460px",
		};
	}

	return {
		chunkedRound1: 4,
		chunkedRound2: 2,
		chunkedRound3: 1,
		scrollerHeight: "975px",
		scrollerHeightMobile: "1760px",
	};
};

interface IProps {
	participant1: IRoundItem | null;
	participant2: IRoundItem | null;
	participant3: IRoundItem | null;
	activeSubTab: number;
	activeRoundName: string;
	flashRounds: string[];
}

export const CardsForRound: React.FC<IProps> = ({
	participant1,
	participant2,
	participant3,
	activeSubTab,
	activeRoundName,
	flashRounds,
}) => {
	const {isMobile} = useIsMobile();
	const {chunkedRound1, chunkedRound2, chunkedRound3, scrollerHeight, scrollerHeightMobile} =
		getCartsBlockDataByParticipantCounts(participant1?.participants.length || 0);
	const settings = useSelector(getActiveTournamentSettings);

	const chunkedRound1Participants = sliceParticipantsIntoChunks(
		participant1?.participants,
		chunkedRound1
	) as [IParticipant[]];
	const chunkedRound2Participants = sliceParticipantsIntoChunks(
		participant2?.participants,
		chunkedRound2
	) as [IParticipant[]];
	const chunkedRound3Participants = sliceParticipantsIntoChunks(
		participant3?.participants,
		chunkedRound3
	) as [IParticipant[]];

	const pageScrollerHeight = isMobile ? scrollerHeightMobile : scrollerHeight;

	return (
		<ReactPageScroller
			animationTimer={600}
			customPageNumber={activeSubTab}
			blockScrollUp={true}
			blockScrollDown={true}
			renderAllPagesOnFirstRender={true}
			containerHeight={pageScrollerHeight}
			containerWidth={"auto"}>
			{chunkedRound1Participants.map((participants, key) => (
				<WrapperParticipantsList key={key}>
					<DisplayParticipantsList
						participants={chunkedRound1Participants}
						activeKey={key}
						roundName={participant1?.roundName}
						isActive={activeRoundName === participant1?.roundName}
						participantBlockNumber={1}
						flashRounds={flashRounds}
					/>

					<Exist when={Boolean(participant2)}>
						<ProgressionIcons
							color={get(settings, "draw.roundProgressionIconColour")}
						/>
					</Exist>

					<DisplayParticipantsList
						participants={chunkedRound2Participants}
						activeKey={key}
						roundName={participant2?.roundName}
						isActive={activeRoundName === participant2?.roundName}
						participantBlockNumber={2}
						flashRounds={flashRounds}
					/>

					<Exist when={Boolean(participant3)}>
						<ProgressionIcons
							color={get(settings, "draw.roundProgressionIconColour")}
						/>
					</Exist>

					<DisplayParticipantsList
						participants={chunkedRound3Participants}
						activeKey={key}
						roundName={participant3?.roundName}
						isActive={activeRoundName === participant3?.roundName}
						participantBlockNumber={3}
						flashRounds={flashRounds}
					/>
				</WrapperParticipantsList>
			))}
		</ReactPageScroller>
	);
};
