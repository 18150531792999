export const getTextForLive = (tournamentName: string, eventName: string) => {
	if (tournamentName === "Surbiton") {
		return `
			<p>For scoring of the men's events in the Lexus Surbiton Trophy, please visit the <a href="https://www.atptour.com/en/scores/current-challenger/surbiton/7297/live-scores" target="_blank">ATP site</a>.</p>
			<p>For scoring of the women's events in the Lexus Surbiton Trophy, please visit the <a href="https://live.itftennis.com/en/live-scores/?tourkey=W-ITF-GBR-2024-007" target="_blank">ITF site</a>.</p>
		`;
	}

	return `
		<p>For scoring of the men’s events in the Lexus Ilkley Trophy, please visit the <a href="https://www.atptour.com/en/scores/current-challenger/ilkley/9005/live-scores" target="_blank">ATP site</a>.</p>
		<p>For scoring of the women’s events in the Lexus Ilkley Trophy, please visit the <a href="https://live.itftennis.com/en/live-scores/?tourkey=W-ITF-GBR-2024-008" target="_blank">ITF site</a>.</p>
	`;
};

export const getTextForComplete = (tournamentName: string, eventName: string) => {
	if (tournamentName === "Surbiton") {
		return `
			<p>For scoring of the men's events in the Lexus Surbiton Trophy, please visit the <a href="https://www.atptour.com/en/scores/current-challenger/surbiton/7297/results" target="_blank">ATP site</a>.</p>
			<p>For scoring of the women's events in the Lexus Surbiton Trophy, please visit the <a href="https://www.itftennis.com/en/tournament/w100-surbiton/gbr/2024/w-itf-gbr-2024-007/draws-and-results/" target="_blank">ITF site</a>.</p>
		`;
	}

	return `
		<p>For scoring of the men’s events in the Lexus Ilkley Trophy, please visit the <a href="https://www.atptour.com/en/scores/current-challenger/ilkley/9005/results" target="_blank">ATP site</a>.</p>
		<p>For scoring of the women’s events in the Lexus Ilkley Trophy, please visit the <a href="https://www.itftennis.com/en/tournament/w100-ilkley/gbr/2024/w-itf-gbr-2024-008/draws-and-results/" target="_blank">ITF site</a>.</p>
	`;
};

export const getTextForDraw = (tournamentName: string, eventName: string) => {
	if (tournamentName === "Surbiton") {
		return `
			<p>For the draws of the men's events in the Lexus Surbiton Trophy, please visit the <a href="https://www.atptour.com/en/scores/current-challenger/surbiton/7297/draws" target="_blank">ATP site</a>.</p>
			<p>For the draws of the women's events in the Lexus Surbiton Trophy, please visit the <a href="https://www.itftennis.com/en/tournament/w100-surbiton/gbr/2024/w-itf-gbr-2024-007/draws-and-results/" target="_blank">ITF site</a>.</p>
		`;
	}

	return `
		<p>For the draws of the men's events in the Lexus Ilkley Trophy, please visit the <a href="https://www.atptour.com/en/scores/current-challenger/ilkley/9005/draws" target="_blank">ATP site</a>.</p>
		<p>For the draws of the women’s events in the Lexus Ilkley Trophy, please visit the <a href="https://www.itftennis.com/en/tournament/w100-ilkley/gbr/2024/w-itf-gbr-2024-008/draws-and-results/" target="_blank">ITF site</a>.</p>
	`;
};

export const getTextForOrder = (tournamentName: string, eventName: string) => {
	if (tournamentName === "Surbiton") {
		return `
			<p>For the men's order of play at the Lexus Surbiton Trophy, please visit the <a href="https://www.atptour.com/en/scores/current-challenger/surbiton/7297/daily-schedule" target="_blank" rel="noreferrer">ATP site</a>.</p>
			<p>For women's order of play at the Lexus Surbiton Trophy, please visit the <a href="https://live.itftennis.com/en/live-scores/?tourkey=W-ITF-GBR-2024-007" target="_blank" rel="noreferrer">ITF site</a>.</p>
		`;
	}

	return `
		<p>For the men's order of play at the Lexus Ilkley Trophy, please visit the <a href="https://www.atptour.com/en/scores/current-challenger/ilkley/9005/daily-schedule" target="_blank">ATP site</a>.</p>
		<p>For women's order of play at the Lexus Ilkley Trophy, please visit the <a href="https://live.itftennis.com/en/live-scores/?tourkey=W-ITF-GBR-2024-008" target="_blank">ITF site</a>.</p>
	`;
};
