export enum ShareNet {
	Facebook = "fb",
	Twitter = "tw",
}

export enum ShareSource {
	General = "general",
}

export enum RequestState {
	IDLE,
	Requested,
	Received,
}

export enum SaveButtonType {
	Disabled,
	Saving,
	Saved,
	Enabled,
}

export enum ButtonType {
	Disabled,
	Saving,
	Saved,
	Enabled,
}

export enum PrivacyType {
	Private = "private",
	Public = "public",
}

export enum ParticipantStatusType {
	Active = "active",
	Complete = "complete",
	Scheduled = "scheduled",
}

export enum TournamentStatusType {
	Playing = "active",
	Complete = "complete",
	Scheduled = "scheduled",
}

export enum NavigationSide {
	Prev = "prev",
	Next = "next",
}

export enum CreateLeaguesTab {
	FIRST,
	SECOND,
}

export enum SectionType {
	LEAGUE = "league",
	GENERAL = "general",
}

export enum ShareType {
	FB,
	TW,
	MOBILE,
}

export enum ModalType {
	Error,
	Info,
}

export enum ParticipantTypes {
	DOUBLE = "D",
	SINGLE = "S",
}

export enum EventsTypes {
	MensSinglesQualifying = "Men's Singles Qualifying",
	MensSingles = "Men's Singles",
	WomensSinglesQualifying = "Women's Singles Qualifying",
	WomensSingles = "Women's Singles",
	MensDoubles = "Men's Doubles",
	WomensDoubles = "Women's Doubles",
	MensWheelchairSingles = "Men's Wheelchair Singles",
	WomensWheelchairSingles = "Women's Wheelchair Singles",
	MensWheelchairDoubles = "Men's Wheelchair Doubles",
	WomensWheelchairDoubles = "Women's Wheelchair Doubles",
}

export enum Rounds {
	First = "first",
	Second = "second",
	Third = "third",
	Fourth = "fourth",
	QuarterFinal = "quarterFinal",
	SemiFinal = "semiFinal",
	Final = "final",
}

export enum GenderType {
	Woman = "W",
	Man = "M",
}
