import React from "react";
import styled from "styled-components";
import Spinner from "components/Spinner";

const SPreloader = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 1000;
	background: #ffffff;

	.loader {
		width: 120px;
		height: 120px;
		transform: rotate(-90deg);
		stroke-linecap: round;
		stroke-width: 4;
		fill: none;
	}

	.loader .circle {
		stroke-dashoffset: 0;
		transform-origin: center;
	}

	.loader .circle {
		stroke-dasharray: 312;
		animation: internal 1.5s linear infinite;
	}

	@keyframes internal {
		0% {
			stroke-dashoffset: 187;
		}
		25% {
			stroke-dashoffset: 80;
		}
		100% {
			stroke-dashoffset: 187;
			transform: rotate(360deg);
		}
	}
`;

export const PagePreloader: React.FC = () => {
	return (
		<SPreloader>
			<Spinner color="#004766" />
		</SPreloader>
	);
};
