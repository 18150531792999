import {
	fetchChecksumsJSONFailed,
	fetchChecksumsJSONSuccess,
	fetchParticipantSetsSuccess,
	fetchTournamentsSettingsFailed,
	fetchTournamentsSettingsSuccess,
	requestTournamentsFailed,
	requestTournamentsSuccess,
} from "modules/actions";
import {call, put} from "typed-redux-saga";
import {Api} from "modules/utils/Api";
import {IParticipant, ISagaAction} from "modules/types";

export const requestTournamentsSaga = function* () {
	try {
		const result = yield* call(Api.JSON.tournaments);
		yield* put(requestTournamentsSuccess(result));
	} catch (err) {
		yield* put(requestTournamentsFailed());
	}
};

export const fetchChecksumsJSONSaga = function* () {
	try {
		const result = yield* call(Api.JSON.checksums);
		yield* put(fetchChecksumsJSONSuccess(result));
	} catch (err) {
		yield* put(fetchChecksumsJSONFailed());
	}
};

export const fetchTournamentsSettingsSaga = function* () {
	try {
		const result = yield* call(Api.JSON.tournaments_settings);
		yield* put(fetchTournamentsSettingsSuccess(result));
	} catch (err) {
		yield* put(fetchTournamentsSettingsFailed());
	}
};

export const fetchParticipantSetsSaga = function* ({payload}: ISagaAction<IParticipant>) {
	try {
		const result = yield* call(Api.JSON.participantSets, payload.id);

		yield* put(
			fetchParticipantSetsSuccess({
				[payload.id]: {
					status: payload.status,
					set: result,
				},
			})
		);
	} catch (err) {
		yield* put(fetchTournamentsSettingsFailed());
	}
};
