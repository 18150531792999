import React from "react";
import {IRoundItem} from "modules/selectors";
import {DisplayParticipantsList} from "./DisplayParticipantsList";
import {sliceParticipantsIntoChunks} from "modules/utils";
import styled from "styled-components";
import ReactPageScroller from "react-page-scroller";
import {IParticipant} from "modules/reducers";

const WrapperParticipantsList = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;

	@media (min-width: ${({theme}) => theme.media.desktop}) {
		flex-direction: initial;
		height: 100%;
		padding: 0 10px;
	}
`;

const getCartsBlockDataByParticipantCounts = (participantCounts: number) => {
	if (participantCounts === 32) {
		return {
			chunkedRound1: 8,
			chunkedRound2: 6,
			chunkedRound3: 6,
			scrollerHeight: "1770px",
		};
	}

	return {
		chunkedRound1: 4,
		chunkedRound2: 2,
		chunkedRound3: 1,
		scrollerHeight: "950px",
	};
};

interface IProps {
	participant1: IRoundItem | null;
	participant2: IRoundItem | null;
	participant3: IRoundItem | null;
	activeSubTab: number;
	activeRoundName: string;
	firstRoundParticipantCount: number;
}

export const CardsForRoundMobile: React.FC<IProps> = ({
	participant1,
	activeSubTab,
	activeRoundName,
	firstRoundParticipantCount,
}) => {
	const {chunkedRound1, scrollerHeight} = getCartsBlockDataByParticipantCounts(
		firstRoundParticipantCount
	);

	const chunkedRound1Participants = sliceParticipantsIntoChunks(
		participant1?.participants,
		chunkedRound1
	) as [IParticipant[]];

	return (
		<ReactPageScroller
			animationTimer={600}
			customPageNumber={activeSubTab}
			blockScrollUp={true}
			blockScrollDown={true}
			renderAllPagesOnFirstRender={true}
			containerHeight={scrollerHeight}
			containerWidth={"auto"}>
			{chunkedRound1Participants.map((participants, key) => (
				<WrapperParticipantsList key={key}>
					<DisplayParticipantsList
						participants={chunkedRound1Participants}
						activeKey={key}
						roundName={participant1?.roundName}
						isActive={activeRoundName === participant1?.roundName}
						participantBlockNumber={1}
					/>
				</WrapperParticipantsList>
			))}
		</ReactPageScroller>
	);
};
