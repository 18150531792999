import React, {Fragment} from "react";
import styled from "styled-components";
import {IParticipant} from "modules/reducers";
import {Players} from "components/GameCard/Players";
import {get} from "lodash";
import {useSelector} from "react-redux";
import {getActiveTournamentSettings} from "modules/selectors";
import {CardHeaderWrapper} from "components/GameCard/CardHeader";
import {Exist} from "components";
import {ParticipantStatusType} from "modules/enums";
import {ReactComponent as ClockIcon} from "assets/images/icons/clockIcon.svg";
import {DateTime} from "luxon";
import {MatchDurationWrapper, MatchDurationText} from "components/GameCard/CardFooter";

const Wrapper = styled.div`
	width: 100%;
	max-width: 312px;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
	border-radius: 6px;
	margin-top: 16px;

	@media (min-width: ${({theme}) => theme.media.desktop}) {
		margin-top: 0;
	}
`;

const Head = styled(CardHeaderWrapper)`
	display: flex;
	align-items: center;
	font-weight: 400;
	font-size: 13px;
	line-height: 18px;
	color: #ffffff;
	padding: 7px 12px;

	strong {
		font-weight: 700;
	}
`;

const GameTimeWrapper = styled.p`
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;

	svg {
		margin-left: 5px;
	}
`;
const updateDisplayTime = (time: string | null) => {
	if (time) {
		const timeObj = DateTime.fromISO(time);
		return `${timeObj.get("hour")}h:${timeObj.get("minute")}m`;
	}

	return "";
};
interface IScoreCard {
	participant: IParticipant;
}

export const ScoreCard: React.FC<IScoreCard> = ({participant}) => {
	const settings = useSelector(getActiveTournamentSettings);

	const statusLabels = {
		[ParticipantStatusType.Complete]: "Completed",
		[ParticipantStatusType.Active]: "In progress",
	};

	return (
		<Wrapper>
			<Head background={get(settings, "scoreCentre.cardHeaderColour", "")}>
				<Exist when={participant.status !== ParticipantStatusType.Scheduled}>
					<div>{get(statusLabels, participant.status, "")}</div>
				</Exist>
				<Exist when={participant.status === ParticipantStatusType.Active}>
					<GameTimeWrapper>
						<strong>{updateDisplayTime(participant.timeTotal)}</strong> <ClockIcon />
					</GameTimeWrapper>
				</Exist>

				<Exist when={participant.status === ParticipantStatusType.Complete}>
					<Fragment>
						<Exist when={Boolean(participant.timeTotal)}>
							<MatchDurationWrapper>
								<MatchDurationText>
									Match duration:{"\xa0"}
									<strong>{updateDisplayTime(participant.timeTotal)}</strong>
								</MatchDurationText>
							</MatchDurationWrapper>
						</Exist>
					</Fragment>
				</Exist>
			</Head>

			<Players participant={participant} showRetiresScore={true} />
		</Wrapper>
	);
};
