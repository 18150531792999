import {all, takeLatest} from "redux-saga/effects";
import {
	fetchChecksumsJSON,
	fetchParticipantSets,
	fetchPlayerMatches,
	fetchPlayers,
	fetchPlayerSets,
	fetchTournamentsSettings,
	requestTournaments,
	subscribeToLiveScores,
} from "modules/actions";
import {SagaIterator} from "redux-saga";
import {fetchPlayerSetsSaga, fetchPlayersSaga, fetchPlayerMatchesSaga} from "./players";
import {
	fetchChecksumsJSONSaga,
	requestTournamentsSaga,
	fetchTournamentsSettingsSaga,
	fetchParticipantSetsSaga,
} from "./json";
import {fetchLiveScoresSaga} from "./liveScoring";

export const rootSaga = function* (): SagaIterator {
	yield all([
		// JSONs
		takeLatest(fetchPlayers, fetchPlayersSaga),
		takeLatest(fetchPlayerSets, fetchPlayerSetsSaga),
		takeLatest(fetchPlayerMatches, fetchPlayerMatchesSaga),
		takeLatest(fetchParticipantSets, fetchParticipantSetsSaga),
		/**
		 * Tournament
		 */
		takeLatest(requestTournaments, requestTournamentsSaga),
		/**
		 * LiveScores
		 * */
		takeLatest(subscribeToLiveScores, fetchLiveScoresSaga),
		takeLatest(fetchChecksumsJSON, fetchChecksumsJSONSaga),
		/**
		 * Tournaments Settings
		 * */
		takeLatest(fetchTournamentsSettings, fetchTournamentsSettingsSaga),
	]);
};

export * from "./json";
export * from "./liveScoring";
export * from "./players";
