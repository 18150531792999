export const ENV = process.env.REACT_APP_ENVIRONMENT;
export const BASE_URL = process.env.REACT_APP_BASE_URL || "";
export const API_URL = process.env.REACT_APP_API_URL || "";
export const JSON_URL = process.env.REACT_APP_JSON_URL || "";
export const IMAGES_URL = process.env.REACT_APP_IMAGES_URL || "";
export const STORAGE_VERSION = 1;

export const ROUNDS_NAMES = {
	first: "Round 1",
	second: "Round 2",
	third: "Round 3",
	fourth: "Round 4",
	quarterFinal: "Quarter-finals",
	semiFinal: "Semi-finals",
	final: "Final",
};

export const EVENTS_LIST = [
	"Men's Singles Qualifying",
	"Men's Singles",
	"Women's Singles Qualifying",
	"Women's Singles",
	"Men's Doubles",
	"Women's Doubles",
	"Men's Wheelchair Singles",
	"Women's Wheelchair Singles",
	"Men's Wheelchair Doubles",
	"Women's Wheelchair Doubles",
	"Women's Qualifying",
];

export const SPECIAL_SYMBOLS = {
	ä: "a",
	Ä: "A",
	ö: "o",
	Ö: "O",
	ü: "u",
	Ü: "U",
	á: "a",
	í: "i",
	é: "e",
	å: "a",
	ß: "b",
};

export const getSortKeys = () => {
	const sortKeys = [
		{
			name: "Center Court",
			value: -6,
		},
		{
			name: "Centre Court",
			value: -6,
		},
		{
			name: "Ann Jones Centre Court",
			value: -5,
		},
		{
			name: "Grand Stand Arena",
			value: -4,
		},
		{
			name: "Pietrangeli",
			value: -3,
		},
		{
			name: "Patrice Dominguez",
			value: -2,
		},
		{
			name: "Court Studenac",
			value: -1,
		},
	];

	for (let i = 1; i < 50; i++) {
		sortKeys.push({
			name: `Court ${i}`,
			value: i,
		});
	}

	return sortKeys;
};
