import React from "react";
import styled from "styled-components";

const Wrapper = styled.footer`
	padding-top: 50px;
	padding-bottom: 20px;
`;

export const Footer: React.FC = () => {
	return (
		<Wrapper>
			Footer
		</Wrapper>
	);
};
