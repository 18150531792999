import styled from "styled-components";

export const InnerWrapper = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	transition: transform 1s;
	transform-style: preserve-3d;
	border-radius: 6px;
`;

export const Container = styled.div`
	width: 100%;
	box-sizing: border-box;
	background: #ffffff;
	box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
	height: 100%;
	backface-visibility: hidden;
	-webkit-perspective: 0;
	-webkit-backface-visibility: hidden;
	-webkit-transform: translate3d(0, 0, 0);
	max-width: 1020px;
	margin: 0 auto;
	padding-bottom: 10px;
`;

export const PageWrapper = styled.div<{$isShow: boolean; $isShowProfile: boolean}>`
	background-color: transparent;
	width: 100%;
	height: 100%;
	perspective: 1000px;
	max-width: 1020px;
	margin: 0 auto;
	overflow: hidden;

	${({$isShow, $isShowProfile}) =>
		($isShow || $isShowProfile) &&
		`
		${InnerWrapper} {
			transform: rotateY(180.01deg);
			min-height: 100vh;
		}
		${Container} {
			pointer-events: none;
		}
	`}

	${({$isShow, $isShowProfile}) =>
		$isShow &&
		$isShowProfile &&
		`
		${InnerWrapper} {
			transform: rotateY(360deg) !important;
		}
	`}
`;

export const ContainerWithSidebar = styled(Container)`
	padding: 20px;

	@media (max-width: 1000px) {
		flex-direction: column;
	}
`;
