import styled from "@emotion/styled";
import TextField from "@mui/material/TextField";
import SearchIcon from "assets/images/icons/search.svg";

export const Input = styled(TextField)`
	color: #a3a3a3;
	text-align: left;
	background-color: #ffffff;
	border-radius: 6px;

	.MuiFilledInput-root {
		background: transparent;
	}

	.MuiInputLabel-root,
	.MuiInputLabel-root.Mui-focused {
		color: #a3a3a3;
	}

	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
		border-color: var(--primaryColor);
	}

	.MuiFilledInput-root::before,
	.MuiFilledInput-root::after {
		border-radius: 3px;
	}

	.MuiFilledInput-root::after {
		border-color: #a3a3a3;
	}
`;

Input.defaultProps = {
	variant: "outlined",
	fullWidth: true,
};

export const SearchInput = styled(Input)`
	background: #ffffff url(${SearchIcon}) no-repeat 96%;
	text-transform: none;
	scrollbar-width: none;
	-ms-overflow-style: none;

	&::-webkit-scrollbar {
		display: none; /* for Chrome, Safari, and Opera */
	}

	label.MuiInputLabel-root {
		color: #333333;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 18px;
	}

	.MuiFilledInput-root::after,
	.MuiFilledInput-root::before {
		display: none !important;
	}
`;
