import React, {useCallback, useEffect} from "react";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {
	getActiveRoundsByActiveEvent,
	getActiveTournamentSettings,
	getTournamentsBySettings,
} from "modules/selectors";
import {map, get, partial} from "lodash";
import {IParticipant} from "modules/reducers";
import {sendDataLayer, sliceParticipantsIntoChunks, useIsMobile} from "modules/utils";
import {Exist} from "components";

const Wrapper = styled.div<{background: string; isDisplaySingleTournament: boolean}>`
	position: relative;
	width: 100%;
	margin: 0 auto 50px;
	height: 75px;
	z-index: 2;
	background: ${({background}) =>
		background || "linear-gradient(90deg, #5F249F 0%, #B426C8 100%)"};

	@media (min-width: ${({theme}) => theme.media.desktop}) {
		top: 0;
		display: flex;
	}
`;

const ListNav = styled.div<{countRounds: number}>`
	display: flex;
	align-items: center;
	width: 336px;
	height: 100%;
	color: #ffffff;
	justify-content: ${({countRounds}) => (countRounds <= 3 ? "center" : "space-evenly")};
	padding: 0;
	margin: 0 auto;

	@media (min-width: ${({theme}) => theme.media.desktop}) {
		width: 100%;
		justify-content: center;
		padding: 0;
	}
`;

interface INavButton {
	isActive?: boolean;
	playerHighlightColour: string;
}

const NavButton = styled.button<INavButton>`
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 18px;
	color: #ffffff;
	text-align: center;
	margin: 0 10px;
	border: none;
	background: transparent;

	p {
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: 20px;
		text-decoration: ${({isActive}) => (isActive ? "underline" : "initial")};
		color: ${({isActive}) => (isActive ? "#ffffff" : "#afa7ca")};
		text-underline-offset: 5px;
	}

	@media screen and (max-width: 900px) {
		margin: 0 5px;
	}
`;

const SubNavigationWrapper = styled.div`
	position: absolute;
	bottom: -45px;
	left: 0;
	display: flex;
	width: 100%;
	text-align: center;
	margin: 15px auto 15px;
	justify-content: center;

	.slick-list {
		width: 200px;
	}

	.slick-track {
		margin: 0 auto;
	}
`;

const ItemsWrapper = styled.div`
	display: flex;
	justify-content: start;
	align-items: center;
	max-width: 260px;
	overflow: scroll;
	scrollbar-width: none;
	-ms-overflow-style: none;

	&::-webkit-scrollbar {
		display: none; /* for Chrome, Safari, and Opera */
	}

	@media (min-width: ${({theme}) => theme.media.desktop}) {
		justify-content: center;
		max-width: 100%;
	}
`;

interface INumberItem {
	color: string;
	activeColor?: string;
	isActive?: boolean;
}

const NumberItem = styled.button<INumberItem>`
	color: ${({isActive, activeColor, color}) => (isActive ? activeColor : color)};
	background: transparent;
	border: none;
	font-weight: 700;
	font-size: 16px;
	line-height: 18px;
	margin: 0 7px;

	&:disabled {
		opacity: 0.6;
	}
`;

const RoundList = {
	first: "Round 1",
	second: "Round 2",
	third: "Round 3",
	fourth: "Round 4",
	quarterFinal: "Quarter-finals",
	semiFinal: "Semi-finals",
	final: "Final",
};

const MobileRoundList = {
	first: "R1",
	second: "R2",
	third: "R3",
	fourth: "R4",
	quarterFinal: "QF",
	semiFinal: "SF",
	final: "F",
};

interface ISubNavigation {
	participant: IParticipant[];
	activeSubTab: number;
	setActiveSubTab: (item: number) => void;
}

const SubNavigation: React.FC<ISubNavigation> = ({participant, activeSubTab, setActiveSubTab}) => {
	const settings = useSelector(getActiveTournamentSettings);
	const chunkSize = participant.length === 32 ? 8 : 4;
	const chunkedParticipants = sliceParticipantsIntoChunks(participant, chunkSize) as [
		IParticipant[]
	];
	const pageScrollerArrowsColour = get(settings, "scoreCentre.pageScrollerArrowsColour")!;
	const prevValue = activeSubTab - 1;
	const nextValue = activeSubTab + 1;

	return chunkedParticipants.length > 1 ? (
		<SubNavigationWrapper>
			<NumberItem
				onClick={partial(setActiveSubTab, prevValue)}
				disabled={activeSubTab <= 0}
				color={pageScrollerArrowsColour}>
				{"<"}
			</NumberItem>
			<ItemsWrapper>
				{chunkedParticipants.map((participants, key) => (
					<NumberItem
						key={key}
						onClick={partial(setActiveSubTab, key)}
						activeColor={get(settings, "scoreCentre.pageScrollerCurrentPageColour")}
						color={get(settings, "scoreCentre.pageScrollerOtherPagesColour", "")}
						id={`subMenu${key}`}
						isActive={key === activeSubTab}>
						{++key}
					</NumberItem>
				))}
			</ItemsWrapper>
			<NumberItem
				onClick={partial(setActiveSubTab, nextValue)}
				disabled={activeSubTab >= chunkedParticipants.length - 1}
				color={pageScrollerArrowsColour}>
				{">"}
			</NumberItem>
		</SubNavigationWrapper>
	) : null;
};

interface IProps {
	activeTab: number;
	activeSubTab: number;
	goToSlide: (item: number) => void;
	setActiveSubTab: (item: number) => void;
	setActiveRoundName: (item: string) => void;
}

export const Navigation: React.FC<IProps> = ({
	activeTab,
	activeSubTab,
	goToSlide,
	setActiveSubTab,
	setActiveRoundName,
}) => {
	const {isMobile} = useIsMobile();
	const settings = useSelector(getActiveTournamentSettings);
	const rounds = useSelector(getActiveRoundsByActiveEvent);
	const tournaments = useSelector(getTournamentsBySettings);
	const onChangeRound = useCallback(
		(e: React.SyntheticEvent<HTMLButtonElement>) => {
			const nav = e.currentTarget.dataset.nav as string;
			const roundName = e.currentTarget.dataset.round as string;
			const navNum = parseInt(nav, 10);
			sendDataLayer(`draw_round_${roundName}_click`);

			goToSlide(navNum);
			setActiveRoundName(roundName);
		},
		[goToSlide, setActiveRoundName]
	);

	useEffect(() => {
		setActiveSubTab(0);
	}, [activeTab, setActiveSubTab]);

	if (!rounds.length) {
		return null;
	}

	return (
		<Wrapper
			background={settings?.scoreCentre.headerPanelColour || ""}
			isDisplaySingleTournament={tournaments.length === 1}>
			<ListNav countRounds={rounds.length}>
				{map(rounds, (round, key) => (
					<NavButton
						className={round.roundName}
						playerHighlightColour={settings?.scoreCentre.playerHighlightColour || ""}
						isActive={activeTab === key}
						key={round.roundName}
						data-nav={key}
						data-round={round.roundName}
						onClick={onChangeRound}>
						<p>{get(isMobile ? MobileRoundList : RoundList, round.roundName, "")}</p>
						<Exist when={activeTab === key}>
							<SubNavigation
								participant={round.participants}
								activeSubTab={activeSubTab}
								setActiveSubTab={setActiveSubTab}
							/>
						</Exist>
					</NavButton>
				))}
			</ListNav>
		</Wrapper>
	);
};
