import {createSelector} from "reselect";
import {IStore} from "modules/types/store";
import {
	concat,
	first,
	flatMap,
	get,
	isNumber,
	orderBy,
	reduce,
	uniqBy,
	keys,
	map,
	isUndefined,
	sortBy,
	find,
	chain,
} from "lodash";
import {IParticipant, IPlayerSet, IRounds, ISet, ITournament, TTeam} from "modules/reducers";
import {formatDate} from "modules/utils";
import {ParticipantStatusType, Rounds} from "modules/enums";
import {getSortKeys} from "modules/constants";

export const getJSONState = (state: IStore) => state.jsons;

export const getTournamentRequestState = createSelector(
	getJSONState,
	(state) => state.requestState
);

export const getTournaments = createSelector(getJSONState, (state) => state.tournaments);

export const getChecksums = createSelector(getJSONState, (jsons) => jsons.checksums);

export const getTournamentsSettings = createSelector(
	getJSONState,
	(state) => state.tournamentsSettings
);

export const getActiveEvent = createSelector(getJSONState, (state) => state.activeEvent);

export const getPlayerHoverId = createSelector(getJSONState, (state) => state.hoverPlayer);

export const getMatchSets = createSelector(getJSONState, (state) => state.matchSets);

export const getPlayersSets = createSelector(getJSONState, (state) => state.playersSets);

export const getPlayersParticipants = createSelector(getJSONState, (state) => {
	let participants: IParticipant[] = [];

	map(state.playersMatches, (playerParticipants, roundName) => {
		if (playerParticipants && playerParticipants.length) {
			participants = [
				...participants,
				...playerParticipants.map((partial) => ({
					...partial,
					roundName: roundName,
				})),
			];
		}
	});

	const orderByDate = orderBy(participants, ["startDate"], ["desc"]);

	return orderBy(orderByDate, ["event"], ["desc"]);
});

export const getFilterTournamentBy = createSelector(
	getJSONState,
	(state) => state.filterTournamentBy
);

export const getMatchSetsById = createSelector(
	getMatchSets,
	(sets) => (id: number) => get(sets, `${id}.set`, []) as ISet[]
);

const tournamentsLostForSort = {
	"Open Nottingham": -6,
	"cinch Championships": -5,
	"Classic Birmingham": -4,
	Championships: -3,
	"International Eastbourne": -2,
	"Davis Cup": -1,
	"ATP Cup": 0,
};

const sortTournamentsByWeighting = (tournament: ITournament) =>
	find(tournamentsLostForSort, (item, key) => tournament.name.indexOf(key) === 0) || 1;

export const getTournamentsBySettings = createSelector(
	getTournaments,
	getTournamentsSettings,
	getFilterTournamentBy,
	(tournaments, settings, filterBy) => {
		if (!isUndefined(filterBy) && tournaments) {
			const allowedTournaments = tournaments.filter((tournament) =>
				settings.find(
					(item) => item.tournamentId === tournament.id && filterBy === item.displayOnPage
				)
			);
			return orderBy(allowedTournaments, sortTournamentsByWeighting);
		}

		const allowedTournaments = tournaments.filter(
			(
				tournament // TODO tournaments is undefined check bug
			) => settings.find((item) => item.tournamentId === tournament.id)
		);

		return orderBy(allowedTournaments, sortTournamentsByWeighting);
	}
);

export const getTournamentById = createSelector(
	getTournamentsBySettings,
	(tournaments) => (id?: number) => tournaments.find((tournament) => tournament.id === id)
);

// get store value
export const getSelectTournamentId = createSelector(
	getJSONState,
	(state) => state.activeTournamentId
);

// get store value or first tournament
export const getActiveTournamentId = createSelector(
	getJSONState,
	getTournamentsBySettings,
	(state, tournaments) => {
		if (
			isNumber(state.activeTournamentId) &&
			tournaments.find((tournament) => tournament.id === state.activeTournamentId)
		) {
			return state.activeTournamentId;
		}

		return first(tournaments)?.id;
	}
);

export const getSelectTournament = createSelector(
	getTournamentsBySettings,
	getSelectTournamentId,
	(tournaments, id) => tournaments.find((tournament) => tournament.id === id)
);

export const getActiveTournament = createSelector(
	getTournamentsBySettings,
	getActiveTournamentId,
	(tournaments, id) => {
		return tournaments.find((tournament) => tournament.id === id);
	}
);

export const getActiveRoundName = createSelector(getJSONState, (state) => {
	if (state.activeRoundName) {
		return state.activeRoundName;
	}

	return "";
});

export const getActiveCourt = createSelector(getJSONState, (state) => {
	if (state.activeCourt) {
		return state.activeCourt;
	}

	return "";
});

export const getActiveCompleteCourt = createSelector(getJSONState, (state) => {
	if (state.activeCompleteCourt) {
		return state.activeCompleteCourt;
	}

	return "";
});

export const getPlayingCourt = createSelector(getJSONState, (state) => {
	if (state.playingCourt) {
		return state.playingCourt;
	}

	return "";
});

export const getActiveRound = createSelector(
	getActiveRoundName,
	getActiveTournament,
	(roundName, tournament) => {
		return get(tournament, `rounds.${roundName}`, []) as unknown as IParticipant;
	}
);

export const getSelectDay = createSelector(getJSONState, (state) => state.activeDay);
export const getSelectDayForOrderOfPlay = createSelector(
	getJSONState,
	(state) => state.selectDayForOrderOfPlay
);

export const getMatchById = createSelector(
	getActiveTournament,
	(tournament) => (id: number | null) =>
		flatMap(get(tournament, "rounds", [])).find((match: IParticipant) => match.id === id)
);

export const getActiveTournamentSettings = createSelector(
	getJSONState,
	getActiveTournamentId,
	(state, id) => {
		return state.tournamentsSettings.find((settings) => settings.tournamentId === id);
	}
);

export const getActiveParticipantsByRound = createSelector(
	getActiveRoundName,
	getActiveTournament,
	(activeRoundName, activeTournament) =>
		get(activeTournament?.rounds, activeRoundName, []).map((participant: IParticipant) => ({
			...participant,
			roundName: activeRoundName,
		})) || []
);

export const getParticipantsByRound = createSelector(
	getActiveTournament,
	(activeTournament) => (roundName: Rounds) =>
		get(activeTournament?.rounds, roundName, []).map((participant: IParticipant) => ({
			...participant,
			roundName: roundName,
		})) || []
);

export const getAllActiveParticipants = createSelector(getActiveTournament, (activeTournament) =>
	reduce(
		activeTournament?.rounds,
		(accumulator: IParticipant[], participants: IParticipant[], roundName) => {
			const participantsWithRoundName = participants
				.filter(
					(participant) =>
						participant.courtName !== "Court 0" && !participant.isDrawPageOnly
				)
				.map((participant: IParticipant) => ({
					...participant,
					roundName: roundName,
				}));

			return concat(accumulator, participantsWithRoundName);
		},
		[]
	)
);

export const getAllActiveParticipantsWithAllCourts = createSelector(
	getActiveRoundName,
	getActiveTournament,
	(activeRoundName, activeTournament) =>
		reduce(
			activeTournament?.rounds,
			(accumulator: IParticipant[], participants: IParticipant[], roundName) => {
				const participantsWithRoundName = participants.map((participant: IParticipant) => ({
					...participant,
					roundName: roundName,
				}));

				return concat(accumulator, participantsWithRoundName);
			},
			[]
		)
);

const eventsListForSortActiveStatus = {
	"Women's Singles": -6,
	"Men's Singles": -5,
	"Women's Doubles": -4,
	"Men's Doubles": -3,
	"Women's Qualifying": -1,
	"Men's Qualifying": 0,
};

const eventsListForSort = {
	"Women's Singles": 1,
	"Men's Singles": 2,
	"Women's Doubles": 3,
	"Men's Doubles": 4,
	"Women's Qualifying": 6,
	"Men's Qualifying": 7,
};

export const getEventsByParticipants = createSelector(
	getAllActiveParticipantsWithAllCourts,
	(participants) => {
		const events = reduce(
			participants,
			(events: string[], participant) => {
				if (!events.find((item) => item === participant.event)) {
					return concat(events, [participant.event]);
				}
				return events;
			},
			[]
		);

		// Return Events in hardcoded order
		return sortBy(events, (event) => {
			if (
				participants.find(
					(participant) =>
						participant.event === event &&
						participant.status === ParticipantStatusType.Active
				)
			) {
				return get(eventsListForSortActiveStatus, event, -2);
			}

			return get(eventsListForSort, event, 5);
		});
	}
);

export const getActualEvent = createSelector(
	getActiveEvent,
	getEventsByParticipants,
	(event, eventsList) => (event === "" ? first(eventsList) : event) || ""
);

export interface IRoundItem {
	participants: IParticipant[];
	roundName: string;
}

export const getActiveRoundsByActiveEvent = createSelector(
	getActiveTournament,
	getActualEvent,
	(tournament, event) => {
		const rounds = get(tournament, "rounds", {});
		const roundsItems = map(rounds, (participants: IParticipant[], roundName) => {
			const participantsList = participants.filter(
				(participant: IParticipant) => participant.event === event
			);
			return {
				participants: orderBy(participantsList, ["numberInDrawRoundColumn"], ["asc"]),
				roundName: roundName,
			};
		}) as IRoundItem[];
		return roundsItems.filter((round) => round.participants.length !== 0);
	}
);

export interface IDaysData {
	date: string;
	formatDate: string;
	status: ParticipantStatusType;
}

const getDaysData = (activeRoundName: string, activeEvent: string, tournament?: ITournament) => {
	const rounds =
		activeRoundName === ""
			? get(tournament, `rounds`, [])
			: get(tournament, `rounds.${activeRoundName}`, []);

	const days = chain(rounds)
		.values()
		.flatten()
		.filter(
			(match: IParticipant) =>
				(activeEvent === "" || match.event === activeEvent) &&
				!match.isBye &&
				match.courtName !== "Court 0" &&
				!match.isDrawPageOnly
		)
		.map((match: IParticipant) => ({
			date: match.startDate,
			formatDate: formatDate(match.startDate),
			status: match.status,
		}))
		.value();

	const daysList = uniqBy(orderBy(days, ["status"], "asc"), "formatDate");

	return orderBy(daysList, ["date"], "desc") as unknown as IDaysData[];
};

export const getDays = createSelector(
	getActiveTournament,
	getActiveRoundName,
	getActualEvent,
	(tournament, activeRoundName, actualEvent) => {
		return getDaysData(activeRoundName, actualEvent, tournament);
	}
);

export const getDaysForOrderOfPlay = createSelector(
	getActiveTournament,
	getActiveRoundName,
	getActiveEvent,
	(tournament, activeRoundName, activeEvent) => {
		return getDaysData(activeRoundName, activeEvent, tournament);
	}
);

export const getRoundsByActiveTournament = createSelector(
	getActiveTournament,
	(tournament) => (isLivePage: boolean) => {
		const rounds = get(tournament, `rounds`, []) as IRounds;

		return reduce(
			rounds,
			(roundsList: string[], participants, roundName) => {
				if (isLivePage) {
					if (
						participants.filter(
							(participant) => participant.status === ParticipantStatusType.Active
						).length
					) {
						roundsList.push(roundName);
					}
				} else if (
					participants.filter(
						(participant) => participant.status === ParticipantStatusType.Complete
					).length
				) {
					roundsList.push(roundName);
				}

				return roundsList;
			},
			[]
		);
	}
);

export const getActiveRoundNameForDraft = createSelector(
	getActiveRoundName,
	getActiveTournament,
	(roundName, tournament) => {
		if (roundName !== "") {
			return roundName;
		}

		return first(keys(get(tournament, "rounds", "first"))) as string;
	}
);

interface IDayItem {
	date: string;
	formatDate: string;
	status: string;
}

const getActiveDayData = (
	day: string,
	days: IDayItem[],
	isComplete?: boolean,
	isActive?: boolean,
	participants?: IParticipant[]
) => {
	if (!days.length) {
		return "";
	}

	if (day) {
		return day;
	}

	const activeDay = days.find((day) => day.status === ParticipantStatusType.Active);
	const completeDay = days.find((day) =>
		participants?.find((participant) => formatDate(participant.startDate) === day.formatDate)
	);

	if (isComplete && completeDay) {
		return completeDay.date;
	}

	if (activeDay && isActive) {
		return activeDay.date;
	}

	return first(days)?.date as string;
};

export const getActiveDay = createSelector(getSelectDay, getDays, (day, days) => {
	return getActiveDayData(day, days, false, true);
});

export const getActiveDayForOrderOfPlay = createSelector(
	getSelectDayForOrderOfPlay,
	getDaysForOrderOfPlay,
	(day, days) => {
		return getActiveDayData(day, days);
	}
);

const getFilteredParticipants = (
	// activeRoundName: string,
	day: string,
	event: string,
	// activeParticipantsByRound: IParticipant[],
	allActiveParticipants: IParticipant[]
) => {
	const activeDay = formatDate(day);
	const participantsList = allActiveParticipants;

	// Remove filter by round. You can uncomment it if need to return filter.
	// const participantsList =
	// 	activeRoundName !== "" ? activeParticipantsByRound : allActiveParticipants;

	const filterParticipantsList = () => {
		let participants = participantsList;

		if (day && participants.find((item) => item.startDate === day)) {
			participants = participants.filter(
				(match: IParticipant) => formatDate(match.startDate) === activeDay
			);
		}

		if (event !== "") {
			participants = participants.filter((match: IParticipant) => match.event === event);
		}

		return participants;
	};

	const list = filterParticipantsList();

	return orderBy(list, ["startDate"], ["desc"]);
};

export const getActiveParticipants = createSelector(
	getActiveRoundName,
	getActiveDay,
	getActualEvent,
	getActiveParticipantsByRound,
	getAllActiveParticipants,
	(activeRoundName, day, event, activeParticipantsByRound, allActiveParticipants) => {
		return getFilteredParticipants(
			// activeRoundName,
			day,
			event,
			// activeParticipantsByRound,
			allActiveParticipants
		);
	}
);

export const geParticipantsForOrderOfPlay = createSelector(
	// getActiveRoundName,
	getActiveDayForOrderOfPlay,
	getActiveEvent,
	// getActiveParticipantsByRound,
	getAllActiveParticipants,
	(day, activeEvent, allActiveParticipants) => {
		return getFilteredParticipants(
			// activeRoundName,
			day,
			activeEvent,
			// activeParticipantsByRound,
			allActiveParticipants
		);
	}
);

export const getActiveParticipantsByActiveCourt = createSelector(
	geParticipantsForOrderOfPlay,
	getActiveCourt,
	(participants, courtName) => {
		if (courtName !== "") {
			participants = participants.filter(
				(match: IParticipant) => match.courtName === courtName
			);
		}
		const cortKeys = getSortKeys();

		const filteredParticipants = participants.map((participant) => ({
			...participant,
			courtOrderKey: cortKeys.find((item) => item.name === participant.courtName)?.value,
		}));

		return orderBy(filteredParticipants, ["courtOrderKey", "startDate"], ["desc"]);
	}
);

export const getActiveLiveParticipants = createSelector(
	// getActiveRoundName,
	getActiveDay,
	getActiveEvent,
	// getActiveParticipantsByRound,
	getAllActiveParticipants,
	(activeDay, event, allActiveParticipants) => {
		return getFilteredParticipants(
			// activeRoundName,
			activeDay,
			event,
			// activeParticipantsByRound,
			allActiveParticipants
		);
	}
);

export const getPlayingParticipants = createSelector(
	getActiveLiveParticipants,
	(participants: IParticipant[]) => {
		const cortKeys = getSortKeys();
		const filteredParticipants = participants.map((participant) => ({
			...participant,
			courtOrderKey: cortKeys.find((item) => item.name === participant.courtName)?.value,
		}));

		return orderBy(
			filteredParticipants.filter(
				(participant) => participant.status === ParticipantStatusType.Active
			),
			["courtOrderKey", "startDate"],
			["asc"]
		);
	}
);

export const getPlayingParticipantsByCourt = createSelector(
	getPlayingParticipants,
	getPlayingCourt,
	(participants: IParticipant[], court) => {
		return court !== ""
			? participants.filter((match: IParticipant) => match.courtName === court)
			: participants;
	}
);

export const getAllCompleteParticipants = createSelector(
	getAllActiveParticipants,
	(participants: IParticipant[]) =>
		participants.filter(
			(participant) =>
				participant.status === ParticipantStatusType.Complete && !participant.isBye
		)
);

export const getAllCompleteParticipantsByCourt = createSelector(
	getAllCompleteParticipants,
	getActiveCompleteCourt,
	getActiveEvent,
	(participants: IParticipant[], court, event) => {
		let filteredParticipants =
			court !== ""
				? participants.filter((match: IParticipant) => match.courtName === court)
				: participants;

		const cortKeys = getSortKeys();

		if (event !== "") {
			filteredParticipants = participants.filter(
				(match: IParticipant) => match.event === event
			);
		}

		filteredParticipants = filteredParticipants.map((participant) => ({
			...participant,
			courtOrderKey: cortKeys.find((item) => item.name === participant.courtName)?.value,
		}));

		return orderBy(filteredParticipants, ["courtOrderKey"], ["asc"]);
	}
);

export const getCompleteDay = createSelector(
	getSelectDay,
	getDaysForOrderOfPlay,
	getAllCompleteParticipantsByCourt,
	(day, days, participants) => {
		return getActiveDayData(day, days, true, false, participants);
	}
);

export const getCompleteParticipants = createSelector(
	getActiveRoundName,
	getCompleteDay,
	getActiveEvent,
	getActiveParticipantsByRound,
	getAllActiveParticipants,
	(activeRoundName, day, event, activeParticipantsByRound, allActiveParticipants) => {
		return getFilteredParticipants(
			// activeRoundName,
			day,
			event,
			// activeParticipantsByRound,
			allActiveParticipants
		).filter(
			(participant) =>
				participant.status === ParticipantStatusType.Complete && !participant.isBye
		);
	}
);

export const getCompleteParticipantsByCourt = createSelector(
	getCompleteParticipants,
	getActiveCompleteCourt,
	(participants: IParticipant[], court) => {
		let filteredParticipants =
			court !== ""
				? participants.filter((match: IParticipant) => match.courtName === court)
				: participants;

		const cortKeys = getSortKeys();

		filteredParticipants = filteredParticipants.map((participant) => ({
			...participant,
			courtOrderKey: cortKeys.find((item) => item.name === participant.courtName)?.value,
		}));

		return orderBy(filteredParticipants, ["courtOrderKey"], ["asc"]);
	}
);

export const getScheduledParticipants = createSelector(
	getActiveParticipants,
	(participants: IParticipant[]) =>
		participants.filter((participant) => participant.status === ParticipantStatusType.Scheduled)
);

export const getNotScheduledParticipants = createSelector(
	getActiveParticipants,
	(participants: IParticipant[]) =>
		orderBy(
			participants.filter(
				(participant) =>
					participant.status !== ParticipantStatusType.Scheduled && !participant.isBye
			),
			["status"],
			["asc"]
		)
);

// export const getGamesByCourtName = createSelector(
// 	getActiveParticipants,
// 	getCourtNamesByTournament,
// 	(participants: IParticipant[], courtNames: (string | null)[]) =>
// 		courtNames.map((courtName) => ({
// 			courtName: courtName || "",
// 			games:
// 				participants
// 					.filter((participant) => participant.courtName === courtName)
// 					.filter((x) => !x.isBye) || [],
// 		}))
// );

export const getGamesByCourtName = createSelector(
	getActiveParticipantsByActiveCourt,
	(participants: IParticipant[]) => {
		console.log(participants);
		return orderBy(
			participants.map((item) => ({
				...item,
				day:
					item.notBeforeText === "Followed By" &&
					item.status !== ParticipantStatusType.Complete
						? 100
						: item.status === ParticipantStatusType.Active
						? 99
						: item.status === ParticipantStatusType.Scheduled
						? 101
						: item.day,
			})),
			["courtOrderKey", "courtSequence"],
			["asc"]
		);
	}
);

export const getParticipantsByRoundAndEvents = createSelector(
	getActiveTournament,
	(activeTournament) => (roundName: string | null, event: string) => {
		if (!roundName) {
			return [];
		}

		const participants = get(activeTournament?.rounds, roundName, []).filter(
			(participant: IParticipant) => participant.event === event
		);

		return orderBy(participants, ["numberInDrawRoundColumn"], ["asc"]);
	}
);

export const getParticipantByPlayerId = createSelector(
	getActiveParticipants,
	(participants) => (playerId: number) => {
		return participants.find(
			(participant) =>
				participant.teams.teamA.find((item: TTeam) => item.playerId === playerId) ||
				participant.teams.teamB.find((item: TTeam) => item.playerId === playerId)
		);
	}
);

export const getParticipantById = createSelector(
	getAllActiveParticipants,
	(participants) => (participantId: number) =>
		participants.find((participant) => participant.id === participantId)
);

export const getCourtNamesByTournament = createSelector(
	geParticipantsForOrderOfPlay,
	(participants) => {
		const cortKeys = getSortKeys();

		const filteredParticipants = participants.map((participant) => ({
			...participant,
			courtOrderKey: cortKeys.find((item) => item.name === participant.courtName)?.value,
		}));

		const sorteredParticipants = orderBy(filteredParticipants, ["courtOrderKey"], ["asc"]);

		return reduce(
			sorteredParticipants,
			(courts: string[], participant) => {
				if (!courts.find((item) => item === participant.courtName)) {
					return concat(courts, [participant.courtName || ""]);
				}
				return courts;
			},
			[]
		);

		// return availableCourts.sort((item) => {
		// 	if (item.indexOf("Center") === 0) {
		// 		return -2;
		// 	}
		// 	if (item.indexOf("Court") === 0 || item.indexOf("COURT") === 0) {
		// 		return 1;
		// 	}
		// 	if (item === "Court 6") {
		// 		console.log(item);
		// 		return 9;
		// 	}
		// 	return -1;
		// });
	}
);

export const getCourtCompleteNamesByTournament = createSelector(
	getCompleteParticipants,
	(participants) => {
		const cortKeys = getSortKeys();

		const filteredParticipants = participants.map((participant) => ({
			...participant,
			courtOrderKey: cortKeys.find((item) => item.name === participant.courtName)?.value,
		}));

		const sorteredParticipants = orderBy(filteredParticipants, ["courtOrderKey"], ["asc"]);

		return reduce(
			sorteredParticipants,
			(courts: string[], participant) => {
				if (!courts.find((item) => item === participant.courtName)) {
					return concat(courts, [participant.courtName || ""]);
				}
				return courts;
			},
			[]
		);
	}
);

export const getCourtPlayingNamesByTournament = createSelector(
	getPlayingParticipants,
	(participants) => {
		const cortKeys = getSortKeys();

		const filteredParticipants = participants.map((participant) => ({
			...participant,
			courtOrderKey: cortKeys.find((item) => item.name === participant.courtName)?.value,
		}));

		const sorteredParticipants = orderBy(filteredParticipants, ["courtOrderKey"], ["asc"]);

		return reduce(
			sorteredParticipants,
			(courts: string[], participant) => {
				if (!courts.find((item) => item === participant.courtName)) {
					return concat(courts, [participant.courtName || ""]);
				}
				return courts;
			},
			[]
		);
	}
);

export const getPlayerSetsByIdAndEvent = createSelector(
	getPlayersSets,
	(playersSets) => (playerId: number, event: string) => {
		const playerSets = get(playersSets, playerId, []);
		return playerSets.find((sets: IPlayerSet) => sets.event === event) || {};
	}
);

export const checkIsCustomEvent = createSelector(getActiveTournament, (tournament) => {
	const customEventsIfs = ["Surbiton", "Ilkley"];
	return tournament ? customEventsIfs.includes(tournament.name) : false;
});

export const getDisplayDays = createSelector(
	getDays,
	getDaysForOrderOfPlay,
	getAllCompleteParticipantsByCourt,
	(dates, datesForCompletes, completeParticipants) =>
		(isLivePage: boolean, isComplete: boolean) => {
			const sortabledDates = orderBy(dates, ["date"], "asc");

			if (isLivePage) {
				return sortabledDates.filter(
					(item) => item.status === ParticipantStatusType.Active
				);
			}

			if (isComplete) {
				return orderBy(datesForCompletes, ["date"], "asc").filter((item) =>
					completeParticipants.find(
						(participant) => formatDate(participant.startDate) === item.formatDate
					)
				);
			}

			return sortabledDates.filter((item) => item.status !== ParticipantStatusType.Scheduled);
		}
);
