import styled from "styled-components";
import {InputLabel, Select as DefaultSelect, MenuItem as DefaultMenuItem} from "@mui/material";
import {ReactComponent as ArrowBackIosIcon} from "assets/images/icons/arrowDown.svg";
import {FormField} from "./FormField";

export const FormSelectIcon = styled(ArrowBackIosIcon)`
	&& {
		top: 8px;
	}
`;

export const SelectLabel = styled(InputLabel)`
	&&.MuiInputLabel-root {
		color: #a3a3a3;
	}
`;

export const Select = styled(DefaultSelect)`
	width: 100%;
	background-color: #ffffff;
	height: 46px;

	&& label {
		&.Mui-disabled {
			opacity: 0.3;
		}

		color: #333333;
	}

	& .MuiInput-underline:after {
		border-bottom-color: #d9d9d9;
	}

	&.MuiOutlinedInput-root {
		text-transform: none;
		border-radius: 6px;
		&.Mui-disabled {
			opacity: 0.3;
		}

		.MuiSelect-select {
			font-size: 14px;
			color: #333333;
			padding: 10px;
			line-height: 18px;
		}

		& fieldset {
			border-color: #d9d9d9;
		}

		&:hover fieldset {
			border-color: #d9d9d9;
		}

		&.Mui-focused fieldset {
			border-color: #d9d9d9;
		}
	}

	svg {
		right: 15px;
		width: 14px;
	}
`;

export const MenuItem = styled(DefaultMenuItem)`
	&.MuiMenuItem-root {
		min-height: auto;
		font-size: 14px;
	}
`;

export const SelectContainer = styled(FormField)`
	&.MuiFormControl-root {
		margin: 30px 0 0 0;

		@media (min-width: ${({theme}) => theme.media.desktop}) {
			margin-top: 0;
			max-width: 300px;
		}
	}
`;
