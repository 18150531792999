import React from "react";

interface IIconProps {
	width?: number;
	height?: number;
	color?: string;
	onClick?: () => void;
	title?: string;
	className?: string;
}

export const Spinner: React.FC<IIconProps> = ({
	title = "Spinner",
	width = 120,
	height = 120,
	color = "#fff",
}) => (
	<svg
		width={`${width}px`}
		height={`${height}px`}
		viewBox="0 0 25 24"
		fill="none"
		className="loader">
		<g className="circle">
			<path
				d="M9.63734 5.86414C8.46303 6.45839 7.45531 7.40427 6.79079 8.65274C6.12627 9.90121 5.90479 11.2647 6.06827 12.5695C7.72844 12.8724 9.47518 12.0935 10.3113 10.5227C11.1474 8.95182 10.817 7.06967 9.63734 5.86414Z"
				fill="#311B77"
			/>
			<path
				d="M19.2483 10.9692C17.5881 10.6663 15.8414 11.4452 15.0053 13.016C14.1692 14.5869 14.4995 16.469 15.6792 17.6745C16.8535 17.0803 17.8612 16.1344 18.5258 14.8859C19.1903 13.6375 19.4118 12.274 19.2483 10.9692Z"
				fill="#311B77"
			/>
			<path
				d="M13.8318 12.3928C14.8551 10.4702 16.8877 9.43717 18.9292 9.58922C18.3995 8.06736 17.3213 6.72777 15.7781 5.90812C14.235 5.08847 12.5203 4.94459 10.9611 5.35697C12.2289 6.96264 12.5081 9.22364 11.4848 11.1462C10.4615 13.0687 8.42893 14.1018 6.38744 13.9497C6.91709 15.4716 7.99533 16.8112 9.53849 17.6308C11.0816 18.4505 12.7963 18.5943 14.3555 18.182C13.0877 16.5763 12.8085 14.3153 13.8318 12.3928Z"
				fill="#311B77"
			/>
		</g>
		<defs>
			<clipPath id="clip0_519_1289">
				<rect
					width="15.9452"
					height="15.9358"
					fill="white"
					transform="matrix(0.883152 0.469087 -0.469856 0.882743 9.36102 0.99585)"
				/>
			</clipPath>
		</defs>
	</svg>
);

export default Spinner;
