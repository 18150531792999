import {IParticipant, IPlayer} from "modules/reducers";
import {IMAGES_URL} from "modules/constants";
import defaultAvatar from "assets/images/defaultAvatar.png";
import {ParticipantStatusType} from "modules/enums";
import {get} from "lodash";

export const getPlayerUrlAvatar = (player: IPlayer | undefined) => {
	return player && player.image ? IMAGES_URL + player.image : defaultAvatar;
};

export const checkIfPlayerFlagNotVisible = (player: IPlayer | undefined) =>
	player?.nationality === "RUS" || player?.nationality === "BLR" || player?.nationality === "JOR";

export const sliceParticipantsIntoChunks = (
	participant: IParticipant[] | undefined,
	chunkSize: number
) => {
	if (!participant) {
		return [];
	}
	const res = [];
	for (let i = 0; i < participant.length; i += chunkSize) {
		const chunk = participant.slice(i, i + chunkSize);
		res.push(chunk);
	}
	return res;
};

const getEmptyBlocksByIndex = (index: number, chunkSize: number) => {
	if (index === 1) {
		return 2;
	}
	if (index === 2) {
		return 1;
	}
	if (index > 3) {
		return chunkSize - index;
	}
	return 0;
};

export const sliceParticipantsForStatsCards = (
	participants: IParticipant[] | undefined,
	chunkSize: number
) => {
	if (!participants) {
		return [];
	}
	const res = [];
	for (let i = 0; i < participants.length; i += chunkSize) {
		const chunk = participants.slice(i, i + chunkSize);
		const countLiveGames = chunk.filter(
			(item) => item.status === ParticipantStatusType.Active
		).length;
		const countEmptyBlocks = getEmptyBlocksByIndex(countLiveGames, chunkSize);

		if (countEmptyBlocks && chunkSize === 6) {
			const updateChunk = participants.slice(i, i + (chunkSize - countEmptyBlocks));
			i = i - countEmptyBlocks;
			res.push(updateChunk);
		} else {
			res.push(chunk);
		}
	}
	return res;
};

const checkWhenNeedStopAddCourtsBlocks = (
	countCourtTypes: number,
	numberOfGameInBlock: number,
	numberCortByType: number[]
) =>
	// Stop add blocks if found 3 any types in court's items. By design we should display not more 2 court's type in one section
	countCourtTypes >= 3 ||
	// Stop add blocks if they 6. This is max for desktop
	numberOfGameInBlock === 6 ||
	// // Stop add blocks if in first court's block we have less 3 games and in last court's block we have 3 games
	(get(numberCortByType, "1", 0) < 3 && get(numberCortByType, "2", 0) === 3) ||
	// If first block with type >= 4. Need to stop adding blocks to save the image by design.
	(countCourtTypes === 2 && get(numberCortByType, "1", 0) >= 4);

const numberOfCourtsAllowedAddItems = (countCourtTypes: number) => countCourtTypes <= 3;
const compareNames = (currentCourtName: string, itemCourtName: string) =>
	currentCourtName !== itemCourtName;

const getNumberCortByType = (
	numberCortByType: number[],
	quantityOfGameInBlock: number,
	countCourtTypes: number
) =>
	countCourtTypes === 1
		? quantityOfGameInBlock
		: quantityOfGameInBlock - get(numberCortByType, `[1]`, 0);

export const sliceParticipantsForOrderOfPlay = (
	participants: IParticipant[] | undefined,
	chunkSize: number,
	isMobile: boolean
) => {
	if (!participants) {
		return [];
	}

	const res = [];

	for (let i = 0; i < participants.length; i += chunkSize) {
		const chunk = participants.slice(i, i + chunkSize);
		let currentCourtName = "";

		// Quantity court's types in this iteration
		let countCourtTypes = 0;

		// Quantity blocks in this iteration
		let quantityOfGameInBlock = 0;

		// Array with quantity game blocks in court's blocks
		const numberCortByType: number[] = [];

		chunk.forEach((item) => {
			const itemCourtName = item.courtName || "";
			if (compareNames(currentCourtName, itemCourtName)) {
				currentCourtName = itemCourtName;
				countCourtTypes++;
			}

			if (
				checkWhenNeedStopAddCourtsBlocks(
					countCourtTypes,
					quantityOfGameInBlock,
					numberCortByType
				)
			) {
				return;
			}

			if (numberOfCourtsAllowedAddItems(countCourtTypes)) {
				quantityOfGameInBlock++;
				numberCortByType[countCourtTypes] = getNumberCortByType(
					numberCortByType,
					quantityOfGameInBlock,
					countCourtTypes
				);
			}
		});

		if (!isMobile) {
			const updateChunk = participants.slice(i, i + quantityOfGameInBlock);
			i = i - (chunkSize - quantityOfGameInBlock);
			res.push(updateChunk);
		} else {
			res.push(chunk);
		}
	}

	return res;
};

export const getCountryFlag = (player?: IPlayer) => {
	return `${process.env.REACT_APP_IMAGES_URL}media/flags/${player?.nationality}.svg`;
	// return `${process.env.REACT_APP_IMAGES_URL}media/flags/${player?.nationality}_2x.png`;
};
