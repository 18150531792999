import React, {PropsWithChildren, useEffect} from "react";
import {PagePreloader} from "components";
import {useDispatch, useSelector} from "react-redux";
import {getFilterTournamentBy, getTournamentRequestState} from "modules/selectors";
import {RequestState} from "modules/enums";
import {
	fetchPlayers,
	fetchTournamentsSettings,
	requestTournaments,
	subscribeToLiveScores,
} from "modules/actions";
import {sendDataLayer} from "modules/utils";

export const HOCGetData: React.FC<PropsWithChildren<unknown>> = ({children}) => {
	const dispatch = useDispatch();
	const requestState = useSelector(getTournamentRequestState);
	const filterTournamentBy = useSelector(getFilterTournamentBy);
	const isLoading = requestState !== RequestState.Received;

	useEffect(() => {
		dispatch(requestTournaments());
		dispatch(subscribeToLiveScores());
		dispatch(fetchPlayers());
		dispatch(fetchTournamentsSettings());
		sendDataLayer("virtual_page_view");
	}, [dispatch]);

	if (isLoading || filterTournamentBy === undefined) return <PagePreloader />;

	return <div id={"mainWrapper"}>{children}</div>;
};
