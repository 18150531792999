import {IParticipant, ITournamentsSetting} from "modules/reducers";
import React, {useCallback} from "react";
import {Exist} from "components";
import {get, partial} from "lodash";
import styled from "styled-components";

interface INumberItem {
	color: string;
	activeColor?: string;
	isActive?: boolean;
}

const NumberItem = styled.button<INumberItem>`
	color: ${({isActive, activeColor, color}) => (isActive ? activeColor : color)};
	background: transparent;
	border: none;
	font-weight: 700;
	font-size: 16px;
	line-height: 18px;
	margin: 0 5px;

	&:disabled {
		opacity: 0.6;
	}
`;

const NumbersWrapper = styled.div`
	display: flex;
	width: 100%;
	text-align: center;
	margin: 15px auto 15px;
	justify-content: center;

	.slick-list {
		width: 200px;
	}

	.slick-track {
		margin: 0 auto;
	}
`;

const ItemsWrapper = styled.ul`
	display: flex;
	justify-content: start;
	align-items: center;
	max-width: 300px;
	overflow: scroll;
	scrollbar-width: none;
	-ms-overflow-style: none;

	&::-webkit-scrollbar {
		display: none; /* for Chrome, Safari, and Opera */
	}

	@media (min-width: ${({theme}) => theme.media.desktop}) {
		justify-content: center;
		max-width: 100%;
	}
`;

interface IProps {
	chunkedParticipants: IParticipant[][];
	maxNumberItem: number;
	countCourts: number;
	goToSlide: (item: number) => void;
	activeTab: number;
	pageScrollerArrowsColour: string;
	settings: ITournamentsSetting | undefined;
	prevValue: number;
	nextValue: number;
}

export const Pagination: React.FC<IProps> = ({
	chunkedParticipants,
	// maxNumberItem,
	countCourts,
	goToSlide,
	activeTab,
	pageScrollerArrowsColour,
	settings,
	prevValue,
	nextValue,
}) => {
	const handleClickOnNaw = useCallback(
		(value: number) => {
			goToSlide(value);
			const element = document.getElementById(`subMenu${value}`);
			element?.scrollIntoView({block: "nearest", inline: "start"});
		},
		[goToSlide]
	);

	return (
		<Exist when={countCourts > 1}>
			<NumbersWrapper>
				<NumberItem
					onClick={partial(handleClickOnNaw, prevValue)}
					disabled={activeTab === 0}
					color={pageScrollerArrowsColour}>
					{"<"}
				</NumberItem>
				<ItemsWrapper>
					{chunkedParticipants.map((participants, key) => (
						<li key={key} id={`subMenu${key}`}>
							<NumberItem
								onClick={partial(handleClickOnNaw, key)}
								activeColor={get(
									settings,
									"scoreCentre.pageScrollerCurrentPageColour"
								)}
								color={get(
									settings,
									"scoreCentre.pageScrollerOtherPagesColour",
									""
								)}
								isActive={activeTab === key}>
								{++key}
							</NumberItem>
						</li>
					))}
				</ItemsWrapper>
				<NumberItem
					onClick={partial(handleClickOnNaw, nextValue)}
					disabled={activeTab === countCourts - 1}
					color={pageScrollerArrowsColour}>
					{">"}
				</NumberItem>
			</NumbersWrapper>
		</Exist>
	);
};
