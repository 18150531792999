import React, {useCallback} from "react";
import styled from "styled-components";
import openInNewSharp from "assets/images/icons/openInNewSharp.svg";
import {useDispatch, useSelector} from "react-redux";
import {getActiveTournamentSettings} from "modules/selectors";
// import {IMAGES_URL} from "modules/constants";
import {hidePlayerProfile, showMatchStats} from "modules/actions";
import {IParticipant} from "modules/reducers";
import {Exist} from "components";
import {ParticipantStatusType} from "modules/enums";
import {sendDataLayer, twentyFourHourTime} from "modules/utils";
import {DateTime} from "luxon";
import {ReactComponent as ClockIcon} from "assets/images/icons/clockIcon.svg";

const Wrapper = styled.div<{background: string}>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 32px;
	background: ${({background}) =>
		background || "linear-gradient(90deg, #5F249F 0%, #B426C8 100%)"};
	border-radius: 0 0 6px 6px;
	padding-left: 10px;
`;

const MatchStatsWrapper = styled.div`
	display: flex;
	font-weight: 500;
	font-size: 11px;
	line-height: 15px;
	color: #ffffff;
	cursor: pointer;
	p {
		display: flex;
		align-items: center;
		margin-right: 5px;
	}
`;

const OpenStatsButton = styled.button`
	width: 16px;
	height: 20px;
	background: url(${openInNewSharp}) no-repeat;
	background-size: cover;
	border: none;
	margin-right: 10px;
`;

const Flex = styled.div`
	display: flex;
	align-items: center;
	color: #ffffff;
`;

export const MatchDurationText = styled.div`
	font-weight: 400;
	font-size: 12px;
	line-height: 15px;
	font-family: ${({theme}) => theme.fonts.linkFontFamily};
	margin-left: 5px;

	strong {
		font-weight: 900;
	}
`;

export const MatchDurationWrapper = styled.div`
	display: flex;
	align-items: center;
	p {
		width: 20px;
		height: 20px;
		margin-left: 5px;
	}
	img {
		width: 20px;
		height: 20px;
	}
`;

const GameDetail = styled.div`
	display: flex;
	flex-direction: column;
	gap: 3px;
	p {
		margin: 0;
		padding: 0;
		font-size: 10.7px;
		color: #ffffff;
	}
`;

// const CourtName = styled.p``;
const GameTimeWrapper = styled.p`
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;

	svg {
		margin-left: 5px;
	}
`;
const GameTime = styled.span`
	font-weight: bold;
`;

const checkNotBefore = (participant: IParticipant, showNotBefore: boolean) =>
	showNotBefore &&
	Boolean(participant.notBeforeISOTime) &&
	participant.status === ParticipantStatusType.Scheduled;

const checkNotBeforeText = (participant: IParticipant, showNotBefore: boolean) =>
	showNotBefore &&
	!participant.notBeforeISOTime &&
	participant.status === ParticipantStatusType.Scheduled;

const checkIsShowGameScore = (
	participant: IParticipant,
	isShowNotBeforeBlock: boolean,
	dontShowMatchStats: boolean
) =>
	!dontShowMatchStats &&
	!participant.isBye &&
	!participant.isWalkOver &&
	!isShowNotBeforeBlock &&
	participant.setsAvailable &&
	participant.status !== ParticipantStatusType.Scheduled;

interface ICardFooter {
	participant: IParticipant;
	showNotBefore?: boolean;
	dontShowMatchStats?: boolean;
}

const updateDisplayTime = (time: string | null) => {
	if (time) {
		const timeObj = DateTime.fromISO(time);
		return `${timeObj.get("hour")}h:${timeObj.get("minute")}m`;
	}

	return "";
};

const capitalizeFirstLetter = (string: string | null) => {
	return string ? string.charAt(0).toUpperCase() + string.toLowerCase().slice(1) : "";
};

export const CardFooter: React.FC<ICardFooter> = ({
	participant,
	showNotBefore = false,
	dontShowMatchStats = false,
}) => {
	const settings = useSelector(getActiveTournamentSettings);
	const dispatch = useDispatch();
	const isComplete = participant.status === ParticipantStatusType.Complete;
	const isActive = participant.status === ParticipantStatusType.Active;
	const isSuspended = participant.suspended;
	const isShowNotBeforeBlock = checkNotBefore(participant, showNotBefore);
	const isShowNotBeforeText = checkNotBeforeText(participant, showNotBefore);

	const clickHandler = useCallback(() => {
		sendDataLayer("match_stats_click");
		dispatch(hidePlayerProfile());
		dispatch(showMatchStats(participant.id));
		window.parent.postMessage(
			JSON.stringify({
				type: "voting_height",
				payload: 950,
			}),
			"*"
		);
		window.scrollTo(0, 0);
	}, [dispatch, participant]);

	return (
		<Wrapper background={settings?.scoreCentre.cardFooterColour || ""}>
			<Flex>
				{/*<Exist when={Boolean(get(settings, "scoreCentre.cardFooterLogo"))}>*/}
				{/*	<img*/}
				{/*		src={`${IMAGES_URL}${settings?.scoreCentre.cardFooterLogo || ""}`}*/}
				{/*		alt={"logo"}*/}
				{/*		width={46}*/}
				{/*	/>*/}
				{/*</Exist>*/}
				<Exist when={isComplete && Boolean(participant.timeTotal)}>
					<MatchDurationWrapper>
						<MatchDurationText>
							Match duration:{"\xa0"}
							<strong>{updateDisplayTime(participant.timeTotal)}</strong>
						</MatchDurationText>
					</MatchDurationWrapper>
				</Exist>
				<Exist when={isActive && !isSuspended}>
					<GameDetail>
						<GameTimeWrapper>
							<GameTime>{updateDisplayTime(participant.timeTotal)}</GameTime>
							{"\xa0"}
							<ClockIcon />
						</GameTimeWrapper>
						{/*<CourtName>{participant.courtName}</CourtName>*/}
					</GameDetail>
				</Exist>
				<Exist when={isShowNotBeforeBlock}>
					<GameTimeWrapper>
						{capitalizeFirstLetter(participant.notBeforeText)}
						{"\xa0"}
						<GameTime>{twentyFourHourTime(participant.notBeforeISOTime)}</GameTime>
					</GameTimeWrapper>
				</Exist>
				<Exist when={isShowNotBeforeText}>
					<GameTimeWrapper>
						{capitalizeFirstLetter(participant.notBeforeText)}
						{"\xa0"}
					</GameTimeWrapper>
				</Exist>
				<Exist when={isSuspended}>
					<GameTimeWrapper>Play suspended</GameTimeWrapper>
				</Exist>
			</Flex>

			<Exist
				when={checkIsShowGameScore(participant, isShowNotBeforeBlock, dontShowMatchStats)}>
				<MatchStatsWrapper onClick={clickHandler}>
					<p>Match stats{"\xa0"}</p>
					<OpenStatsButton />
				</MatchStatsWrapper>
			</Exist>
		</Wrapper>
	);
};
