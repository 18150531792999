import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {getMatchById, getMatchStatsState} from "modules/selectors";
import styled from "styled-components";
import {CardHead} from "./CardHead";
import {StatsPlayers} from "./StatsPlayers";
import {changeIframeHeight, useIsMobile} from "modules/utils";

const Wrapper = styled.div`
	position: absolute;
	font-family: "Red Hat Display", sans-serif;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
	background-color: #ffffff;
	backface-visibility: hidden;
	-webkit-perspective: 0;
	-webkit-backface-visibility: hidden;
	-webkit-transform: translate3d(0, 0, 0);
	transform: rotateY(180deg);
	min-height: 100vh;
	overflow: auto;

	@media screen and (max-width: 950px) {
		min-height: auto;
		height: auto;
	}
`;

export const MatchStatsCard: React.FC = () => {
	const {id, isShow} = useSelector(getMatchStatsState);
	const {isMobile} = useIsMobile();
	const participant = useSelector(getMatchById)(id);

	useEffect(() => {
		changeIframeHeight();
	}, [id]);

	useEffect(() => {
		const rootElement = document.getElementById("app");

		if (rootElement) {
			rootElement.style.overflow = isShow ? "hidden" : "visible";
			rootElement.style.height = isShow ? "100vh" : "inherit";
		}
	}, [isShow]);

	useEffect(() => {
		if (isShow && isMobile) {
			changeIframeHeight(document.querySelector<HTMLElement>("#playerStats")?.offsetHeight);
		}
	}, [isShow, isMobile]);

	if (!id) return null;

	return (
		<Wrapper id={"playerStats"}>
			<CardHead />
			{/*<TournamentName participant={participant} />*/}
			<StatsPlayers participant={participant} />
		</Wrapper>
	);
};
