import {FormControl} from "@mui/material";
import styled from "styled-components";

export const FormField = styled(FormControl)`
	&.MuiFormControl-root {
		width: 100%;
		margin-bottom: 26px;

		input {
			padding: 13px;
		}

		label {
			&[data-shrink="false"] {
				top: -2px;
			}
		}
	}
`;