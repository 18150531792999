export const applyBodyOverflowStyleByPage = (page: string, visibility: "visible" | "hidden") => {
	const {location} = window;
	if (location.href.split("/")[location.href.split("/").length - 1] === page) {
		document.getElementsByTagName("body")[0].style.overflowY = visibility;
	}
};

export const capitalizeFirstLetter = (string: string) => {
	const lowerCaseString = string.toLowerCase();
	return lowerCaseString.charAt(0).toUpperCase() + lowerCaseString.slice(1);
};
