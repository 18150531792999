import React, {useEffect} from "react";
import styled from "styled-components";
import {
	Exist,
	Header,
	InnerWrapper,
	MatchStatsCard,
	PlayerProfileCard,
	PageWrapper,
	Container,
	SideParticipants,
} from "components";
import {useSelector} from "react-redux";
import {
	getMatchStatsState,
	getPlayerProfileState,
	checkIsCustomEvent,
	getActualEvent,
	getActiveTournament,
	getPlayingParticipantsByCourt,
} from "modules/selectors";

import {changeIframeHeight, getTextForLive, sendDataLayer} from "modules/utils";
import {get, partial} from "lodash";
import {NavLink} from "react-router-dom";

export const WarningText = styled.div`
	font-size: 16px;
	line-height: 35px;
	font-family: ${({theme}) => theme.fonts.primaryFontFamily};
	text-align: center;
	padding: 20px 10px;
	max-width: 800px;
	margin: 0 auto;

	a {
		color: #0052cc;
		text-decoration: underline;
	}
`;

export const ScoreCentreLive: React.FC = () => {
	const {isShow} = useSelector(getMatchStatsState);
	const {isShow: isShowProfile} = useSelector(getPlayerProfileState);
	const playingParticipants = useSelector(getPlayingParticipantsByCourt);
	const tournament = useSelector(getActiveTournament);
	const isCustomTournament = useSelector(checkIsCustomEvent);
	const activeEvent = useSelector(getActualEvent);

	useEffect(() => {
		changeIframeHeight();
	}, [playingParticipants.length]);

	return (
		<PageWrapper $isShow={isShow} $isShowProfile={isShowProfile}>
			<InnerWrapper>
				<Container>
					<Header />

					<Exist when={isCustomTournament}>
						<WarningText>
							<p
								// eslint-disable-next-line react/no-danger
								dangerouslySetInnerHTML={{
									__html: getTextForLive(
										get(tournament, "name", ""),
										activeEvent
									),
								}}
							/>
						</WarningText>
					</Exist>

					<Exist when={!isCustomTournament}>
						<Exist when={playingParticipants.length !== 0}>
							<SideParticipants participants={playingParticipants} />
						</Exist>

						<Exist when={!playingParticipants.length}>
							<WarningText>
								<p>
									There are currently no live matches, check the{" "}
									<NavLink
										to={"/play"}
										onClick={partial(sendDataLayer, "order_of_play_tab_view")}>
										order of play
									</NavLink>{" "}
									to see when the next match will take place.
								</p>
							</WarningText>
						</Exist>
					</Exist>
				</Container>
				<MatchStatsCard />
				<PlayerProfileCard />
			</InnerWrapper>
		</PageWrapper>
	);
};

export default ScoreCentreLive;
