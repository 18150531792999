import React from "react";
import styled from "styled-components";
import {IParticipant} from "modules/reducers";
import {useSelector} from "react-redux";
import {getActiveTournamentSettings} from "modules/selectors";
import {get} from "lodash";
import {ROUNDS_NAMES} from "modules/constants";
import {capitalizeFirstLetter} from "modules/utils";

export const CardHeaderWrapper = styled.div<{background?: string}>`
	display: flex;
	align-items: center;
	height: 32px;
	background: ${({background}) =>
		background || "linear-gradient(90deg, #5F249F 0%, #B426C8 100%)"};
	border-radius: 6px 6px 0 0;
	color: #ffffff;
	justify-content: space-between;
`;

const ParticipantTypeStyled = styled.h5`
	font-size: 13px;
	font-style: normal;
	font-weight: 700;
	line-height: 18px;
	color: #ffffff;
	margin-left: 10px;
`;

const InfoBlock = styled.div`
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	margin-right: 10px;
`;

interface IProps {
	participant: IParticipant;
}

export const CardHeader: React.FC<IProps> = ({participant}) => {
	const roundName = get(participant, "roundName", "");
	const settings = useSelector(getActiveTournamentSettings);

	return (
		<CardHeaderWrapper background={get(settings, "scoreCentre.cardHeaderColour", "")}>
			<ParticipantTypeStyled>
				{capitalizeFirstLetter(participant.event)}
			</ParticipantTypeStyled>
			<InfoBlock>
				{/*{formatDate(participant.startDate)}*/} {get(ROUNDS_NAMES, roundName, "")}
			</InfoBlock>
		</CardHeaderWrapper>
	);
};
