import React from "react";
import {useSelector} from "react-redux";
import {
	getActivePlayer,
	getActualEvent,
	getParticipantById,
	getPlayerSetsByIdAndEvent,
} from "modules/selectors";
import {StatsList} from "./StatsList";
import {ITournament} from "modules/reducers";
import {get} from "lodash";

interface ITennisStats {
	tournament: ITournament;
	tournaments: ITournament[];
	participantId: number | null;
}

export const TennisStats: React.FC<ITennisStats> = ({participantId}) => {
	const player = useSelector(getActivePlayer)!;
	const participantById = useSelector(getParticipantById)(participantId || 0);
	const actualEvent = useSelector(getActualEvent);
	const event = get(participantById, "event", actualEvent);

	const playerSets = useSelector(getPlayerSetsByIdAndEvent)(player.id, event);

	const breakPointsConverted = get(playerSets, "breakPointsConverted", 0);
	const breakPointsPlayed = get(playerSets, "breakPointsPlayed", 0);
	const breakPointsSaved = get(playerSets, "breakPointsSaved", "N/A");

	const breakPointsConvertedDisplay =
		breakPointsConverted > breakPointsPlayed
			? `${breakPointsPlayed}/${breakPointsConverted}`
			: `${breakPointsConverted}/${breakPointsPlayed}`;

	const data = [
		{
			title: get(playerSets, "aces", "N/A"),
			value: "Aces",
		},
		{
			title: get(playerSets, "doubleFaults", "N/A"),
			value: "Double faults",
		},
		{
			title: `${get(playerSets, "percentageFirstServe", 0)}%`,
			value: "First serve %",
		},
		{
			title: `${get(playerSets, "percentageSecondServe", 0)}%`,
			value: "Second serve %",
		},
		{
			title: `${get(playerSets, "percentageWinOnFirstServe", 0)}%`,
			value: "Win % on first serve",
		},
		{
			title: `${get(playerSets, "percentageWinOnSecondServe", 0)}%`,
			value: "Win % on second serve",
		},
		{
			title: breakPointsConvertedDisplay,
			value: "Break points converted",
		},
		{
			title: breakPointsSaved,
			value: "Break points saved",
		},
		{
			title: get(playerSets, "totalPointsWon", 0),
			value: "Total points won",
		},
		{
			title: "N/A", // todo
			value: "Best achievement",
		},
	];

	return <StatsList data={data} />;
};
