import {createTheme} from "@mui/material";
import {DefaultTheme} from "styled-components";

declare module "@mui/material/styles" {
	// allow configuration using `createTheme`
	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface ThemeOptions {
		[key: string]: unknown;
	}
}

const themeObject = {
	background: {
		careerStats: {
			tab: "#ECF5F9",
			activeTab: "#8F1DBF",
			card: "#ECF5F9",
		},
	},
	text: {
		careerStats: {
			tab: "#333333",
			activeTab: "#ffffff",
			card: "#311B77",
		},
	},
	fonts: {
		primaryFontFamily: "Roboto, sans-serif",
		secondaryFontFamily: "Red Hat Display, sans-serif",
		linkFontFamily: "Inter, sans-serif",
	},
	media: {
		desktop: "956px",
		mobile: "480px",
	},
};

export const theme = createTheme(themeObject) as unknown as DefaultTheme;
type ThemeInterface = typeof themeObject;

declare module "styled-components" {
	// Allows change type of  theme to avoid any
	/* eslint-disable @typescript-eslint/no-empty-interface */

	// eslint-disable-next-line @typescript-eslint/naming-convention
	export interface DefaultTheme extends ThemeInterface {}
}
