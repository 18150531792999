import React from "react";
import styled from "styled-components";
import {get} from "lodash";
import {useSelector} from "react-redux";
import {getActiveTournamentSettings} from "modules/selectors";

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	padding: 20px;
	column-gap: 20px;
	row-gap: 20px;

	@media (min-width: 769px) {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}

	@media (min-width: ${({theme}) => theme.media.desktop}) {
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	}
`;

const Item = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 13px;
	background: ${({theme}) => theme.background.careerStats.card};
	font-size: 14px;
	border-radius: 4px;
	font-weight: 500;
	line-height: 17px;
	text-align: center;
	height: 100px;

	@media (min-width: ${({theme}) => theme.media.desktop}) {
		padding: 20px;
		font-size: 15px;
	}
`;

const Title = styled.div<{color: string}>`
	font-weight: 900;
	line-height: 22px;
	color: ${({color}) => color};
	margin-bottom: 4px;

	@media (min-width: ${({theme}) => theme.media.desktop}) {
		font-size: 20px;
		line-height: 24px;
		margin-bottom: 6px;
	}
`;

interface IStatsList {
	data: {title: string | number; value: string}[];
}

export const StatsList: React.FC<IStatsList> = ({data}) => {
	const settings = useSelector(getActiveTournamentSettings);
	return (
		<Wrapper>
			{data.map((el) => (
				<Item key={el.value}>
					<Title color={get(settings, "playerProfiles.statsTextColour", "")}>
						{el.title}
					</Title>
					<div>{el.value}</div>
				</Item>
			))}
		</Wrapper>
	);
};
