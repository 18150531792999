import React, {Dispatch, SetStateAction} from "react";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {getActivePlayer, getActiveTournamentSettings} from "modules/selectors";
import {Exist} from "components";
import {PlayerProfileCardTab} from "./index";
import {get} from "lodash";
import {checkIfPlayerFlagNotVisible, getPlayerUrlAvatar, getCountryFlag} from "modules/utils/hooks";
import {sendDataLayer} from "modules/utils";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin: 20px 20px 0;
	padding-bottom: 17px;
	font-family: ${({theme}) => theme.fonts.secondaryFontFamily};
	border-bottom: 1px solid #e3e3e3;

	@media (min-width: ${({theme}) => theme.media.desktop}) {
		padding-bottom: 20px;
		flex-direction: row;
		justify-content: space-between;
	}
`;

const Player = styled.div`
	display: flex;
`;

const Avatar = styled.img`
	width: auto;
	height: 84px;
	margin-right: 16px;
	border-radius: 6px;

	@media (min-width: ${({theme}) => theme.media.desktop}) {
		width: auto;
		height: 146px;
		margin-right: 20px;
	}
`;

const PlayerInfo = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	font-size: 15px;
	line-height: 17px;
`;

const Name = styled.div`
	font-size: 18px;
	font-weight: 700;
	line-height: 19px;
	margin-bottom: 11px;

	@media (min-width: ${({theme}) => theme.media.desktop}) {
		font-size: 20px;
		font-weight: 700;
		line-height: 17px;
		margin-bottom: 13px;
	}
`;

const Link = styled.a`
	color: #1565c0;
	font-weight: 500;
	border-bottom: 1px solid #1565c0;
`;

const DisciplineContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 30px;
	justify-content: end;

	@media (min-width: ${({theme}) => theme.media.desktop}) {
		min-width: 500px;
		margin-top: 0;
	}
`;

const Tabs = styled.div`
	width: 100%;
	display: flex;
	border-radius: 4px;
	overflow: hidden;
	font-size: 14px;
	font-weight: 700;
	line-height: 17px;
	justify-content: space-between;

	@media (min-width: ${({theme}) => theme.media.desktop}) {
		font-size: 16px;
		line-height: 19px;
		justify-content: end;
	}
`;

const Tab = styled.div<{isActive: boolean; statsButtonColour: string}>`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	cursor: pointer;
	color: ${({isActive, statsButtonColour}) => (isActive ? statsButtonColour : "#707070")};
	padding: 5px 0;
	margin: 0 10px;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	border-bottom: ${({isActive, statsButtonColour}) =>
		isActive ? `1px solid ${statsButtonColour}` : "none"};

	@media (min-width: ${({theme}) => theme.media.desktop}) {
		margin: 0 15px;
	}
`;

const CountryInfo = styled.div`
	display: flex;
	align-items: center;
`;

const PlayerFlagImage = styled.img`
	width: 24px;
`;

const Country = styled.div`
	margin-left: 10px;
`;

interface IPlayerProfile {
	activeTab: PlayerProfileCardTab;
	setActiveTab: Dispatch<SetStateAction<PlayerProfileCardTab>>;
}

export const PlayerProfile: React.FC<IPlayerProfile> = ({setActiveTab, activeTab}) => {
	const player = useSelector(getActivePlayer)!;
	const name = `${player.firstName} ${player.lastName}`;
	const ifPlayerFlagNotVisible = checkIfPlayerFlagNotVisible(player);
	const settings = useSelector(getActiveTournamentSettings);

	return (
		<Wrapper>
			<Player>
				<Avatar src={getPlayerUrlAvatar(player)} width={110} alt="Player's avatar" />
				<PlayerInfo>
					<div>
						<Name>{name}</Name>
						<Exist when={!ifPlayerFlagNotVisible}>
							<CountryInfo>
								<PlayerFlagImage
									src={getCountryFlag(player)}
									alt="Country's flag"
								/>
								<Country>{player.country}</Country>
							</CountryInfo>
						</Exist>
					</div>
					<Exist when={Boolean(player.informationLink)}>
						<div>
							<Link target="_blank" href={player.informationLink || ""}>
								View Player Information
							</Link>
						</div>
					</Exist>
				</PlayerInfo>
			</Player>

			<DisciplineContainer>
				<Tabs>
					<Tab
						isActive={activeTab === PlayerProfileCardTab.Matches}
						onClick={() => {
							sendDataLayer("tournament_matches_click");
							setActiveTab(PlayerProfileCardTab.Matches);
						}}
						statsButtonColour={get(
							settings,
							"playerProfiles.careerStatsButtonColour",
							"#000"
						)}>
						Matches
					</Tab>
					<Tab
						isActive={activeTab === PlayerProfileCardTab.Tennis}
						onClick={() => {
							sendDataLayer("tournament_stats_click");
							setActiveTab(PlayerProfileCardTab.Tennis);
						}}
						statsButtonColour={get(
							settings,
							"playerProfiles.careerStatsButtonColour",
							"#000"
						)}>
						Tournament stats
					</Tab>
					<Tab
						isActive={activeTab === PlayerProfileCardTab.Career}
						onClick={() => {
							sendDataLayer("career_stats_click");
							setActiveTab(PlayerProfileCardTab.Career);
						}}
						statsButtonColour={get(
							settings,
							"playerProfiles.tennisStatsButtonColour",
							"#000"
						)}>
						Career stats
					</Tab>
				</Tabs>
			</DisciplineContainer>
		</Wrapper>
	);
};
