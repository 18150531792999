import React, {useCallback} from "react";
import styled from "styled-components";
import {IParticipant, IPlayer} from "modules/reducers";
import {getPlayerUrlAvatar, sendDataLayer, useIsMobile} from "modules/utils";
import {ScoreCard} from "./ScoreCard";
import {showPlayerProfile} from "modules/actions";
import {useDispatch, useSelector} from "react-redux";
import {get, partial} from "lodash";
import {getActiveTournamentSettings} from "modules/selectors";
// import {Exist} from "../Exist";

const Wrapper = styled.div`
	display: flex;
	align-items: flex-start;
	width: 100%;

	img {
		border-radius: 2px;
	}

	@media (min-width: ${({theme}) => theme.media.desktop}) {
		align-items: flex-start;
	}
`;

const ImgContainer = styled.div`
	width: 100%;

	img {
		width: 141px;
		height: 187px;
	}
`;

export const Versus = styled.div<{color?: string}>`
	font-size: 20px;
	font-weight: 900;
	line-height: 17px;
	letter-spacing: 0;
	text-align: center;
	color: ${({color}) => color || "#311b77"};
	padding: 0 5px;
	margin-bottom: 10px;
	margin-top: 50px;

	@media (min-width: ${({theme}) => theme.media.desktop}) {
		font-size: 26px;
		margin-top: 33px;
	}
`;

const Name = styled.div`
	margin-top: 12px;

	button {
		font-size: 14px;
		font-weight: 700;
		line-height: 19px;
		color: #333333;
		cursor: pointer;
		background: transparent;
		border: none;
		&:hover {
			text-decoration: underline;
		}
	}

	button + button {
		margin-left: 10px;
		display: inline-block;
	}

	span {
		font-size: 14px;
		font-weight: 700;
		line-height: 19px;
		color: #333333;
	}

	@media (min-width: ${({theme}) => theme.media.desktop}) {
		font-size: 20px;
		margin-top: 18px;

		button,
		span {
			font-size: 20px;
		}
	}
`;

const Info = styled.div`
	text-align: center;
	width: 100%;
`;

// const Nationality = styled.div`
// 	display: none;

// 	@media (min-width: 901px) {
// 		display: flex;
// 		justify-content: center;
// 		font-size: 15px;
// 		line-height: 17px;
// 		margin-left: 10px;
// 	}
// `;

const ScoreCardContainer = styled.div`
	padding: 0 20px;
	width: 100%;
	max-width: 360px;
`;

const PlayerImagesWrapper = styled.div<{isImageHalfWidth: boolean}>`
	//display: flex;
	//justify-content: center;
	text-align: center;
	background: #ffffff;
	min-height: 185px;
`;

const CountryInfo = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 10px;

	img {
		width: 25px;
	}
`;

const PlayerFlagImage = styled.img`
	width: 24px;
`;

const Country = styled.div`
	font-family: ${({theme}) => theme.fonts.secondaryFontFamily};
	margin-left: 10px;
	font-size: 15px;
`;

// const CountryInfo = styled.div`
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	margin-top: 13px;
// `;

// const PlayerFlagImage = styled.img`
// 	width: 24px !important;
// `;

interface IPlayerFlag {
	player?: IPlayer;
}

export const PlayerFlag: React.FC<IPlayerFlag> = ({player}) => {
	return (
		<CountryInfo>
			<PlayerFlagImage
				src={`${(
					process.env.REACT_APP_IMAGES_URL || ""
				).toString()}media/flags/${player?.nationality.toString()}.svg`}
				alt="Country flag"
			/>
			<Country>{player?.country}</Country>
		</CountryInfo>
	);
};

interface IPlayersCard {
	opponentsA: IPlayer[];
	opponentsB: IPlayer[];
	participant: IParticipant;
}

export const PlayersCard: React.FC<IPlayersCard> = ({opponentsA, opponentsB, participant}) => {
	const {isMobile} = useIsMobile("(max-width: 900px)");
	const dispatch = useDispatch();
	const settings = useSelector(getActiveTournamentSettings);
	const vsColor = get(settings, "liveStats.vsTextColour");
	// const ifOpponentAFlagNotVisible = checkIfPlayerFlagNotVisible(opponentsA[0]);
	// const ifOpponentBFlagNotVisible = checkIfPlayerFlagNotVisible(opponentsB[0]);
	const playerAvatarAlt = "Player's avatar";

	const isDoubles = (players: IPlayer[]) => players?.length > 1;

	const clickHandler = useCallback(
		(opponent: IPlayer, participantId: number) => {
			if (!opponent || opponent.isWheelchair) {
				return;
			}
			sendDataLayer("player_profile_click");

			dispatch(showPlayerProfile({playerId: opponent.id, participantId}));
		},
		[dispatch]
	);

	return (
		<Wrapper>
			<ImgContainer>
				<PlayerImagesWrapper isImageHalfWidth={isDoubles(opponentsA)}>
					<img src={getPlayerUrlAvatar(opponentsA[0])} alt={playerAvatarAlt} />

					{isDoubles(opponentsA) && (
						<img src={getPlayerUrlAvatar(opponentsA[1])} alt={playerAvatarAlt} />
					)}
				</PlayerImagesWrapper>
				<Info>
					<Name>
						<button onClick={partial(clickHandler, opponentsA[0], participant.id)}>
							{opponentsA[0]?.firstName} {opponentsA[0]?.lastName}
						</button>
						{isDoubles(opponentsA) && (
							<span>
								&
								<button
									onClick={partial(clickHandler, opponentsA[1], participant.id)}>
									{opponentsA[1]?.firstName} {opponentsA[1]?.lastName}
								</button>
							</span>
						)}
					</Name>
					{/* <Exist when={!ifOpponentAFlagNotVisible}>
						<CountryInfo>
							<PlayerFlagImage
								src={`${process.env.REACT_APP_IMAGES_URL}media/flags/${opponentsA[0]?.nationality}.svg`}
								alt="Country flag"
							/>
							<Nationality>{opponentsA[0]?.nationality}</Nationality>
						</CountryInfo>
					</Exist> */}
				</Info>
			</ImgContainer>

			{isMobile ? (
				<Versus color={vsColor}>VS</Versus>
			) : (
				<ScoreCardContainer>
					<ScoreCard participant={participant} />
					<Versus color={vsColor}>VS</Versus>
				</ScoreCardContainer>
			)}
			<ImgContainer>
				<PlayerImagesWrapper isImageHalfWidth={isDoubles(opponentsB)}>
					<img src={getPlayerUrlAvatar(opponentsB[0])} alt={playerAvatarAlt} />
					{isDoubles(opponentsB) && (
						<img src={getPlayerUrlAvatar(opponentsB[1])} alt={playerAvatarAlt} />
					)}
				</PlayerImagesWrapper>
				<Info>
					<Name>
						<button onClick={partial(clickHandler, opponentsB[0], participant.id)}>
							{opponentsB[0]?.firstName} {opponentsB[0]?.lastName}
						</button>
						{isDoubles(opponentsA) && (
							<span>
								&
								<button
									onClick={partial(clickHandler, opponentsB[1], participant.id)}>
									{" "}
									{opponentsB[1]?.firstName} {opponentsB[1]?.lastName}
								</button>
							</span>
						)}
					</Name>
					{/* <Exist when={!ifOpponentBFlagNotVisible}>
						<CountryInfo>
							<PlayerFlagImage
								src={`${process.env.REACT_APP_IMAGES_URL}media/flags/${opponentsB[0]?.nationality}.svg`}
								alt="Country flag"
							/>
							<Nationality>{opponentsB[0]?.nationality}</Nationality>
						</CountryInfo>
					</Exist> */}
				</Info>
			</ImgContainer>
		</Wrapper>
	);
};
