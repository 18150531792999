import React from "react";
import styled from "styled-components";
import {IParticipant} from "modules/reducers";
import {get} from "lodash";
import {ROUNDS_NAMES} from "modules/constants";
import {DraftCard} from "./DraftCard";
import {Exist} from "components";
import {useIsMobile} from "modules/utils";
import {useSelector} from "react-redux";
import {getActiveTournamentSettings} from "modules/selectors";

export const List = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	background-size: cover;
	background-position: center;
	justify-content: space-around;
	position: relative;
	padding-top: 15px;
	width: 312px;
	scrollbar-width: none;
	-ms-overflow-style: none;

	&::-webkit-scrollbar {
		display: none; /* for Chrome, Safari, and Opera */
	}

	@media (min-width: ${({theme}) => theme.media.desktop}) {
		margin: 0 50px 0 7px;
		height: 100%;
		width: 290px;
		padding-top: 30px;
	}
`;

export const RoundTitle = styled.h4<{color?: string | null}>`
	font-family: ${({theme}) => theme.fonts.primaryFontFamily};
	position: relative;
	width: 100%;
	text-align: center;
	font-weight: 700;
	font-size: 18px;
	line-height: 18px;
	color: ${({color}) => (color ? color : "#311b77")};
	@media (min-width: ${({theme}) => theme.media.desktop}) {
		position: absolute;
		top: 0;
		width: 290px;
		margin-top: 20px;
	}
`;

const Separator = styled.div<{top: number; height: number}>`
	position: absolute;
	background-color: #ffffff;
	right: -30px;
	width: 50px;
	height: ${({height}) => height}px;
	top: ${({top}) => top}px;
	border-top: 1px solid #e3e3e3;
	border-bottom: 1px solid #e3e3e3;
	border-right: 1px solid #e3e3e3;

	@media (max-width: 950px) {
		right: -6%;
		&:after {
			content: "";
			position: absolute;
			display: block;
			height: 1px;
			width: 500px;
			background: #e3e3e3;
			margin-top: 109.5px;
			right: -500px;
		}
	}

	@media (max-width: 400px) {
		right: -3%;
	}
	@media (max-width: 340px) {
		right: -2%;
	}
`;

interface ISeparatorsProps {
	countParticipants: number;
	participantBlockNumber: number;
}

const isShowLastSeparator = (
	countParticipants: number,
	isMobile: boolean,
	participantBlockNumber: number
) => countParticipants === 2 && participantBlockNumber !== 3;

const Separators: React.FC<ISeparatorsProps> = ({countParticipants, participantBlockNumber}) => {
	const {isMobile} = useIsMobile();
	return (
		<React.Fragment>
			<Exist when={countParticipants === 8}>
				<Exist when={isMobile}>
					<Separator top={124} height={219} />
					<Separator top={604} height={248} />
					<Separator top={1094} height={248} />
					<Separator top={1573} height={248} />
				</Exist>
				<Exist when={!isMobile}>
					<Separator top={132} height={248} />
					<Separator top={604} height={248} />
					<Separator top={1094} height={248} />
					<Separator top={1573} height={248} />
				</Exist>
			</Exist>

			<Exist when={countParticipants === 4 && participantBlockNumber === 1}>
				<Exist when={isMobile}>
					<Separator top={124} height={219} />
					<Separator top={556} height={219} />
				</Exist>

				<Exist when={!isMobile}>
					<Separator top={132} height={238} />
					<Separator top={604} height={238} />
				</Exist>
			</Exist>

			<Exist when={countParticipants === 4 && participantBlockNumber === 2}>
				<Separator top={253} height={482} />
				<Separator top={1213} height={481} />
			</Exist>

			<Exist when={isShowLastSeparator(countParticipants, isMobile, participantBlockNumber)}>
				<Exist when={isMobile}>
					<Separator top={124} height={219} />
				</Exist>

				<Exist when={!isMobile}>
					<Separator top={251} height={473} />
				</Exist>
			</Exist>
		</React.Fragment>
	);
};

interface IProps {
	participants: IParticipant[][];
	activeKey: number;
	participantBlockNumber: number;
	roundName: string | undefined;
	isActive: boolean;
	flashRounds?: string[];
}

// const flashRounds = ["Quarter-Final", "Semi-Final", "Final"];

export const DisplayParticipantsList: React.FC<IProps> = ({
	participants,
	activeKey,
	roundName,
	participantBlockNumber,
	isActive,
	flashRounds = [],
}) => {
	const settings = useSelector(getActiveTournamentSettings);
	const actualParticipants = get(participants, activeKey, []);
	const countParticipants = actualParticipants.length;
	const roundNameTitle = get(ROUNDS_NAMES, roundName || "", "");

	const isActiveFlash = isActive && flashRounds.includes(roundName || "");

	return roundName ? (
		<List>
			<RoundTitle color={get(settings, "draw.roundTitlesColour")}>
				{roundNameTitle}
			</RoundTitle>
			<Separators
				countParticipants={countParticipants}
				participantBlockNumber={participantBlockNumber}
			/>

			{actualParticipants.map((participant: IParticipant, key) => {
				participant.isDrawFinalCard = roundNameTitle.toLowerCase() === "final";
				return (
					<DraftCard
						participant={participant}
						key={key}
						countParticipants={countParticipants}
						isActiveFlash={isActiveFlash}
						participantBlockNumber={participantBlockNumber}
					/>
				);
			})}
		</List>
	) : null;
};
