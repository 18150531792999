import {createSelector} from "reselect";
import {IStore} from "modules/types/store";

export const getPlayers = (state: IStore) => state.players;

export const getPlayerById = createSelector(
	getPlayers,
	(players) => (id: number) => players.find((player) => player.id === id)
);

export const getPlayersById = createSelector(
	getPlayers,
	(players) => (ids: number[]) => players.filter((player) => ids.some((id) => id === player.id))
);

export const getPlayersByTournamentId = createSelector(
	getPlayers,
	(players) => (tournamentId: number | undefined) =>
		tournamentId
			? players.filter((player) => player.tournamentStats[tournamentId] !== undefined)
			: []
);
