import {get} from "lodash";

export const twentyFourHourTime = (date: string | null) => {
	if (!date || date === "") {
		return "";
	}

	const splitDate = date.split("+");
	const updatedDate = get(splitDate, "0", null);

	if (updatedDate) {
		const time = updatedDate.split(":");
		const hour = get(time, "0", "0");
		const minute = get(time, "1", "0");

		return `${hour}:${minute}${Number(hour) >= 12 ? "PM" : "AM"}`;
	}

	return "";
};
