import {HTTPClient as HTTPClientCore, IRequestConfig} from "@fanhubmedia/fe-common-utils";
import axios, {AxiosError} from "axios";
import {isObject, set} from "lodash";
import {CANCEL} from "redux-saga";
import {API_URL, JSON_URL} from "modules/constants";
import {
	IApiResponse,
	IDictionary,
	ISet,
	ITournamentsSetting,
	IPlayer,
	IPlayerSet,
	IRounds,
} from "modules/types";
import {ITournament} from "modules/reducers";
import ApiError from "./ApiError";
import {IFetchPlayersSets} from "modules/actions";

class HTTPClient extends HTTPClientCore {
	/**
	 * Overridden method adds CancelToken symbol, that allow redux-saga'
	 * "takeLatest" function to cancel any requests automatically.
	 * http://fe-common-utils.s3-website-eu-west-1.amazonaws.com/classes/httpclient.html
	 */
	public makeRequest<T>(config: IRequestConfig): Promise<T> {
		const source = axios.CancelToken.source();

		const request = super.makeRequest<T>({
			...config,
			cancelToken: source.token,
		});

		return set<Promise<T>>(request, CANCEL, () => source.cancel());
	}
}

const onCatchNetworkError = ({response, message = "Network error"}: AxiosError<ApiError>) => {
	const data = response?.data;
	const error = isObject(data)
		? data
		: {
				errors: [new ApiError(message)],
		  };

	return Promise.reject(error).catch((err) => ApiError.CHECK(err as IApiResponse));
};

const APIClient = new HTTPClient({
	baseURL: API_URL,
	withCredentials: true,
	onCatchNetworkError,
});

const JSONClient = new HTTPClient({
	baseURL: JSON_URL,
});

export const Api = {
	JSON: {
		players: () => JSONClient.get<IPlayer[]>("players.json"),
		tournaments: () => JSONClient.get<ITournament[]>("tournaments.json"),
		checksums: () => JSONClient.get<IDictionary<string>>("checksums.json"),
		tournaments_settings: () =>
			JSONClient.get<ITournamentsSetting[]>("tournaments_settings.json"),
		participantSets: (id: number) => JSONClient.get<ISet[]>(`match_sets/${id}.json`),
		playersSets: ({tournamentId, playerId}: IFetchPlayersSets) =>
			JSONClient.get<IPlayerSet[]>(
				`tournament_stats/tournament_${tournamentId}_player_${playerId}.json`
			),
		playerMatches: ({tournamentId, playerId}: IFetchPlayersSets) =>
			JSONClient.get<IRounds>(
				`player_matches/tournament_${tournamentId}_player_${playerId}_matches.json`
			),
	},
	Player: {
		h2h: APIClient.get(""),
	},
	Mock: {
		// after first real endpoint delete mock
		get: () => APIClient.get(""),
	},
};

export * from "./ApiError";
