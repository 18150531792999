import {createAction} from "redux-act";
import {IPlayerSet, IRounds} from "modules/reducers";

export interface IShowPlayerProfile {
	playerId: number;
	participantId?: number;
}

export const showPlayerProfile = createAction<IShowPlayerProfile>();
export const hidePlayerProfile = createAction();
export const clearPlayerProfileId = createAction();

export interface IFetchPlayersSets {
	playerId: number;
	tournamentId: number;
}

export const fetchPlayerSets = createAction<IFetchPlayersSets>();
export const fetchPlayerMatches = createAction<IFetchPlayersSets>();

export interface IPlayersSets {
	[id: number]: IPlayerSet[];
}

export const fetchPlayersSetsSuccess = createAction<IPlayersSets>();
export const fetchPlayersMatchesSuccess = createAction<IRounds>();
