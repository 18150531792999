import React, {useCallback, useEffect, useRef, useState} from "react";
import {Exist, FormSelectIcon, MenuItem, SearchPlayers, Select} from "components";
import styled from "styled-components";
import {useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
	getActiveTournamentId,
	getTournamentsBySettings,
	getEventsByParticipants,
	getCourtNamesByTournament,
	getActiveCourt,
	getActiveEvent,
	getRoundsByActiveTournament,
	getDaysForOrderOfPlay,
	getDisplayDays,
	checkIsCustomEvent,
	getActiveDay,
	getActiveDayForOrderOfPlay,
	getCourtCompleteNamesByTournament,
	getActiveCompleteCourt,
	getCourtPlayingNamesByTournament,
	getPlayingCourt,
	getActiveTournamentSettings,
	getCompleteDay,
	getActualEvent,
} from "modules/selectors";
import {SelectChangeEvent} from "@mui/material";
import {get, orderBy} from "lodash";
import {
	setActiveCompleteCourt,
	setActiveCourt,
	setActiveDay,
	setActiveEvent,
	setActiveRoundName,
	setActiveTournament,
	setPlayingCourt,
	setSelectDayForOrderOfPlay,
} from "modules/actions";
import {
	capitalizeFirstLetter,
	dateWithoutDayOfWeek,
	dayOfWeek,
	sendDataLayer,
	useIsMobile,
} from "modules/utils";

const SelectContainer = styled.div<{isHidden?: boolean}>`
	width: 100%;
	margin-top: 20px;
	max-width: 100%;
	text-transform: capitalize;
	display: ${({isHidden}) => (isHidden ? "none" : "block")};
	margin-left: 20px;

	&:first-child {
		margin-left: 0;
	}

	&.full-width {
		max-width: 100%;
	}

	@media (max-width: ${({theme}) => theme.media.mobile}) {
		display: ${({isHidden}) => (isHidden ? "none" : "block")};
	}

	@media (min-width: ${({theme}) => theme.media.desktop}) {
		margin-top: 0;
	}
`;

const Wrapper = styled.div<{isShowDraft?: boolean}>`
	width: 100%;
	max-width: ${({isShowDraft}) => (isShowDraft ? "500px" : "auto")};
	margin: 0 auto;

	// ${SelectContainer}:first-child {
	// 	margin-left: 0;
	// }
`;

const FlexWrapper = styled(Wrapper)<{showMargin: boolean; isHidden: boolean}>`
	display: ${({isHidden}) => (isHidden ? "none" : "flex")};

	@media (min-width: ${({theme}) => theme.media.desktop}) {
		${({showMargin}) => (showMargin ? "margin-top: 20px;" : "")}
	}
`;

const DateWrapper = styled.ul`
	position: relative;
	width: 100%;
	display: flex;
	overflow: scroll;
	-ms-overflow-style: none;
	scrollbar-width: none;
	margin-top: 20px;

	li {
		margin-left: 10px;
	}
`;

const DateItem = styled.button<{isActive: boolean; colorBlock: string}>`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 46px;
	width: 100px;
	color: ${({isActive, colorBlock}) => (isActive ? "#ffffff" : colorBlock)};
	background: ${({isActive, colorBlock}) => (isActive ? colorBlock : "#ffffff")};
	text-align: center;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	border-radius: 4px;
	border: none;

	&:first-child {
		margin-left: 0;
	}

	div {
		color: ${({isActive}) => (isActive ? "#ffffff" : "#4c4a4a")};
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}
	p {
		color: ${({isActive, colorBlock}) => (isActive ? "#ffffff" : colorBlock)};
		font-size: 10px;
		font-style: normal;
		font-weight: 500;
		line-height: 17px;
		width: 80px;
	}
`;

const scrollInDataBlock = () => {
	document.getElementById("wrapperDates")?.scroll({
		top: 0,
		left: 2000,
		behavior: "smooth",
	});

	document.getElementById("wrapperDatesForOrderOfPlay")?.scroll({
		top: 0,
		left: 2000,
		behavior: "smooth",
	});
};

const scrollToElement = (id: string) => {
	const element = document.getElementById(id);
	element?.scrollIntoView({block: "center", inline: "center", behavior: "smooth"});
};

const getCurrentDay = (isCompletedVisible: boolean, completeDay: string, activeDay: string) =>
	isCompletedVisible ? completeDay : activeDay;

const isShowLiveSection = (
	isHaveRounds: boolean,
	isShowDraft: boolean,
	isOrderOfPlayVisible: boolean
) => isHaveRounds && !isShowDraft && !isOrderOfPlayVisible;

export const Filters: React.FC = () => {
	const {isMobile} = useIsMobile();
	const location = useLocation();
	const dispatch = useDispatch();
	const tournaments = useSelector(getTournamentsBySettings);
	const tournamentId = useSelector(getActiveTournamentId);
	const [isSenDayDataLayer, setIsSenDayDataLayer] = useState<boolean>(true);
	const isCustomTournament = useSelector(checkIsCustomEvent);
	const activeDayForOrderOfPlay = useSelector(getActiveDayForOrderOfPlay);

	const pathname = location.pathname;
	const isLivePage = pathname === "/";
	const isCompletedVisible = pathname === "/completed";
	const completeDay = useSelector(getCompleteDay);
	const activeDay = useSelector(getActiveDay);
	const currentDay = getCurrentDay(isCompletedVisible, completeDay, activeDay);
	const displayDates = useSelector(getDisplayDays)(isLivePage, isCompletedVisible);

	const datesForOrderOfPlay = useSelector(getDaysForOrderOfPlay);
	const displayDatesForOrderOfPlay = orderBy(datesForOrderOfPlay, ["date"], "asc");
	const rounds = useSelector(getRoundsByActiveTournament)(isLivePage);

	const isShowDraft = pathname === "/draw";
	const draftEvents = useSelector(getEventsByParticipants);
	const activeEvent = useSelector(getActiveEvent);
	const actualEvent = useSelector(getActualEvent);
	const activeEventForOrderOfPlay = useSelector(getActiveEvent);
	const playingCourt = useSelector(getPlayingCourt);

	const isOrderOfPlayVisible = pathname === "/play";
	const courtNames = useSelector(getCourtNamesByTournament);
	const completeCourtNames = useSelector(getCourtCompleteNamesByTournament);
	const playingCourtNames = useSelector(getCourtPlayingNamesByTournament);

	const activeCourt = useSelector(getActiveCourt);
	const activeCompleteCourt = useSelector(getActiveCompleteCourt);

	const lastSliderItem = isOrderOfPlayVisible
		? displayDatesForOrderOfPlay.length - 1
		: displayDates.length - 1;
	const sliderRef = useRef(null);

	const settings = useSelector(getActiveTournamentSettings);
	const headerDateColour = get(settings, "scoreCentre.headerDateColour", "#8F1DBF");

	useEffect(() => {
		setTimeout(() => scrollInDataBlock(), 500);
	}, []);

	const goToSlide = useCallback(
		(item: number) => {
			const slickGoTo = get(sliderRef.current, "slickGoTo", (item: number) => item);
			slickGoTo(item);
		},
		[sliderRef]
	);

	const goToMaxDay = useCallback(
		(slideTo: number) => {
			setIsSenDayDataLayer(false);
			goToSlide(slideTo);
		},
		[goToSlide]
	);

	useEffect(() => {
		setTimeout(() => {
			goToMaxDay(lastSliderItem);
		}, 0);
	}, [lastSliderItem, goToMaxDay]);

	const onChangeTournament = useCallback(
		(e: SelectChangeEvent<unknown>) => {
			const id = e.target.value as number | "";

			if (id === "") {
				dispatch(setActiveRoundName(""));
				dispatch(setActiveDay(""));
				dispatch(setActiveCourt(""));
			}

			dispatch(setActiveDay(""));
			dispatch(setSelectDayForOrderOfPlay(""));
			dispatch(setActiveRoundName(""));
			dispatch(setActiveEvent(""));
			dispatch(setActiveCourt(""));
			dispatch(setActiveCompleteCourt(""));
			dispatch(setPlayingCourt(""));
			dispatch(setActiveTournament(id));
			sendDataLayer("tournament_dropdown_click");
			scrollInDataBlock();
		},
		[dispatch]
	);

	const onChangeCourt = useCallback(
		(e: SelectChangeEvent<unknown>) => {
			const court = e.target.value as string;
			dispatch(setActiveCourt(court));
			sendDataLayer("court_dropdown_click");
		},
		[dispatch]
	);

	const onChangeCompleteCourt = useCallback(
		(e: SelectChangeEvent<unknown>) => {
			const court = e.target.value as string;
			dispatch(setActiveCompleteCourt(court));
			sendDataLayer("court_dropdown_click");
		},
		[dispatch]
	);

	const onChangePlayingCourt = useCallback(
		(e: SelectChangeEvent<unknown>) => {
			const court = e.target.value as string;
			dispatch(setPlayingCourt(court));
			sendDataLayer("court_dropdown_click");
		},
		[dispatch]
	);

	const changeDay = useCallback(
		(newActiveDay: string) => {
			dispatch(setActiveDay(newActiveDay));
			if (isSenDayDataLayer) {
				sendDataLayer("day_dropdown_click");
			}
			setIsSenDayDataLayer(true);
			scrollToElement(newActiveDay);
		},
		[dispatch, isSenDayDataLayer]
	);

	const changeDayForOrderOfPlay = useCallback(
		(newActiveDay: string) => {
			dispatch(setSelectDayForOrderOfPlay(newActiveDay));
			if (isSenDayDataLayer) {
				sendDataLayer("day_dropdown_click");
			}
			setIsSenDayDataLayer(true);
			scrollToElement(newActiveDay);
		},
		[dispatch, isSenDayDataLayer]
	);

	const onChangeEvent = useCallback(
		(e: SelectChangeEvent<unknown>) => {
			const name = e.target.value as string;
			dispatch(setActiveEvent(name));
			dispatch(setActiveDay(""));
			dispatch(setSelectDayForOrderOfPlay(""));
			sendDataLayer("event_dropdown_click");
			goToMaxDay(10);
		},
		[dispatch, goToMaxDay]
	);

	useEffect(() => {
		if (!courtNames.includes(activeCourt)) {
			dispatch(setActiveCourt(""));
		}
		dispatch(setActiveCompleteCourt(""));
		dispatch(setPlayingCourt(""));
	}, [courtNames, activeCourt, activeEvent, dispatch]);

	useEffect(() => {
		dispatch(setActiveEvent(""));
	}, [dispatch, pathname]);

	useEffect(() => {
		dispatch(setActiveCourt(""));
		dispatch(setActiveRoundName(""));
		dispatch(setActiveDay(""));
		dispatch(setSelectDayForOrderOfPlay(""));
		dispatch(setActiveCompleteCourt(""));
	}, [isCompletedVisible, dispatch]);

	const fullWidthClass = "full-width";

	const populateDraftDaySelectOptions = () => {
		return draftEvents.map((item) => (
			<MenuItem key={item} value={item}>
				{capitalizeFirstLetter(item)}
			</MenuItem>
		));
	};

	return (
		<Wrapper isShowDraft={isShowDraft}>
			<Exist when={tournaments.length > 1}>
				<SelectContainer className={fullWidthClass}>
					<Select
						name="tournaments"
						displayEmpty
						value={tournamentId}
						IconComponent={FormSelectIcon}
						onChange={onChangeTournament}>
						{tournaments.map(({id, name}) => (
							<MenuItem key={id} value={id}>
								{name}
							</MenuItem>
						))}
					</Select>
				</SelectContainer>
			</Exist>

			<Exist
				when={isShowLiveSection(Boolean(rounds.length), isShowDraft, isOrderOfPlayVisible)}>
				<FlexWrapper showMargin={tournaments.length > 1} isHidden={isCustomTournament}>
					<SelectContainer>
						<Exist when={Boolean(draftEvents.length) && !isLivePage}>
							<Select
								name="event"
								value={activeEvent}
								onChange={onChangeEvent}
								IconComponent={FormSelectIcon}
								displayEmpty>
								<MenuItem value={""}>All events</MenuItem>
								{draftEvents.map((item) => (
									<MenuItem key={item} value={item}>
										{capitalizeFirstLetter(item)}
									</MenuItem>
								))}
							</Select>
						</Exist>
						<Exist when={Boolean(draftEvents.length) && isLivePage}>
							<Select
								name="event"
								value={activeEventForOrderOfPlay}
								onChange={onChangeEvent}
								IconComponent={FormSelectIcon}
								displayEmpty>
								<MenuItem value={""}>All events</MenuItem>
								{populateDraftDaySelectOptions()}
							</Select>
						</Exist>
					</SelectContainer>

					<Exist when={isCompletedVisible}>
						<SelectContainer isHidden={isCustomTournament}>
							<Exist when={Boolean(completeCourtNames.length)}>
								<Select
									name="court"
									value={activeCompleteCourt}
									onChange={onChangeCompleteCourt}
									displayEmpty
									IconComponent={FormSelectIcon}>
									{completeCourtNames.length > 0 && (
										<MenuItem value={""}>All courts</MenuItem>
									)}
									{completeCourtNames.map((court, i) => (
										<MenuItem key={i} value={court || ""}>
											{court}
										</MenuItem>
									))}
								</Select>
							</Exist>
						</SelectContainer>
					</Exist>

					<Exist when={!isCompletedVisible}>
						<SelectContainer isHidden={isCustomTournament}>
							<Select
								name="court"
								value={playingCourt}
								onChange={onChangePlayingCourt}
								displayEmpty
								IconComponent={FormSelectIcon}>
								<MenuItem value={""}>All courts</MenuItem>
								{playingCourtNames.map((court, i) => (
									<MenuItem key={i} value={court || ""}>
										{court}
									</MenuItem>
								))}
							</Select>
						</SelectContainer>
					</Exist>
				</FlexWrapper>

				<Exist when={!isCustomTournament}>
					<DateWrapper id={"wrapperDates"}>
						{displayDates.map(({date}) => {
							return (
								<li id={date} key={date}>
									<DateItem
										isActive={currentDay === date}
										colorBlock={headerDateColour}
										onClick={() => changeDay(date)}>
										<div>{dayOfWeek(date)}</div>
										<p>{dateWithoutDayOfWeek(date)}</p>
									</DateItem>
								</li>
							);
						})}
					</DateWrapper>
				</Exist>
			</Exist>

			<Exist when={isOrderOfPlayVisible}>
				<FlexWrapper showMargin={tournaments.length > 1} isHidden={isCustomTournament}>
					<SelectContainer>
						<Exist when={Boolean(draftEvents.length)}>
							<Select
								name="event"
								value={activeEventForOrderOfPlay}
								onChange={onChangeEvent}
								IconComponent={FormSelectIcon}
								displayEmpty>
								<MenuItem value={""}>All events</MenuItem>
								{populateDraftDaySelectOptions()}
							</Select>
						</Exist>
					</SelectContainer>

					<SelectContainer isHidden={isCustomTournament}>
						<Exist when={Boolean(courtNames.length)}>
							<Select
								name="court"
								value={activeCourt}
								onChange={onChangeCourt}
								displayEmpty
								IconComponent={FormSelectIcon}>
								{courtNames.length > 0 && (
									<MenuItem value={""}>All courts</MenuItem>
								)}
								{courtNames.map((court, i) => (
									<MenuItem key={i} value={court || ""}>
										{court}
									</MenuItem>
								))}
							</Select>
						</Exist>
					</SelectContainer>
				</FlexWrapper>
				<Exist when={!isCustomTournament}>
					<DateWrapper id={"wrapperDatesForOrderOfPlay"}>
						{displayDatesForOrderOfPlay.map(({date}) => {
							return (
								<li key={date} id={date}>
									<DateItem
										isActive={activeDayForOrderOfPlay === date}
										colorBlock={headerDateColour}
										onClick={() => changeDayForOrderOfPlay(date)}>
										<div>{dayOfWeek(date)}</div>
										<p>{dateWithoutDayOfWeek(date)}</p>
									</DateItem>
								</li>
							);
						})}
					</DateWrapper>
				</Exist>
			</Exist>

			<Exist when={isShowDraft && Boolean(draftEvents.length)}>
				<FlexWrapper showMargin={tournaments.length > 1} isHidden={isCustomTournament}>
					<SelectContainer className={fullWidthClass}>
						<Select
							name="event"
							value={actualEvent}
							onChange={onChangeEvent}
							IconComponent={FormSelectIcon}>
							{populateDraftDaySelectOptions()}
						</Select>
					</SelectContainer>
				</FlexWrapper>
			</Exist>
			<Exist when={isMobile}>
				<SearchPlayers />
			</Exist>
		</Wrapper>
	);
};
