import {createReducer} from "redux-act";
import {
	RequestState,
	TournamentStatusType,
	ParticipantTypes,
	ParticipantStatusType,
	EventsTypes,
} from "modules/enums";
import {
	fetchChecksumsJSONSuccess,
	fetchParticipantSetsSuccess,
	fetchPlayersSetsSuccess,
	fetchTournamentsSettingsSuccess,
	IParticipantSets,
	requestTournaments,
	requestTournamentsFailed,
	requestTournamentsSuccess,
	setActiveCourt,
	setActiveDay,
	setActiveEvent,
	setActiveRoundName,
	setActiveTournament,
	setFilterTournamentBy,
	setHoverPlayer,
	unsetHoverPlayer,
	IPlayersSets,
	fetchPlayersMatchesSuccess,
	setSelectDayForOrderOfPlay,
	setActiveCompleteCourt,
	setPlayingCourt,
} from "modules/actions";
import {IDictionary} from "modules/types";
// import {filter} from "lodash";

export type TTeam = {
	playerId: number;
};

export interface ITeams {
	teamA: TTeam[];
	teamB: TTeam[];
}

export interface ISet {
	number: number;
	scoreA: number | null;
	pointsA: number | null;
	scoreB: number | null;
	pointsB: number | null;
	acesA: number | null;
	acesB: number | null;
	doubleFaultsA: number | null;
	doubleFaultsB: number | null;
	percentageFirstServeA: number | null;
	percentageFirstServeB: number | null;
	percentageSecondServeA: number | null;
	percentageSecondServeB: number | null;
	percentageWinOnFirstServeA: number | null;
	percentageWinOnFirstServeB: number | null;
	percentageWinOnSecondServeA: number | null;
	percentageWinOnSecondServeB: number | null;
	breakPointsConvertedA: number | null;
	breakPointsConvertedB: number | null;
	breakPointsPlayedA: number | null;
	breakPointsPlayedB: number | null;
	totalPointsWonA: number | null;
	totalPointsWonB: number | null;
	tieBreakScoreA: number | null;
	tieBreakScoreB: number | null;
}

export interface IParticipant {
	id: number;
	type: ParticipantTypes;
	day: number;
	status: ParticipantStatusType;
	startDate: string;
	suspended: boolean;
	timeTotal: string | null;
	teams: ITeams;
	sets: ISet[];
	seedA: number | null;
	seedB: number | null;
	event: EventsTypes;
	serve: "A" | "A2" | "B" | "B2" | null;
	winner: "A" | "B" | null;
	roundName?: string;
	currentGameScoreA: string | number | null;
	currentGameScoreB: string | number | null;
	courtName: string;
	courtId: number | null;
	isBye: boolean;
	highlightsLink: string | null;
	notBeforeISOTime: string | null;
	notBeforeText: string | null;
	entryTypeA: string | null;
	entryTypeB: string | null;
	isWalkOver: boolean;
	isRetired: boolean;
	isDrawFinalCard?: boolean;
	isDrawPageOnly: boolean;
	setsAvailable: boolean;
	feedType: "PROTENNIS" | "WTA" | "";
	courtOrderKey?: number;
}

interface IRoundMap {
	[key: string]: IParticipant[];
}

export interface IRounds extends IRoundMap {
	first: IParticipant[];
	second: IParticipant[];
	third: IParticipant[];
	fourth: IParticipant[];
	quarterFinal: IParticipant[];
	semiFinal: IParticipant[];
	final: IParticipant[];
}

export interface ITournament {
	id: number;
	name: string;
	year: number;
	link: string | null;
	location: string;
	status: TournamentStatusType;
	startDate: string;
	endDate: string;
	rounds: IRounds;
}

export interface ITournamentsSetting {
	tournamentId: number;
	displayOnPage: string;
	scoreCentre: {
		headerPanelColour: string | null;
		headerLogo: string | null;
		cardHeaderColour: string | null;
		cardFooterLogo: string | null;
		cardFooterColour: string | null;
		playerHighlightColor: string | null;
		playerHighlightColour: string | null;
		liveMatchesTitleColour: string | null;
	};
	draw: {
		roundNavigationPanelColour: string | null;
		roundNavigationSelectorColour: string | null;
	};
	playerProfiles: {
		statsTilesColour: string | null;
		statsTextColour: string | null;
	};
}

export interface IPlayerSet {
	event: string;
	tieBreakScore: number | null;
	points: number | null;
	aces: number | null;
	doubleFaults: number | null;
	breakPointsConverted: number | null;
	breakPointsPlayed: number | null;
	totalPointsWon: number | null;
	breakPointsSaved: number | null;
	percentageFirstServe: number | null;
	percentageSecondServe: number | null;
	percentageWinOnFirstServe: number | null;
	percentageWinOnSecondServe: number | null;
}

export interface IJSONsReducer {
	activeTournamentId: "" | number;
	activeRoundName: string;
	activeDay: string;
	selectDayForOrderOfPlay: string;
	activeCourt: string;
	activeCompleteCourt: string;
	playingCourt: string;
	activeEvent: string;
	tournaments: ITournament[];
	requestState: RequestState;
	checksums: IDictionary<string>;
	tournamentsSettings: ITournamentsSetting[];
	hoverPlayer: number | null;
	matchSets: IParticipantSets;
	playersSets: IPlayersSets;
	playersMatches: IRounds;
	filterTournamentBy?: string;
}

const initial: IJSONsReducer = {
	activeTournamentId: "",
	activeRoundName: "",
	activeDay: "",
	selectDayForOrderOfPlay: "",
	activeCourt: "",
	activeCompleteCourt: "",
	playingCourt: "",
	activeEvent: "",
	tournaments: [],
	requestState: RequestState.IDLE,
	checksums: {},
	tournamentsSettings: [],
	hoverPlayer: null,
	matchSets: {},
	playersSets: {},
	playersMatches: {
		first: [],
		second: [],
		third: [],
		fourth: [],
		quarterFinal: [],
		semiFinal: [],
		final: [],
	},
};

const onRequestTournaments = (state: IJSONsReducer) => ({
	...state,
	requestState: RequestState.Requested,
});

const onRequestTournamentsSuccess = (state: IJSONsReducer, payload: ITournament[]) => {
	return {
		...state,
		tournaments: payload,
		requestState: RequestState.Received,
		matchSets: {},
		// matchSets: filter(state.matchSets, (set) => set.status === ParticipantStatusType.Complete),
	};
};

const onRequestTournamentsFailed = (state: IJSONsReducer) => ({
	...state,
	requestState: RequestState.Received,
});

const onFetchChecksumsJSONSuccess = (state: IJSONsReducer, payload: IDictionary<string>) => ({
	...state,
	checksums: payload,
});

const onFetchTournamentsSettingsSuccess = (
	state: IJSONsReducer,
	payload: ITournamentsSetting[]
) => ({
	...state,
	tournamentsSettings: payload,
});

const onSetActiveTournament = (state: IJSONsReducer, payload: number | "") => ({
	...state,
	activeTournamentId: payload,
});

const onSetActiveRoundName = (state: IJSONsReducer, payload: string) => ({
	...state,
	activeRoundName: payload,
});

const onSetActiveDay = (state: IJSONsReducer, payload: string) => ({
	...state,
	activeDay: payload,
});

const onSelectDayForOrderOfPlay = (state: IJSONsReducer, payload: string) => ({
	...state,
	selectDayForOrderOfPlay: payload,
});

const onSetActiveCourt = (state: IJSONsReducer, payload: string) => ({
	...state,
	activeCourt: payload,
});

const onSetActiveCompleteCourt = (state: IJSONsReducer, payload: string) => ({
	...state,
	activeCompleteCourt: payload,
});

const onSetPlayingCourt = (state: IJSONsReducer, payload: string) => ({
	...state,
	playingCourt: payload,
});

const onSetActiveEvent = (state: IJSONsReducer, payload: string) => ({
	...state,
	activeEvent: payload,
});

export const jsons = createReducer({}, initial)
	/**
	 * Tournaments
	 * */
	.on(requestTournaments, onRequestTournaments)
	.on(requestTournamentsSuccess, onRequestTournamentsSuccess)
	.on(requestTournamentsFailed, onRequestTournamentsFailed)
	/**
	 * fetchChecksumsJSONSuccess
	 * */
	.on(fetchChecksumsJSONSuccess, onFetchChecksumsJSONSuccess)
	/**
	 * fetchTournamentsSettingsSuccess
	 * */
	.on(fetchTournamentsSettingsSuccess, onFetchTournamentsSettingsSuccess)
	.on(setActiveTournament, onSetActiveTournament)
	.on(setActiveRoundName, onSetActiveRoundName)
	.on(setActiveDay, onSetActiveDay)
	.on(setSelectDayForOrderOfPlay, onSelectDayForOrderOfPlay)
	.on(setActiveCourt, onSetActiveCourt)
	.on(setActiveCompleteCourt, onSetActiveCompleteCourt)
	.on(setPlayingCourt, onSetPlayingCourt)
	.on(setActiveEvent, onSetActiveEvent)
	.on(setHoverPlayer, (state: IJSONsReducer, payload: number) => ({
		...state,
		hoverPlayer: payload,
	}))
	.on(unsetHoverPlayer, (state: IJSONsReducer) => ({
		...state,
		hoverPlayer: null,
	}))
	.on(fetchParticipantSetsSuccess, (state: IJSONsReducer, payload) => {
		return {
			...state,
			matchSets: {
				...state.matchSets,
				...payload,
			},
		};
	})
	.on(setFilterTournamentBy, (state: IJSONsReducer, payload) => {
		return {
			...state,
			filterTournamentBy: payload,
		};
	})
	.on(fetchPlayersSetsSuccess, (state: IJSONsReducer, payload) => {
		return {
			...state,
			playersSets: {
				...state.playersSets,
				...payload,
			},
		};
	})
	.on(fetchPlayersMatchesSuccess, (state: IJSONsReducer, payload) => {
		return {
			...state,
			playersMatches: payload,
		};
	});
