import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {IParticipant, ISet} from "modules/reducers";
import {Exist} from "components";
import {ParticipantStatusType} from "modules/enums";
import {useSelector} from "react-redux";
import {getActiveTournamentSettings, getMatchSetsById} from "modules/selectors";
import {get} from "lodash";
// import {MatchHistoryStats} from "./MatchHistoryStats";

const Wrapper = styled.div`
	margin-top: 20px;
	padding: 20px 16px 8px;
	width: 100%;
	border-top: 1px solid #e3e3e3;

	@media (min-width: ${({theme}) => theme.media.desktop}) {
		padding: 30px 0 24px;
	}
`;

const Title = styled.div`
	font-size: 16px;
	font-weight: 700;
	line-height: 17px;
	text-align: center;
	margin-bottom: 16px;

	@media (min-width: ${({theme}) => theme.media.desktop}) {
		font-size: 20px;
		margin-bottom: 23px;
	}
`;

const List = styled.div`
	width: 100%;
`;

const Item = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 12px;
	position: relative;
	overflow: hidden;

	@media (min-width: ${({theme}) => theme.media.desktop}) {
		margin-bottom: 16px;
	}
`;

interface IValue {
	$isActive?: boolean;
	statsHigherColour: string | null;
	statsLowerColour: string | null;
	statsLowerTextColour: string | null;
	statsHigherTextColour: string | null;
}

const Value = styled.div<IValue>`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	width: 56px;
	height: 32px;
	font-size: 14px;
	font-weight: 700;
	background: ${({$isActive, statsHigherColour, statsLowerColour}) =>
		$isActive ? statsHigherColour : statsLowerColour};
	color: ${({$isActive, statsLowerTextColour, statsHigherTextColour}) =>
		$isActive ? statsHigherTextColour : statsLowerTextColour};

	&:first-child {
		&:after {
			left: 0;
			z-index: -1;
			top: 16px;
			content: "";
			position: absolute;
			width: 100%;
			height: 1px;
			background-color: #e5e5e5;
		}
	}

	@media (min-width: ${({theme}) => theme.media.desktop}) {
		width: 72px;
		height: 36px;
		font-size: 18px;
	}
`;

const Text = styled.div<{matchStatsTextColour: string}>`
	text-align: center;
	font-size: 14px;
	background-color: #ffffff;
	padding: 7px;
	min-width: 101px;
	color: ${({matchStatsTextColour}) => matchStatsTextColour};

	@media (min-width: ${({theme}) => theme.media.desktop}) {
		padding: 0 24px;
		min-width: 210px;
	}
`;

interface IMatchStats {
	participant: IParticipant;
}

const getAces = (setSummary: ISet) => {
	const acesA = setSummary?.acesA || 0;
	const acesB = setSummary?.acesB || 0;

	return {
		text: "Aces",
		valueA: acesA,
		valueB: acesB,
		isAWin: acesA > acesB,
		isBWin: acesA < acesB,
	};
};

const getDoubleFaults = (setSummary: ISet) => {
	const doubleFaultsA = setSummary.doubleFaultsA || 0;
	const doubleFaultsB = setSummary.doubleFaultsB || 0;

	return {
		text: "Double faults",
		valueA: get(setSummary, "doubleFaultsA", 0),
		valueB: get(setSummary, "doubleFaultsB", 0),
		isAWin: doubleFaultsA < doubleFaultsB,
		isBWin: doubleFaultsA > doubleFaultsB,
	};
};

const getFirstServePercent = (setSummary: ISet) => ({
	text: "First serve %",
	valueA: `${get(setSummary, "percentageFirstServeA", "")}%` || "0%",
	valueB: `${setSummary?.percentageFirstServeB}%` || "0%",
	isAWin: (setSummary?.percentageFirstServeA || 0) > (setSummary?.percentageFirstServeB || 0),
	isBWin: (setSummary?.percentageFirstServeA || 0) < (setSummary?.percentageFirstServeB || 0),
});

const getSecondServe = (setSummary: ISet) => ({
	text: "Second serve %",
	valueA: `${setSummary?.percentageSecondServeA}%` || "0%",
	valueB: `${setSummary?.percentageSecondServeB}%` || "0%",
	isAWin: (setSummary?.percentageSecondServeA || 0) > (setSummary?.percentageSecondServeB || 0),
	isBWin: (setSummary?.percentageSecondServeA || 0) < (setSummary?.percentageSecondServeB || 0),
});

const getWinPercentOnFirstServe = (setSummary: ISet) => ({
	text: "Win % on first serve",
	valueA: `${setSummary?.percentageWinOnFirstServeA}%` || "0%",
	valueB: `${setSummary?.percentageWinOnFirstServeB}%` || "0%",
	isAWin:
		(setSummary?.percentageWinOnFirstServeA || 0) >
		(setSummary?.percentageWinOnFirstServeB || 0),
	isBWin:
		(setSummary?.percentageWinOnFirstServeA || 0) <
		(setSummary?.percentageWinOnFirstServeB || 0),
});

const getWinPercentOnSecondServe = (setSummary: ISet) => ({
	text: "Win % on second serve",
	valueA: `${setSummary?.percentageWinOnSecondServeA}%` || "0%",
	valueB: `${setSummary?.percentageWinOnSecondServeB}%` || "0%",
	isAWin:
		(setSummary?.percentageWinOnSecondServeA || 0) >
		(setSummary?.percentageWinOnSecondServeB || 0),
	isBWin:
		(setSummary?.percentageWinOnSecondServeA || 0) <
		(setSummary?.percentageWinOnSecondServeB || 0),
});

const getBreakPointsConverted = (setSummary: ISet) => {
	const breakPointsConvertedA = get(setSummary, "breakPointsConvertedA", 0) || 0;
	const breakPointsConvertedB = get(setSummary, "breakPointsConvertedB", 0) || 0;

	return {
		text: "Break points converted",
		valueA: `${breakPointsConvertedA}/${breakPointsConvertedA}` || 0,
		valueB: `${breakPointsConvertedB}/${breakPointsConvertedB}` || 0,
		isAWin: breakPointsConvertedA > breakPointsConvertedB,
		isBWin: breakPointsConvertedA < breakPointsConvertedB,
	};
};

const getTotalPointsWon = (setSummary: ISet) => ({
	text: "Total points won",
	valueA: setSummary?.totalPointsWonA || 0,
	valueB: setSummary?.totalPointsWonB || 0,
	isAWin: (setSummary?.totalPointsWonA || 0) > (setSummary?.totalPointsWonB || 0),
	isBWin: (setSummary?.totalPointsWonA || 0) < (setSummary?.totalPointsWonB || 0),
});

export const MatchStats: React.FC<IMatchStats> = ({participant}) => {
	const settings = useSelector(getActiveTournamentSettings);
	const sets = useSelector(getMatchSetsById)(participant.id);
	const [nowSets, setNowSets] = useState<ISet[]>([]);
	useEffect(() => {
		if (sets.length) {
			setNowSets(sets);
		}
	}, [sets, setNowSets]);
	const setSummary: ISet = nowSets.find((set) => set.number === 0) || ({} as ISet);

	const data = [
		getAces(setSummary),
		getDoubleFaults(setSummary),
		getFirstServePercent(setSummary),
		getSecondServe(setSummary),
		getWinPercentOnFirstServe(setSummary),
		getWinPercentOnSecondServe(setSummary),
		getBreakPointsConverted(setSummary),
		getTotalPointsWon(setSummary),
	];

	return (
		<Wrapper>
			{/*<MatchHistoryStats participant={participant} />*/}

			<Exist when={participant.status === ParticipantStatusType.Active}>
				<Title>Live stats</Title>
			</Exist>

			<Exist when={participant.status === ParticipantStatusType.Complete}>
				<Title>Match stats</Title>
			</Exist>

			<List>
				{data.map(({isBWin, isAWin, valueA, valueB, text}, i) => (
					<Item key={i}>
						<Value
							$isActive={isAWin}
							statsHigherColour={get(settings, "liveStats.statsHigherColour", null)}
							statsLowerColour={get(settings, "liveStats.statsLowerColour", null)}
							statsLowerTextColour={get(
								settings,
								"liveStats.statsLowerTextColour",
								"#fff"
							)}
							statsHigherTextColour={get(
								settings,
								"liveStats.statsHigherTextColour",
								"#000"
							)}>
							{valueA}
						</Value>
						<Text
							matchStatsTextColour={get(
								settings,
								"liveStats.matchStatsTextColour",
								""
							)}>
							{text}
						</Text>
						<Value
							$isActive={isBWin}
							statsHigherColour={get(settings, "liveStats.statsHigherColour", null)}
							statsLowerColour={get(settings, "liveStats.statsLowerColour", null)}
							statsLowerTextColour={get(
								settings,
								"liveStats.statsLowerTextColour",
								"#fff"
							)}
							statsHigherTextColour={get(
								settings,
								"liveStats.statsHigherTextColour",
								"#000"
							)}>
							{valueB}
						</Value>
					</Item>
				))}
			</List>
		</Wrapper>
	);
};
