import React, {useCallback, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {PageTitle} from "components/Title";
import {GameCard, Pagination} from "components";
import {useSelector} from "react-redux";
import {
	getActiveDay,
	getActiveRoundName,
	getActiveTournamentSettings,
	getActualEvent,
} from "modules/selectors";
import {IParticipant} from "modules/reducers";
import {sliceParticipantsForOrderOfPlay, useIsMobile} from "modules/utils";
import {get} from "lodash";
import Slider from "react-slick";
import {ParticipantStatusType} from "modules/enums";

const EmptyBlock = styled.div`
	display: block;
	width: 300px;
`;

const List = styled.div<{countParticipant: number}>`
	display: flex !important;
	flex-wrap: wrap;
	align-items: center;
	max-width: 1000px;
	background-size: cover;
	background-position: center;
	margin: 0 auto;
	flex-direction: column;

	${EmptyBlock} {
		&:first-child {
			display: none;
		}
	}

	@media (min-width: ${({theme}) => theme.media.desktop}) {
		display: grid !important;
		grid-template-columns: repeat(3, 1fr);
		justify-content: flex-start;
		flex-direction: initial;
		padding: 10px;
	}
`;

const SideParticipantsWrapper = styled.div`
	padding-top: 20px;
	width: 100%;

	.removeHeight {
		.slick-track {
			height: auto !important;
		}
	}
`;

const WrapperCard = styled.div`
	display: block;
	margin: 30px auto;
	position: relative;
	width: 100%;
	max-width: 310px;

	@media (min-width: ${({theme}) => theme.media.desktop}) {
		display: inline-flex;
		margin: 10px;
		width: 300px;
	}

	@media screen and (max-width: 1000px) {
		margin: 15px 0;
		width: 300px;
	}
`;

export const WarningText = styled.div`
	font-size: 16px;
	line-height: 35px;
	font-family: ${({theme}) => theme.fonts.primaryFontFamily};
	text-align: center;
	padding: 20px 10px;
	max-width: 800px;
	margin: 0 auto;
`;
const PageTitleStyled = styled(PageTitle)<{color?: string | null}>`
	position: absolute;
	top: -11px;
	left: 10px;
	margin: 0;
	color: ${({color}) => (color ? color : "#311b77")};

	@media screen and (min-width: ${({theme}) => theme.media.desktop}) {
		top: -11px;
	}
`;

const getEmptyBlocksByIndex = (index: number) => {
	if (index === 1) {
		return (
			<React.Fragment>
				<EmptyBlock />
				<EmptyBlock />
			</React.Fragment>
		);
	}

	return <EmptyBlock />;
};

let statusForCart: ParticipantStatusType | null = null;
const getParticipantStatus = (participant: IParticipant) => {
	if (statusForCart !== participant.status) {
		statusForCart = participant.status;
		return participant.status;
	}
	return null;
};

interface IProps {
	participants: IParticipant[];
}

export const SideParticipants: React.FC<IProps> = ({participants}) => {
	const {isMobile} = useIsMobile();
	const settings = useSelector(getActiveTournamentSettings);
	const slider = useRef(null);
	const activeEvent = useSelector(getActualEvent);
	const day = useSelector(getActiveDay);
	const roundName = useSelector(getActiveRoundName);
	const [activeTab, setActiveTab] = useState<number>(0);
	const chunkedParticipants = sliceParticipantsForOrderOfPlay(
		participants,
		isMobile ? 3 : 6,
		isMobile
	);
	const countParticipants = chunkedParticipants.length;
	const prevValue = activeTab - 1;
	const nextValue = activeTab + 1;
	statusForCart = null;
	const goToSlide = useCallback(
		(item: number) => {
			setActiveTab(item);
			const slickGoTo = get(slider.current, "slickGoTo", (item: number) => item);
			slickGoTo(item);
		},
		[slider, setActiveTab]
	);
	useEffect(() => {
		const slickGoTo = get(slider.current, "slickGoTo", (item: number) => item);
		setTimeout(() => {
			slickGoTo(0);
			setActiveTab(0);
		}, 500);
	}, [participants.length]);

	const pageScrollerArrowsColour = get(settings, "scoreCentre.pageScrollerArrowsColour")!;
	const countCourts = chunkedParticipants.length;
	const maxNumberItem = isMobile ? 3 : 5;
	let displayCourtName = "";

	useEffect(() => {
		setActiveTab(0);
	}, [roundName, day, activeEvent]);

	return (
		<SideParticipantsWrapper>
			<Pagination
				chunkedParticipants={chunkedParticipants}
				maxNumberItem={maxNumberItem}
				countCourts={countCourts}
				goToSlide={goToSlide}
				prevValue={prevValue}
				pageScrollerArrowsColour={pageScrollerArrowsColour}
				settings={settings}
				activeTab={activeTab}
				nextValue={nextValue}
			/>
			<Slider
				slidesToShow={1}
				slidesToScroll={1}
				vertical={true}
				infinite={false}
				arrows={false}
				swipe={false}
				speed={400}
				beforeChange={() => {
					setTimeout(() => {
						window.parent.postMessage(
							JSON.stringify({
								type: "voting_height",
								// payload: height,
								payload:
									document.querySelector<HTMLElement>("#mainWrapper")
										?.offsetHeight || 0,
							}),
							"*"
						);
					}, 100);
				}}
				adaptiveHeight={true}
				className={countParticipants === 1 ? "removeHeight" : ""}
				ref={slider}>
				{chunkedParticipants.map((participantsItems, key) => {
					let isAddedEmptyBlocks = false;
					return (
						<List key={key} countParticipant={participantsItems.length}>
							{participantsItems.map((participant, keyParticipant) => {
								const participantHaveUpdatedStatus =
									getParticipantStatus(participant);

								const getCourtName = () => {
									if (displayCourtName !== participant.courtName) {
										displayCourtName = participant?.courtName || "";
										return (
											<PageTitleStyled
												color={get(
													settings,
													"scoreCentre.orderOfPlayCourtTitles"
												)}>
												{participant.courtName}
											</PageTitleStyled>
										);
									}
									return "";
								};

								const participantHaveUpdatedCourt = getCourtName();

								if (
									participantHaveUpdatedCourt &&
									keyParticipant < 3 &&
									keyParticipant !== 0 &&
									!isAddedEmptyBlocks
								) {
									isAddedEmptyBlocks = true;
									return (
										<React.Fragment key={participant.id}>
											{getEmptyBlocksByIndex(keyParticipant)}
											<WrapperCard>
												{participantHaveUpdatedCourt}
												<GameCard
													participant={participant}
													participantSubTitle={
														participantHaveUpdatedStatus
													}
													blockKey={++key}
												/>
											</WrapperCard>
										</React.Fragment>
									);
								}
								return (
									<WrapperCard key={participant.id}>
										{participantHaveUpdatedCourt}
										<GameCard
											participant={participant}
											key={participant.id}
											participantSubTitle={participantHaveUpdatedStatus}
											blockKey={++key}
										/>
									</WrapperCard>
								);
							})}
						</List>
					);
				})}
			</Slider>
			<Pagination
				chunkedParticipants={chunkedParticipants}
				maxNumberItem={maxNumberItem}
				countCourts={countCourts}
				goToSlide={goToSlide}
				prevValue={prevValue}
				pageScrollerArrowsColour={pageScrollerArrowsColour}
				settings={settings}
				activeTab={activeTab}
				nextValue={nextValue}
			/>
		</SideParticipantsWrapper>
	);
};
