import React, {useCallback} from "react";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";
import {IconButton} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {clearPlayerProfileId, hideMatchStats} from "modules/actions";
import {get} from "lodash";
import {getActiveTournamentSettings} from "modules/selectors";
import {IMAGES_URL} from "modules/constants";
import {Exist} from "components";

const Wrapper = styled.div<{bg?: string | null}>`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: ${({bg}) => bg || "linear-gradient(180deg, #31176B 0%, #42177B 100%)"};
	width: 100%;
	height: 72px;
	padding: 0 20px;

	img {
		height: 40px;
	}

	@media (min-width: ${({theme}) => theme.media.desktop}) {
		height: 80px;
	}
`;

const CloseButton = styled(IconButton)`
	&.MuiButtonBase-root {
		// position: absolute;
		// right: 10px;
		padding: 1px;
		color: #ffffff;

		@media (min-width: ${({theme}) => theme.media.desktop}) {
			// right: 14px;
		}
	}
`;

export const CardHead: React.FC = () => {
	const dispatch = useDispatch();
	const settings = useSelector(getActiveTournamentSettings);
	const logo = get(settings, "scoreCentre.headerLogo");

	const clickHandler = useCallback(() => {
		dispatch(clearPlayerProfileId());
		dispatch(hideMatchStats());
	}, [dispatch]);

	return (
		<Wrapper bg={get(settings, "scoreCentre.cardHeaderColour")}>
			<Exist when={!!logo}>
				<img src={IMAGES_URL + logo} alt="Tournament's logo" />
			</Exist>
			<CloseButton onClick={clickHandler}>
				<CloseIcon />
			</CloseButton>
		</Wrapper>
	);
};
