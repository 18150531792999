import {createReducer} from "redux-act";
import {
	clearPlayerProfileId,
	hidePlayerProfile,
	showPlayerProfile,
	IShowPlayerProfile,
} from "modules/actions";

export interface IPlayerProfileReducer {
	isShow: boolean;
	id: number | null;
	participantId: number | null;
}

const initial: IPlayerProfileReducer = {
	isShow: false,
	id: null,
	participantId: null,
};

const onShowPlayerProfile = (state: IPlayerProfileReducer, payload: IShowPlayerProfile) => ({
	...state,
	id: payload.playerId,
	participantId: payload?.participantId || null,
	isShow: true,
});

const onHidePlayerProfile = (state: IPlayerProfileReducer) => ({
	...state,
	isShow: false,
});

const onClearPlayerProfileId = (state: IPlayerProfileReducer) => ({
	...state,
	id: null,
});

export const playerProfile = createReducer({}, initial)
	/**
	 * OpenPlayerProfile
	 * */
	.on(showPlayerProfile, onShowPlayerProfile)
	.on(hidePlayerProfile, onHidePlayerProfile)
	.on(clearPlayerProfileId, onClearPlayerProfileId);
