import React from "react";
import styled from "styled-components";
import {NavLink} from "react-router-dom";
import {Filters} from "./Filters";
import {useSelector} from "react-redux";
import {
	checkIsCustomEvent,
	getActiveTournament,
	getActiveTournamentSettings,
} from "modules/selectors";
import {get, partial} from "lodash";
import {IMAGES_URL} from "modules/constants";
import {Exist, SearchPlayers} from "components";
import {sendDataLayer, useIsMobile} from "modules/utils";

const Wrapper = styled.div<{bg?: string | null}>`
	width: 100%;
	display: flex;
	background: ${({bg}) => bg || "linear-gradient(180deg, #31176B 0%, #42177B 100%)"};
	flex-direction: column;
	align-items: center;
	padding: 16px;
	color: #333333;
`;

const MenuWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;

	@media (min-width: ${({theme}) => theme.media.desktop}) {
		flex-direction: row;
		margin-bottom: 20px;
	}
`;

const Logo = styled.a<{isCinchLogo: boolean}>`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 16px;

	img {
		height: 70px;
	}

	${({isCinchLogo}) =>
		isCinchLogo &&
		`
		img {
			height: 70px;
		}
	`};

	@media (min-width: ${({theme}) => theme.media.desktop}) {
		margin-right: 20px;
		margin-bottom: 0;
	}
`;

const Content = styled.div`
	width: 100%;

	@media (min-width: ${({theme}) => theme.media.desktop}) {
		margin-right: 10px;
	}
`;

const Tabs = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-around;
	border-radius: 4px;
	overflow: hidden;

	@media (min-width: ${({theme}) => theme.media.desktop}) {
		max-width: 300px;
		height: 100%;
		align-items: center;
	}
`;

interface ITab {
	activecolor: string | null;
	inactivecolor: string | null;
}

const Tab = styled(NavLink)<ITab>`
	display: block;
	text-align: center;
	padding: 10px 0;
	font-family: "Roboto", sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 16px;
	color: ${({inactivecolor}) => inactivecolor || "#ffffff"};

	&.active {
		position: relative;
		border-right: none;
		color: ${({activecolor}) => activecolor || "#4EE8FE"};
		text-decoration: underline;
		text-underline-offset: 10px;
	}

	&:last-child {
		border: none;
	}

	@media (min-width: ${({theme}) => theme.media.desktop}) {
		font-size: 13px;
	}
`;

export const Header: React.FC = () => {
	const {isMobile} = useIsMobile();
	const cinchUrl = "https://www.cinch.co.uk/";
	const rothesayUrl = "https://www.rothesay.com/";
	const cinchTournamentIds = [10, 12, 7, 8, 9];
	const settings = useSelector(getActiveTournamentSettings);
	const tournament = useSelector(getActiveTournament);
	const logo = get(settings, "scoreCentre.headerLogo", "");
	const logoUrl = IMAGES_URL + logo;
	const isCustomTournament = useSelector(checkIsCustomEvent);

	const getTournamentUrl = () => {
		if (isCustomTournament) {
			return "https://www.lexus.co.uk/";
		}
		return cinchTournamentIds.some((id) => id === settings?.tournamentId)
			? cinchUrl
			: rothesayUrl;
	};

	const inactiveColor = get(settings, "scoreCentre.scoreCentreDisabledTabColour", null);
	const activeColor = get(settings, "scoreCentre.scoreCentreTabColour", null);

	return (
		<Wrapper bg={get(settings, "scoreCentre.headerPanelColour")}>
			<MenuWrapper>
				<Exist when={!!logo}>
					<Logo
						href={tournament?.link || ""}
						target="_blank"
						isCinchLogo={getTournamentUrl().includes("cinch")}>
						<img src={logoUrl} alt="Tournament's logo" />
					</Logo>
				</Exist>

				<Content>
					<Tabs>
						<Tab
							to="/"
							inactivecolor={inactiveColor}
							activecolor={activeColor}
							onClick={partial(sendDataLayer, "live_tab_view")}>
							Live
						</Tab>
						<Tab
							to="/completed"
							inactivecolor={inactiveColor}
							activecolor={activeColor}
							onClick={partial(sendDataLayer, "completed_tab_view")}>
							Completed
						</Tab>
						<Tab
							to="/play"
							inactivecolor={inactiveColor}
							activecolor={activeColor}
							onClick={partial(sendDataLayer, "order_of_play_tab_view")}>
							Order of play
						</Tab>
						<Tab
							to="/draw"
							inactivecolor={inactiveColor}
							activecolor={activeColor}
							onClick={partial(sendDataLayer, "draw_tab_view")}>
							Draw
						</Tab>
					</Tabs>
				</Content>
				<Exist when={!isMobile}>
					<SearchPlayers />
				</Exist>
			</MenuWrapper>

			<Filters />
		</Wrapper>
	);
};
