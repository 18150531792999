import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {
	getActivePlayer,
	getActiveTournament,
	getMatchStatsState,
	getPlayerProfileState,
	getPlayersParticipants,
	getPlayerTournaments,
} from "modules/selectors";
import {Exist} from "components";
import {PlayerCardHead} from "./PlayerCardHead";
import {PlayerProfile} from "./PlayerProfile";
import {CareerStats} from "./CareerStats";
import {TennisStats} from "./TennisStats";
import {fetchPlayerMatches, fetchPlayerSets} from "modules/actions";
import {get} from "lodash";
import {changeIframeHeight, useIsMobile} from "modules/utils";
import {LiveMatches} from "./LiveMatches";

const Wrapper = styled.div<{isShowMatchStats: boolean; activeTab: PlayerProfileCardTab}>`
	position: absolute;
	font-family: "Red Hat Display", sans-serif;
	left: 0;
	top: 0;
	width: 100%;
	//height: 100%;
	z-index: 10;
	background-color: #ffffff;
	backface-visibility: hidden;
	-webkit-perspective: 0;
	-webkit-backface-visibility: hidden;
	-webkit-transform: translate3d(0, 0, 0);
	transform: ${({isShowMatchStats, activeTab}) =>
		isShowMatchStats ? "rotateY(0)" : "rotateY(180deg)"};
	//min-height: 100vh;
	overflow: hidden;

	@media screen and (max-width: 950px) {
		min-height: auto;
		height: auto;
	}
`;

export enum PlayerProfileCardTab {
	Matches,
	Career,
	Tennis,
}

export const PlayerProfileCard: React.FC = () => {
	const dispatch = useDispatch();
	const player = useSelector(getActivePlayer);
	const [activeTab, setActiveTab] = useState<PlayerProfileCardTab>(PlayerProfileCardTab.Matches);
	const {id, isShow, participantId} = useSelector(getPlayerProfileState);
	const {isShow: isShowMatchStats} = useSelector(getMatchStatsState);
	const tournaments = useSelector(getPlayerTournaments)(id);
	const tournament = useSelector(getActiveTournament);
	const {isMobile} = useIsMobile();
	const playingParticipants = useSelector(getPlayersParticipants);

	useEffect(() => {
		if (tournament && player) {
			if (get(player, `tournamentStats.${tournament.id}`, false)) {
				dispatch(
					fetchPlayerSets({
						playerId: player.id,
						tournamentId: tournament.id,
					})
				);
			}
			dispatch(
				fetchPlayerMatches({
					playerId: player.id,
					tournamentId: tournament.id,
				})
			);
		}
	}, [dispatch, player, tournament]);

	useEffect(() => {
		const rootElement = document.getElementById("app");

		if (rootElement) {
			rootElement.style.overflow = isShow ? "hidden" : "visible";
			rootElement.style.height = isShow ? "100vh" : "inherit";
		}

		if (isShow) {
			setActiveTab(PlayerProfileCardTab.Matches);
		}
	}, [isShow, setActiveTab]);

	useEffect(() => {
		changeIframeHeight(document.querySelector<HTMLElement>("#playerProfile")?.offsetHeight);
		// if (id && isMobile) {
		// 	changeIframeHeight(document.querySelector<HTMLElement>("#playerProfile")?.offsetHeight);
		// }
	}, [id, activeTab, isMobile, playingParticipants.length]);

	if (!id) return null;

	const showTennisStats = !!tournament && activeTab === PlayerProfileCardTab.Tennis;

	return (
		<Wrapper isShowMatchStats={isShowMatchStats} activeTab={activeTab} id={"playerProfile"}>
			<PlayerCardHead />
			<PlayerProfile setActiveTab={setActiveTab} activeTab={activeTab} />

			<Exist when={activeTab === PlayerProfileCardTab.Matches}>
				<LiveMatches />
			</Exist>

			<Exist when={activeTab === PlayerProfileCardTab.Career}>
				<CareerStats />
			</Exist>

			<Exist when={showTennisStats}>
				<TennisStats
					tournament={tournament!}
					tournaments={tournaments}
					participantId={participantId}
				/>
			</Exist>
		</Wrapper>
	);
};
