import {combineReducers} from "redux";
import {default as createSagaMiddleware} from "redux-saga";
import {configureStore} from "@reduxjs/toolkit";
import * as reducers from "./reducers";
import {rootSaga} from "./sagas";

const sagaMiddleware = createSagaMiddleware();
export const rootReducer = combineReducers(reducers);

export const store = configureStore({
	reducer: rootReducer,
	middleware: [sagaMiddleware],
});

sagaMiddleware.run(rootSaga);
