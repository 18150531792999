import {createReducer} from "redux-act";
import {hideMatchStats, showMatchStats} from "modules/actions";

export interface IMatchStatsReducer {
	isShow: boolean;
	id: number | null;
}

const initial: IMatchStatsReducer = {
	isShow: false,
	id: null,
};

const onShowMatchStats = (state: IMatchStatsReducer, payload: number) => ({
	...state,
	id: payload,
	isShow: true,
});

const onHideMatchStats = (state: IMatchStatsReducer) => ({
	...state,
	isShow: false,
});

export const matchStats = createReducer({}, initial)
	/**
	 * OpenMatchStats
	 * */
	.on(showMatchStats, onShowMatchStats)
	.on(hideMatchStats, onHideMatchStats);
