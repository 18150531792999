import React, {useState, useRef, useCallback, useEffect} from "react";
import {useSelector} from "react-redux";
import {
	getActiveRoundsByActiveEvent,
	getActiveTournamentId,
	getActualEvent,
	getMatchStatsState,
	getPlayerProfileState,
} from "modules/selectors";
import {CardsForRound} from "./CardsForRound";
import Slider from "react-slick";
import {Navigation} from "./Navigation";
import {get} from "lodash";
import styled from "styled-components";
import {useIsMobile} from "modules/utils";
import {WarningText} from "pages/ScoreCentreLive";
import {Exist} from "components/Exist";
import {CardsForRoundMobile} from "./CardsForRoundMobile";
// import {List} from "./DisplayParticipantsList";

const Wrapper = styled.div<{updateSliderHeight: boolean}>`
	position: relative;
	${({updateSliderHeight}) => (updateSliderHeight ? ".slick-slide{height: 500px;}" : "")}
`;

// const ListStyled = styled(List)`
// 	width: 270px;
// 	margin: 0 auto;
// `;

export const DrawCards: React.FC = () => {
	const {isMobile} = useIsMobile();
	const activeEvent = useSelector(getActualEvent);
	const rounds = useSelector(getActiveRoundsByActiveEvent);
	const tournamentId = useSelector(getActiveTournamentId);
	const {isShow: isShowMatchStatsState} = useSelector(getMatchStatsState);
	const {isShow: isShowPlayerProfileState} = useSelector(getPlayerProfileState);
	const slider = useRef(null);
	const [activeTab, setActiveTab] = useState<number>(0);
	const [activeSubTab, setActiveSubTab] = useState<number>(0);
	const [activeRoundName, setActiveRoundName] = useState<string>("first");
	const filteredRounds = rounds.filter((round) => round.participants.length !== 0);
	const firstRound = filteredRounds.find((round) => round.roundName === "first");
	const firstRoundParticipantCount = get(firstRound, "participant", []).length;

	const goToActiveSubTab = useCallback((tab: number) => {
		setActiveSubTab(tab);
		const element = document.getElementById(`subMenu${tab}`);
		element?.scrollIntoView({block: "nearest", inline: "start"});
	}, []);

	const goToSlide = useCallback((item: number) => {
		setActiveTab(item);
	}, []);

	const timeoutObject = useRef<NodeJS.Timeout | null>(null);

	useEffect(() => {
		timeoutObject.current = setTimeout(() => {
			const slickGoTo = get(slider.current, "slickGoTo", (item: number) => item);
			slickGoTo(activeTab);
		}, 400);
	}, [activeTab, timeoutObject]);

	useEffect(() => {
		setActiveSubTab(0);
		goToSlide(0);
		if (isMobile) {
			window.scroll({top: 0, behavior: "smooth"});
		}
	}, [activeEvent, setActiveSubTab, setActiveTab, tournamentId, goToSlide, isMobile]);

	const roundsCount = filteredRounds.length;
	const flashRounds =
		roundsCount === 1
			? []
			: filteredRounds
					.map((round) => round.roundName)
					.slice(roundsCount > 2 ? -2 : roundsCount === 1 ? 0 : -1);

	const sliderSettings = {
		slidesToShow: 1,
		slidesToScroll: 1,
		infinite: false,
		arrows: false,
		swipe: false,
		adaptiveHeight: true,
		vertical: false,
		speed: 350,
		beforeChange: () => {
			setTimeout(() => {
				window.parent.postMessage(
					JSON.stringify({
						type: "voting_height",
						// payload: height,
						payload:
							document.querySelector<HTMLElement>("#mainWrapper")?.offsetHeight || 0,
					}),
					"*"
				);
			}, 100);
		},
	};

	return (
		<Wrapper updateSliderHeight={isShowMatchStatsState || isShowPlayerProfileState}>
			<Exist when={!rounds.length}>
				<WarningText>
					<p>The draw is currently unavailable for this tournament. </p>
					<p>Matches will display here when the draw has been finalised.</p>
				</WarningText>
			</Exist>
			<Navigation
				goToSlide={goToSlide}
				activeTab={activeTab}
				setActiveSubTab={goToActiveSubTab}
				activeSubTab={activeSubTab}
				setActiveRoundName={setActiveRoundName}
			/>
			<Exist when={!isMobile}>
				<Slider {...sliderSettings} ref={slider}>
					{filteredRounds.map((item, key) => {
						const isRound = roundsCount === 1 || get(rounds, key + 1, null);
						if (!isRound) {
							return null;
						}
						return (
							<CardsForRound
								participant1={item}
								participant2={get(rounds, key + 1, null)}
								participant3={get(rounds, key + 2, null)}
								activeSubTab={activeSubTab}
								activeRoundName={activeRoundName}
								key={key}
								flashRounds={flashRounds}
							/>
						);
					})}
				</Slider>
			</Exist>
			<Exist when={isMobile}>
				<Slider {...sliderSettings} ref={slider}>
					{filteredRounds.map((item, key) => (
						<CardsForRoundMobile
							participant1={item}
							participant2={get(rounds, key + 1, null)}
							participant3={get(rounds, key + 2, null)}
							activeSubTab={activeSubTab}
							activeRoundName={activeRoundName}
							firstRoundParticipantCount={firstRoundParticipantCount}
							key={key}
						/>
					))}
				</Slider>
			</Exist>
		</Wrapper>
	);
};
