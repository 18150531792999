import React, {useCallback, useEffect, useState} from "react";
import styled, {keyframes} from "styled-components";
import {IParticipant, IPlayer, ISet, ITournamentsSetting} from "modules/types";
import {Exist, ServingIcon} from "components";
import {ParticipantStatusType, ParticipantTypes} from "modules/enums";
import {useDispatch, useSelector} from "react-redux";
import {getPlayerById} from "modules/selectors/players";
import {first, get, isNumber, last, partial} from "lodash";
import {ReactComponent as WinnerIcon} from "assets/images/icons/winner.svg";
import {ReactComponent as TrophyIcon} from "assets/images/icons/trophy.svg";
import {getPlayerUrlAvatar, getCountryFlag, sendDataLayer} from "modules/utils";
import axios from "axios";
import {
	getActiveTournamentSettings,
	getActualEvent,
	getMatchSets,
	getMatchSetsById,
	getMatchStatsState,
	getPlayerHoverId,
} from "modules/selectors";
import {
	fetchParticipantSets,
	setHoverPlayer,
	showPlayerProfile,
	unsetHoverPlayer,
} from "modules/actions";

const Wrapper = styled.div<{withoutHeader: boolean}>`
	background: #ffffff;
	${({withoutHeader}) => withoutHeader && "border-radius: 6px 6px 0 0;"}
	overflow: hidden;
`;

interface IPlayerRow {
	isTeamServing?: boolean;
	isTeamWinner?: boolean;
	playerHighlightColour?: string;
	playerHighlighted: boolean;
	playerPathTrackerColour?: string;
}

const getPlayerRowBackground = ({
	isTeamWinner,
	playerHighlightColour,
	playerHighlighted,
	playerPathTrackerColour,
}: IPlayerRow) => {
	if (playerHighlighted) {
		return `background: ${playerPathTrackerColour || "#4EE8FE"};`;
	}

	if (isTeamWinner) {
		return `background: ${playerHighlightColour || "#89FFBC"}`;
	}

	return "";
};

export const PlayerRow = styled.div<IPlayerRow>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 77px;
	font-weight: 700;
	font-size: 14px;
	line-height: 17px;
	border-bottom: 1px solid #ecf5f9;
	${getPlayerRowBackground};
`;

const ByePlayerRow = styled.div`
	display: flex;
	text-align: center;
	align-items: center;
	height: 77px;
	width: 100%;
	padding-left: 20px;
	border-bottom: 1px solid #ecf5f9;

	p {
		font-family: ${({theme}) => theme.fonts.secondaryFontFamily};
		font-weight: 600;
	}
`;

const PlayersWrapper = styled.div<{isComplete: boolean}>`
	${PlayerRow} {
		${({isComplete}) => (isComplete ? "border: none;" : "")}
		&:last-child {
			border: none;
		}
	}

	${ByePlayerRow} {
		&:last-child {
			border: none;
		}
	}
`;

interface IPlayerName {
	isComplete: boolean;
	isTeamWinner: boolean;
	isWheelchair: boolean;
}

const PlayerName = styled.div<IPlayerName>`
	margin-left: 10px;
	text-align: left;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 17px;
	flex: 2;
	opacity: ${({isComplete, isTeamWinner}) => (isComplete && !isTeamWinner ? ".6" : "1")};
	color: #333333;
	${({isWheelchair}) =>
		!isWheelchair
			? `
		&:hover {
		text-decoration: underline;
		cursor: pointer;
		color: #333333;
	}
	`
			: ""}
`;

const PlayerNameDouble = styled(PlayerName)`
	flex: 1;
	font-family: ${({theme}) => theme.fonts.secondaryFontFamily};
	&:hover {
		text-decoration: initial;
		cursor: initial;
	}
	button {
		display: block;
		border: none;
		background: transparent;
		font-weight: 600;
		font-size: 12px;
		text-align: left;
		color: #333333;

		&:hover {
			text-decoration: underline;
			cursor: pointer;
		}
	}
`;

const PlayerFlagImage = styled.img<{isStatsView: boolean; isSingle?: boolean}>`
	position: absolute;
	width: 25px !important;
	right: -1px;
	bottom: 5px;
	${({isStatsView}) =>
		isStatsView &&
		`
			right: 0;
			bottom: 0;
			left: 25px;
		`}

	${({isSingle}) => (!isSingle ? "right: 50px;" : "")}
	
	${({isStatsView, isSingle}) =>
		isStatsView &&
		isSingle &&
		`
			bottom: -8px;
		`}
`;

const Player2FlagImage = styled(PlayerFlagImage)`
	right: 0;

	${({isStatsView}) =>
		isStatsView &&
		`
			right: -1px;
			bottom: -20px;
		`}
`;

const PlayerAvatar = styled.div<{isStatsView: boolean; isMens: boolean; hideBlock: boolean}>`
	display: ${({hideBlock}) => (hideBlock ? "none" : "flex")};
	flex: 1;
	text-align: left;
	position: relative;
	//height: 100%;

	img {
		width: 58px;
		z-index: 1;
		&.player2 {
			margin-left: -15px;
			z-index: 0;
		}
	}
	${({isMens}) =>
		isMens &&
		`
		img {
			margin-top: 11px;
    		width: 65px;
		}
	`}

	.flag-one {
		right: 41px;
		bottom: 5px;
	}

	${({isStatsView}) =>
		isStatsView &&
		`
		.flag-one {
			bottom: 8px;
		}
	`}
`;

const PlayerSets = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	flex: 1;

	div {
		min-width: 17px;
		font-weight: 700;
		font-size: 16px;
		line-height: 21px;
	}

	svg {
		margin-right: 10px;
	}
`;

const PlayerScore = styled.div<{isTeamWinner: boolean; isComplete: boolean}>`
	position: relative;
	opacity: ${({isComplete, isTeamWinner}) => (isComplete && !isTeamWinner ? ".6" : "1")};
`;

const PlayerTieBreakScore = styled.span`
	position: absolute;
	font-size: 8px;
	top: -12px;
	right: 7px;
`;

const CurrentGameScore = styled.p`
	margin: 0 10px 0 0;
	padding: 0;
	font-size: 16px;
	min-width: 18px;
`;

const IsWalkOverRetired = styled.div`
	margin-right: 5px;
`;

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const ServingIconWrapper = styled.span`
	width: 35px;
	height: 25px;
	svg {
		animation: ${rotate} 3s linear infinite forwards;
		transform-origin: center;
	}
`;

const isPlayerFlagVisible = (nationality: string) => {
	return nationality === "RUS" || nationality === "BLR" || nationality === "JOR" ? false : true;
};

const getScoreA = (set: ISet, key: number, isTeamWinner: boolean, isComplete: boolean) => {
	return isNumber(set.scoreA) ? (
		<PlayerScore key={key} isTeamWinner={isTeamWinner} isComplete={isComplete}>
			{set.scoreA}
			{set.tieBreakScoreA !== 0 && set.tieBreakScoreB !== 0 && (
				<PlayerTieBreakScore>{set.tieBreakScoreA}</PlayerTieBreakScore>
			)}
		</PlayerScore>
	) : null;
};

const getScoreB = (set: ISet, key: number, isTeamWinner: boolean, isComplete: boolean) => {
	return isNumber(set.scoreB) ? (
		<PlayerScore key={key} isTeamWinner={isTeamWinner} isComplete={isComplete}>
			{set.scoreB}
			{set.tieBreakScoreA !== 0 && set.tieBreakScoreB !== 0 && (
				<PlayerTieBreakScore>{set.tieBreakScoreB}</PlayerTieBreakScore>
			)}
		</PlayerScore>
	) : null;
};

interface IPlayersScore {
	participant: IParticipant;
	sets: ISet[];
	teamType: "A" | "B";
	isTeamWinner: boolean;
	isPreview: boolean;
	playerHighlighted: boolean;
	showRetiresScore: boolean;
}

const getScore = (
	sets: ISet[],
	teamType: "A" | "B",
	isTeamWinner: boolean,
	isComplete: boolean
) => {
	if (!sets.length) {
		return null;
	}
	return sets.map((set, key) => {
		if (teamType === "A") {
			return getScoreA(set, key, isTeamWinner, isComplete);
		}
		if (teamType === "B") {
			return getScoreB(set, key, isTeamWinner, isComplete);
		}
		return null;
	});
};

const walkOverText = (isTeamWinner: boolean) =>
	!isTeamWinner ? <IsWalkOverRetired>W/O</IsWalkOverRetired> : null;

const retiredText = (
	isTeamWinner: boolean,
	showRetiresScore: boolean,
	isComplete: boolean,
	sets: ISet[],
	teamType: "A" | "B"
) => {
	const scores = getScore(sets, teamType, isTeamWinner, isComplete);

	if (showRetiresScore && !isTeamWinner) {
		return (
			<React.Fragment>
				<IsWalkOverRetired>RET</IsWalkOverRetired>
				{scores}
			</React.Fragment>
		);
	}

	return !isTeamWinner ? (
		<IsWalkOverRetired>RET</IsWalkOverRetired>
	) : showRetiresScore ? (
		<React.Fragment>{scores}</React.Fragment>
	) : null;
};

const getIconColor = (isWhite: boolean, color?: string) =>
	isWhite ? "#ffffff" : color || "#311B77";

const PlayersScore: React.FC<IPlayersScore> = ({
	participant,
	sets,
	teamType,
	isTeamWinner,
	isPreview,
	playerHighlighted,
	showRetiresScore,
}) => {
	const {isBye, isWalkOver, isRetired} = participant;
	const settings = useSelector(getActiveTournamentSettings);
	const isSetsLoaded = sets.length > 0;
	const isComplete = participant.status === ParticipantStatusType.Complete;

	if (isPreview) {
		return <IsWalkOverRetired>-</IsWalkOverRetired>;
	}

	if (isBye) {
		return null;
	}

	if (isWalkOver) {
		return walkOverText(isTeamWinner);
	}

	if (isRetired) {
		return retiredText(isTeamWinner, showRetiresScore, isComplete, sets, teamType);
	}

	if (!isSetsLoaded) {
		return (
			<ServingIconWrapper>
				<ServingIcon
					color={
						playerHighlighted
							? "#FFFFFF"
							: get(settings, "scoreCentre.servingIconColour")
					}
				/>
			</ServingIconWrapper>
		);
	}

	return <React.Fragment>{getScore(sets, teamType, isTeamWinner, isComplete)}</React.Fragment>;
};

interface IPlayerBlock {
	participant: IParticipant;
	isTeamServing: boolean;
	isTeamWinner: boolean;
	handleMouseOver: (e: React.SyntheticEvent<HTMLDivElement>) => void;
	handleMouseOut: () => void;
	openPlayerProfile: (player: IPlayer | undefined) => void;
	player: IPlayer | undefined;
	player2?: IPlayer | undefined;
	playerHighlighted: boolean;
	seed: number | null;
	sets: ISet[];
	isShowGameScore: boolean;
	gameScore: string | number | null;
	isShowBye: boolean;
	entryType: string | null;
	teamType: "A" | "B";
	showRetiresScore: boolean;
}

const allowedEntryType = ["Q", "WC"];

const getSeed = (seed: number | null, entryType: string | null) =>
	!seed && entryType && allowedEntryType.includes(entryType)
		? `(${entryType})`
		: seed
		? `(${seed})`
		: "";

const getPlayerName = (player: IPlayer | undefined) =>
	player ? `${player?.firstName.substring(0, 1)}. ${player?.lastName}` : "TBC";

const isHideFlagBlock = (flagIsNotVisible: boolean, isNotDouble: boolean, isNotShow: boolean) =>
	flagIsNotVisible && isNotDouble && isNotShow;

export const checkImage = (path: string) => {
	axios
		.get(path)
		.then(() => {
			return true;
		})
		.catch(() => {
			return false;
		});

	return false;
};

const getGameIcon = (
	isFinal: boolean,
	participant: IParticipant,
	playerHighlighted: boolean,
	settings: ITournamentsSetting | undefined
) =>
	isFinal || participant.isDrawFinalCard ? (
		<TrophyIcon fill={getIconColor(playerHighlighted)} />
	) : (
		<WinnerIcon
			fill={getIconColor(
				playerHighlighted,
				get(settings, "scoreCentre.cardWinnerIconColour")
			)}
		/>
	);

const PlayerBlock: React.FC<IPlayerBlock> = ({
	participant,
	isTeamServing,
	isTeamWinner,
	handleMouseOver,
	handleMouseOut,
	player,
	player2,
	playerHighlighted,
	openPlayerProfile,
	seed,
	sets,
	isShowGameScore,
	gameScore,
	teamType,
	isShowBye,
	entryType,
	showRetiresScore,
}) => {
	const settings = useSelector(getActiveTournamentSettings);
	const {isShow} = useSelector(getMatchStatsState);
	const isDouble = Boolean(player2);
	const isMensEvent = useSelector(getActualEvent).toLowerCase().startsWith("men's");
	const {roundName} = participant;
	const isFinal = roundName === "final";
	const isComplete = participant.status === ParticipantStatusType.Complete;

	return !isShowBye ? (
		<PlayerRow
			isTeamServing={isTeamServing}
			isTeamWinner={isTeamWinner}
			playerHighlightColour={get(settings, "scoreCentre.playerHighlightColour", "")}
			playerPathTrackerColour={get(settings, "draw.playerPathTrackerColour")}
			onMouseOver={handleMouseOver}
			onMouseOut={handleMouseOut}
			data-id={player?.id}
			playerHighlighted={playerHighlighted}>
			<PlayerAvatar
				isStatsView={isShow}
				isMens={isMensEvent}
				hideBlock={isHideFlagBlock(
					!isPlayerFlagVisible(player?.nationality as string),
					!isDouble,
					isShow
				)}>
				{!isShow && <img src={getPlayerUrlAvatar(player)} alt={"Avatar"} />}
				<Exist when={Boolean(player) && isPlayerFlagVisible(player?.nationality as string)}>
					<PlayerFlagImage
						isSingle={!isDouble}
						isStatsView={isShow}
						src={getCountryFlag(player)}
						alt="Country flag"
					/>
				</Exist>
				<Exist when={isDouble}>
					<React.Fragment>
						<Exist when={!isShow}>
							<img
								src={getPlayerUrlAvatar(player2)}
								alt={"Avatar"}
								className={"player2"}
							/>
						</Exist>
						{isPlayerFlagVisible(player2?.nationality as string) && (
							<Player2FlagImage
								isStatsView={isShow}
								src={getCountryFlag(player2)}
								alt="Country flag"
							/>
						)}
					</React.Fragment>
				</Exist>
			</PlayerAvatar>

			<Exist when={!isDouble}>
				<PlayerName
					onClick={partial(openPlayerProfile, player)}
					isTeamWinner={isTeamWinner}
					isComplete={isComplete}
					isWheelchair={Boolean(player?.isWheelchair)}>
					{getPlayerName(player)}
					{"\xa0"}
					{getSeed(seed, entryType)}
				</PlayerName>
			</Exist>

			<Exist when={isDouble}>
				<PlayerNameDouble
					isTeamWinner={isTeamWinner}
					isComplete={isComplete}
					isWheelchair={Boolean(player?.isWheelchair)}>
					<button onClick={partial(openPlayerProfile, player)}>
						{getPlayerName(player)}
						{"\xa0"}
						{getSeed(seed, entryType)}
					</button>
					<button onClick={partial(openPlayerProfile, player2)}>
						{getPlayerName(player2)}
						{"\xa0"}
						{getSeed(seed, entryType)}
					</button>
				</PlayerNameDouble>
			</Exist>
			<PlayerSets>
				<Exist when={isTeamServing}>
					<ServingIconWrapper>
						<ServingIcon
							color={
								playerHighlighted
									? "#FFFFFF"
									: get(settings, "scoreCentre.servingIconColour")
							}
						/>
					</ServingIconWrapper>
				</Exist>
				<Exist when={isTeamWinner}>
					{getGameIcon(isFinal, participant, playerHighlighted, settings)}
				</Exist>
				<PlayersScore
					participant={participant}
					sets={sets}
					teamType={teamType}
					isTeamWinner={isTeamWinner}
					isPreview={participant.status === ParticipantStatusType.Scheduled}
					playerHighlighted={playerHighlighted}
					showRetiresScore={showRetiresScore}
				/>
				{isShowGameScore ? (
					<CurrentGameScore>
						{getShowGameScore(gameScore, isTeamServing, participant.feedType)}
					</CurrentGameScore>
				) : null}
			</PlayerSets>
		</PlayerRow>
	) : (
		<ByePlayerRow>
			<p>BYE</p>
		</ByePlayerRow>
	);
};

interface IPropsPlayerCard {
	participant: IParticipant;
	showHoverPlayers?: boolean;
	openPlayerProfile: (player: IPlayer | undefined) => void;
	handleMouseOver: (e: React.SyntheticEvent<HTMLDivElement>) => void;
	handleMouseOut: () => void;
	showRetiresScore: boolean;
	sets: ISet[];
}

const getWinnerAndServeInfo = (participant: IParticipant) => {
	const isTeamAServing = participant.serve === "A";
	const isTeamBServing = participant.serve === "B";
	const isTeamAWinner = participant.winner === "A";
	const isTeamBWinner = participant.winner === "B";

	return {
		isTeamAServing,
		isTeamBServing,
		isTeamAWinner,
		isTeamBWinner,
	};
};

const variantsForProtennisScores = {
	deuce: {
		serve: 40,
		nonServe: 40,
	},
	"advantage in": {
		serve: "A",
		nonServe: 40,
	},
	"advantage out": {
		serve: 40,
		nonServe: "A",
	},
	game: {
		serve: "",
		nonServe: "",
	},
};

const checkIsShowGameScore = (participant: IParticipant) =>
	participant.status === "active" &&
	!participant.isWalkOver &&
	!participant.isRetired &&
	!participant.isBye;

const getShowGameScore = (
	currentGameScore: string | number | null,
	serve: boolean,
	feedType: "PROTENNIS" | "WTA" | ""
) => {
	if (typeof currentGameScore === "string") {
		if (feedType === "PROTENNIS") {
			return currentGameScore;
		}
		const currentVariant = get(
			variantsForProtennisScores,
			currentGameScore as keyof typeof variantsForProtennisScores
		);
		return currentVariant ? (serve ? currentVariant.serve : currentVariant.nonServe) : "";
	}

	return currentGameScore;
};

const SingleParticipant: React.FC<IPropsPlayerCard> = ({
	participant,
	openPlayerProfile,
	handleMouseOver,
	handleMouseOut,
	showHoverPlayers = false,
	showRetiresScore,
	sets,
}) => {
	const teamA = useSelector(getPlayerById)(first(participant.teams.teamA)?.playerId || 0);
	const teamB = useSelector(getPlayerById)(first(participant.teams.teamB)?.playerId || 0);
	const {seedA, seedB} = participant;
	const {isTeamAServing, isTeamBServing, isTeamAWinner, isTeamBWinner} =
		getWinnerAndServeInfo(participant);

	const isComplete = participant.status === ParticipantStatusType.Complete;

	const hoverId = useSelector(getPlayerHoverId);
	const playerAHighlighted = showHoverPlayers && hoverId === teamA?.id;
	const playerBHighlighted = showHoverPlayers && hoverId === teamB?.id;

	return (
		<PlayersWrapper isComplete={isComplete}>
			<PlayerBlock
				isTeamServing={isTeamAServing}
				isTeamWinner={isTeamAWinner}
				handleMouseOver={handleMouseOver}
				handleMouseOut={handleMouseOut}
				player={teamA}
				playerHighlighted={playerAHighlighted}
				openPlayerProfile={openPlayerProfile}
				seed={seedA}
				sets={sets}
				isShowGameScore={checkIsShowGameScore(participant)}
				gameScore={participant.currentGameScoreA}
				teamType={"A"}
				isShowBye={participant.isBye && !participant.teams.teamA.length}
				entryType={participant.entryTypeA}
				participant={participant}
				showRetiresScore={showRetiresScore}
			/>
			<PlayerBlock
				isTeamServing={isTeamBServing}
				isTeamWinner={isTeamBWinner}
				handleMouseOver={handleMouseOver}
				handleMouseOut={handleMouseOut}
				player={teamB}
				playerHighlighted={playerBHighlighted}
				openPlayerProfile={openPlayerProfile}
				seed={seedB}
				sets={sets}
				isShowGameScore={checkIsShowGameScore(participant)}
				gameScore={participant.currentGameScoreB}
				teamType={"B"}
				isShowBye={participant.isBye && !participant.teams.teamB.length}
				entryType={participant.entryTypeB}
				participant={participant}
				showRetiresScore={showRetiresScore}
			/>
		</PlayersWrapper>
	);
};

const checkTeamServing = (teamsType: string[], participant: IParticipant) =>
	teamsType.includes(participant.serve || "");
const checkIsWinner = (teamType: "A" | "B", participant: IParticipant) =>
	participant.winner === teamType;
const checkIsHighlighted = (
	showHoverPlayers: boolean,
	team: IPlayer | undefined,
	hoverId: number | null
) => showHoverPlayers && hoverId === team?.id;

const DoubleParticipant: React.FC<IPropsPlayerCard> = ({
	participant,
	openPlayerProfile,
	handleMouseOver,
	handleMouseOut,
	showHoverPlayers = false,
	showRetiresScore,
	sets,
}) => {
	const teamA1 = useSelector(getPlayerById)(first(participant.teams.teamA)?.playerId || 0);
	const teamA2 = useSelector(getPlayerById)(last(participant.teams.teamA)?.playerId || 0);
	const teamB1 = useSelector(getPlayerById)(first(participant.teams.teamB)?.playerId || 0);
	const teamB2 = useSelector(getPlayerById)(last(participant.teams.teamB)?.playerId || 0);
	const {seedA, seedB} = participant;
	const isTeamAServing = checkTeamServing(["A", "A2"], participant);
	const isTeamBServing = checkTeamServing(["B", "B2"], participant);
	const isTeamAWinner = checkIsWinner("A", participant);
	const isTeamBWinner = checkIsWinner("B", participant);
	const isComplete = participant.status === ParticipantStatusType.Complete;

	const hoverId = useSelector(getPlayerHoverId);
	const playerAHighlighted = checkIsHighlighted(showHoverPlayers, teamA1, hoverId);
	const playerBHighlighted = checkIsHighlighted(showHoverPlayers, teamB1, hoverId);

	return (
		<PlayersWrapper isComplete={isComplete}>
			<PlayerBlock
				isTeamServing={isTeamAServing}
				isTeamWinner={isTeamAWinner}
				handleMouseOver={handleMouseOver}
				handleMouseOut={handleMouseOut}
				player={teamA1}
				player2={teamA2}
				playerHighlighted={playerAHighlighted}
				openPlayerProfile={openPlayerProfile}
				seed={seedA}
				sets={sets}
				isShowGameScore={checkIsShowGameScore(participant)}
				gameScore={participant.currentGameScoreA}
				teamType={"A"}
				isShowBye={participant.isBye && !participant.teams.teamA.length}
				entryType={participant.entryTypeA}
				participant={participant}
				showRetiresScore={showRetiresScore}
			/>
			<PlayerBlock
				isTeamServing={isTeamBServing}
				isTeamWinner={isTeamBWinner}
				handleMouseOver={handleMouseOver}
				handleMouseOut={handleMouseOut}
				player={teamB1}
				player2={teamB2}
				playerHighlighted={playerBHighlighted}
				openPlayerProfile={openPlayerProfile}
				seed={seedB}
				sets={sets}
				isShowGameScore={checkIsShowGameScore(participant)}
				gameScore={participant.currentGameScoreB}
				teamType={"B"}
				isShowBye={participant.isBye && !participant.teams.teamB.length}
				entryType={participant.entryTypeB}
				participant={participant}
				showRetiresScore={showRetiresScore}
			/>
		</PlayersWrapper>
	);
};

interface IProps {
	participant: IParticipant;
	showHoverPlayers?: boolean;
	withoutHeader?: boolean;
	showRetiresScore?: boolean;
}

export const Players: React.FC<IProps> = ({
	participant,
	showHoverPlayers,
	withoutHeader = false,
	showRetiresScore = false,
}) => {
	const dispatch = useDispatch();
	const sets = useSelector(getMatchSets);

	useEffect(() => {
		if (participant.id && !sets[participant.id] && participant.setsAvailable) {
			dispatch(fetchParticipantSets(participant));
		}
	}, [dispatch, participant, sets]);

	const setsPlayers = useSelector(getMatchSetsById)(participant.id);
	const [nowSets, setNowSets] = useState<ISet[]>([]);

	useEffect(() => {
		if (setsPlayers.length) {
			setNowSets(setsPlayers);
		}
	}, [setsPlayers, setNowSets]);

	const openPlayerProfile = useCallback(
		(player: IPlayer | undefined) => {
			if (!player || player?.isWheelchair) {
				return;
			}

			sendDataLayer("player_profile_click");

			dispatch(showPlayerProfile({playerId: player.id, participantId: participant.id}));
			window.scrollTo(0, 0);
		},
		[dispatch, participant]
	);

	const handleMouseOver = useCallback(
		(e: React.SyntheticEvent<HTMLDivElement>) => {
			if (showHoverPlayers) {
				const userId = parseInt(get(e, "currentTarget.dataset.id", "0"));
				dispatch(setHoverPlayer(userId));
			}
		},
		[dispatch, showHoverPlayers]
	);

	const handleMouseOut = useCallback(() => {
		if (showHoverPlayers) {
			dispatch(unsetHoverPlayer());
		}
	}, [dispatch, showHoverPlayers]);

	return (
		<Wrapper withoutHeader={withoutHeader}>
			<Exist when={participant.type === ParticipantTypes.SINGLE}>
				<SingleParticipant
					participant={participant}
					openPlayerProfile={openPlayerProfile}
					handleMouseOver={handleMouseOver}
					handleMouseOut={handleMouseOut}
					showHoverPlayers={showHoverPlayers}
					showRetiresScore={showRetiresScore}
					sets={nowSets}
				/>
			</Exist>
			<Exist when={participant.type === ParticipantTypes.DOUBLE}>
				<DoubleParticipant
					participant={participant}
					openPlayerProfile={openPlayerProfile}
					handleMouseOver={handleMouseOver}
					handleMouseOut={handleMouseOut}
					showHoverPlayers={showHoverPlayers}
					showRetiresScore={showRetiresScore}
					sets={nowSets}
				/>
			</Exist>
		</Wrapper>
	);
};
