import React, {Fragment, PropsWithChildren, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
	getActiveParticipants,
	getFilterTournamentBy,
	getMatchStatsState,
	getPlayerProfileState,
	getTournamentRequestState,
	getTournamentsBySettings,
} from "modules/selectors";
import {RequestState} from "modules/enums";
import {setFilterTournamentBy} from "modules/actions";
import {IPostMessage} from "modules/types";
import {get, find, forEach} from "lodash";
import {useLocation} from "react-router-dom";
import {changeIframeHeight, useIsMobile} from "modules/utils";

const inIframe = () => {
	try {
		return window.self !== window.top;
	} catch (e) {
		return true;
	}
};

const getPlayerModalHeight = (isMobile: boolean) => (isMobile ? 750 : 600);

export const HOCSendIframeDate: React.FC<PropsWithChildren<unknown>> = ({children}) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const requestState = useSelector(getTournamentRequestState);
	const isLoading = requestState !== RequestState.Received;
	const allTournaments = useSelector(getTournamentsBySettings);
	const activeParticipants = useSelector(getActiveParticipants);
	const filterTournamentBy = useSelector(getFilterTournamentBy);
	const {isMobile} = useIsMobile();
	const countParticipants = activeParticipants.length;
	const {pathname} = location;
	const {isShow: isShowMatchStatsState} = useSelector(getMatchStatsState);
	const {isShow: isShowPlayerProfileState} = useSelector(getPlayerProfileState);
	const changeParentHeight = () => {
		setTimeout(() => {
			changeIframeHeight();
		}, 100);
	};

	window.onresize = () => {
		if (!isShowMatchStatsState && !isShowPlayerProfileState) {
			changeParentHeight();
		}
	};

	useEffect(() => {
		if (!isLoading) {
			changeParentHeight();
		}
	}, [countParticipants, isLoading, pathname, isMobile]);

	// Hide parent iframe if we not have tournaments
	useEffect(() => {
		if (!isLoading && filterTournamentBy !== undefined) {
			if (allTournaments.length === 0) {
				window.parent.postMessage(
					JSON.stringify({
						type: "voting_hide",
					}),
					"*"
				);
			} else {
				window.parent.postMessage(
					JSON.stringify({
						type: "voting_show",
						payload:
							document.querySelector<HTMLElement>("#mainWrapper")?.offsetHeight || 0,
					}),
					"*"
				);
			}
		}
	}, [allTournaments, isLoading, filterTournamentBy]);

	useEffect(() => {
		if (isShowMatchStatsState || isShowPlayerProfileState) {
			window.parent.postMessage(
				JSON.stringify({
					type: "voting_scroll_top",
				}),
				"*"
			);

			document.body.style.overflowY = "hidden";

			window.parent.postMessage(
				JSON.stringify({
					type: "voting_height",
					payload:
						isShowMatchStatsState && !isShowPlayerProfileState
							? document.querySelector<HTMLElement>("#mainWrapper")?.offsetHeight || 0
							: getPlayerModalHeight(isMobile),
				}),
				"*"
			);
		} else {
			document.body.style.overflowY = "visible";
			changeParentHeight();
		}
	}, [
		isShowMatchStatsState,
		isShowPlayerProfileState,
		countParticipants,
		isLoading,
		pathname,
		isMobile,
	]);

	useEffect(() => {
		const ltaFanZone = "LTA Fan Zone";

		if (!inIframe()) {
			dispatch(setFilterTournamentBy(ltaFanZone));
		}

		window.addEventListener("message", (e: IPostMessage) => {
			if (!inIframe()) {
				dispatch(setFilterTournamentBy(ltaFanZone));
			}

			const rothesayClassicBirmingham = "Rothesay Classic Birmingham";
			const ilkley = "Ilkley Trophy";
			const surbiton = "Surbiton Trophy";

			try {
				const pages = {
					"rothesay-open-nottingham": "Rothesay Open Nottingham",
					"rothesay-classic-birmingham": "Rothesay Classic Birmingham",
					"cinch-championships": "Cinch Championships",
					"rothesay-international-eastbourne": "Rothesay International Eastbourne",
					surbiton: surbiton,
					"surbiton-trophy": surbiton,
					nottingham: "Nottingham Media Centre",
					Nottingham: "Nottingham Media Centre",
					ilkley: ilkley,
					"ilkley-trophy": ilkley,
					birmingham: rothesayClassicBirmingham,
					queens: "Cinch Championships",
					eastbourne: "Rothesay International Eastbourne",
					"fan-zone": ltaFanZone,
				};

				const data = JSON.parse(e.data);

				console.log(data.payload.tournament);

				if (get(data, "type", "") === "lta-data") {
					const currentLocation = get(data, "payload.location", []);
					let parentDomain = "";
					forEach(currentLocation, (item: string, key: number) => {
						parentDomain = parentDomain + (key === 0 ? `${item}/` : `/${item}`);
					});

					console.log(parentDomain);

					sessionStorage.setItem("parentDomain", parentDomain);
					const foundPage =
						data.payload.tournament === ""
							? find(pages, (page, name) => currentLocation.includes(name))
							: data.payload.tournament;

					console.log(foundPage);

					dispatch(setFilterTournamentBy(foundPage || ""));
				}
			} catch (error) {
				// Empty
			}
		});
	}, [dispatch]);

	return <Fragment>{children}</Fragment>;
};
