import {SagaIterator} from "redux-saga";
import {call, put} from "typed-redux-saga";
import {Api} from "modules/utils/Api";
import {
	fetchPlayersSetsSuccess,
	fetchPlayersSuccess,
	IFetchPlayersSets,
	fetchPlayersMatchesSuccess,
} from "modules/actions";
import {ISagaAction} from "modules/types";

export const fetchPlayersSaga = function* (): SagaIterator {
	try {
		const players = yield* call(Api.JSON.players);
		yield* put(fetchPlayersSuccess(players));
	} catch (e) {
		console.log(e);
	}
};

export const fetchPlayerSetsSaga = function* ({payload}: ISagaAction<IFetchPlayersSets>) {
	try {
		const result = yield* call(Api.JSON.playersSets, payload);

		yield* put(
			fetchPlayersSetsSuccess({
				[payload.playerId]: result,
			})
		);
	} catch (err) {
		console.log(err);
	}
};

export const fetchPlayerMatchesSaga = function* ({payload}: ISagaAction<IFetchPlayersSets>) {
	try {
		const result = yield* call(Api.JSON.playerMatches, payload);

		yield* put(fetchPlayersMatchesSuccess(result));
	} catch (err) {
		yield* put(
			fetchPlayersMatchesSuccess({
				first: [],
				second: [],
				third: [],
				fourth: [],
				quarterFinal: [],
				semiFinal: [],
				final: [],
			})
		);
		console.log(err);
	}
};
