import {all, call, delay, race, select, take} from "typed-redux-saga";
import {unsubscribeFromLiveScores} from "modules/actions";
import {fetchChecksumsJSONSaga, requestTournamentsSaga, fetchPlayersSaga} from "modules/sagas";
import {getChecksums} from "modules/selectors";
import {differenceBy, first, flow, get, last, partial, toPairs, identity, isEmpty} from "lodash";
import {IDictionary} from "modules/types";

const SEC = 1000;
const WAIT = SEC * 15;

const mapChecksumsToSaga = {
	tournaments: requestTournamentsSaga,
	players: fetchPlayersSaga,
};

export const fetchLiveScoresSaga = function* (): unknown {
	const {stopped} = yield* race({
		wait: delay(WAIT),
		stopped: take(unsubscribeFromLiveScores),
	});

	if (!stopped) {
		const [old_checksums] = yield* all([select(getChecksums), call(fetchChecksumsJSONSaga)]);

		const new_checksums = yield* select(getChecksums);

		const requestsForChanges = differenceBy(
			toPairs(old_checksums as IDictionary<string>),
			toPairs(new_checksums),
			last
		)
			.map(flow([first, partial(get, mapChecksumsToSaga)]))
			.filter(identity);

		if (!isEmpty(requestsForChanges)) {
			yield* all(requestsForChanges.map((request) => call(request)));
		}

		yield* call(fetchLiveScoresSaga);
	}
};
