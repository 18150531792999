import React, {lazy} from "react";
import {createRoot} from "react-dom/client";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import {retryFailLoad} from "modules/utils/LazyLoad";
import {HOCGetData, PagePreloader} from "components";
import {store} from "modules/store";
import {Provider} from "react-redux";
import {theme} from "./assets/css/theme";
import {ThemeProvider} from "styled-components";

import "assets/css/fonts.css";
import "./assets/css/reset.css";
import "./assets/css/core.css";
import {HOCSendIframeDate} from "./components/HOCSendIframeDate";

const ScoreCentreLive = lazy(retryFailLoad(() => import("pages/ScoreCentreLive")));
const ScoreCentreCompleted = lazy(retryFailLoad(() => import("pages/ScoreCentreCompleted")));
const TournamentDraw = lazy(retryFailLoad(() => import("pages/TournamentDraw")));
const OrderOfPlay = lazy(retryFailLoad(() => import("pages/OrderOfPlay")));
const NotFound = lazy(retryFailLoad(() => import("pages/PageNotFound")));

let errorCount = 0;
const MAX_ERRORS = 100;
Sentry.init({
	dsn: "https://d1e5a1879433431f80c0c3f5e42d68b3@o151969.ingest.sentry.io/6343159",
	environment: process.env.REACT_APP_SENTRY_ENV || "development",
	sampleRate: 0.1,
	allowUrls: [".fanhubmedia.com"],
	integrations: [
		new Integrations.BrowserTracing(),
		new Sentry.Integrations.Breadcrumbs({
			console: false,
		}),
	],
	denyUrls: [
		"quantcast",
		"xsca",
		// browser's extensions
		/extensions\//i,
		/^chrome:\/\//i,
		/^moz-extension:\/\//i,
	],
	beforeSend: (event: Sentry.Event) => {
		errorCount++;
		if (errorCount > MAX_ERRORS) {
			return null;
		}
		return event;
	},
});

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

root.render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<Provider store={store}>
				<BrowserRouter basename={process.env.REACT_APP_BASE_URL}>
					<React.Suspense fallback={<PagePreloader />}>
						<HOCSendIframeDate>
							<HOCGetData>
								<Routes>
									<Route>
										<Route path="/" element={<ScoreCentreLive />} />
										<Route
											path="/completed"
											element={<ScoreCentreCompleted />}
										/>
										<Route path="/draw" element={<TournamentDraw />} />
										<Route path="/play" element={<OrderOfPlay />} />
									</Route>
									<Route element={<NotFound />} path="/*" />
								</Routes>
							</HOCGetData>
						</HOCSendIframeDate>
					</React.Suspense>
				</BrowserRouter>
			</Provider>
		</ThemeProvider>
	</React.StrictMode>
);
