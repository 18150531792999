import {DateTime} from "luxon";
import {nth} from "./dayWithNth";
import {get} from "lodash";

export const formatDate = (date: string) => {
	const splitDate = date.split("T");
	const updatedDate = get(splitDate, "[0]", date);

	const dayOfWeek = DateTime.fromISO(updatedDate).toFormat("ccc");
	const dayOfMount = DateTime.fromISO(updatedDate).toFormat("d");
	const mount = DateTime.fromISO(updatedDate).toFormat("MMMM");
	return `${dayOfWeek} ${dayOfMount}${nth(+dayOfMount)} ${mount}`;
};

export const dateWithoutDayOfWeek = (date: string) => {
	const splitDate = date.split("T");
	const updatedDate = get(splitDate, "[0]", date);
	const dayOfMount = DateTime.fromISO(updatedDate).toFormat("d");
	const mount = DateTime.fromISO(updatedDate).toFormat("MMMM");
	const year = DateTime.fromISO(updatedDate).toFormat("y");
	return `${dayOfMount}${nth(+dayOfMount)} ${mount} ${year}`;
};

export const dayOfWeek = (date: string) => {
	const splitDate = date.split("T");
	const updatedDate = get(splitDate, "[0]", date);
	return DateTime.fromISO(updatedDate).toFormat("cccc");
};
